import React from 'react';

import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { settings, shipment } from '../../../../models/entities/shipment/shipment';

import Accounting from '../../commonSubEntities/accounting/Accounting';
import { billingLine } from '../../../../models/entities/common_subentities/billingLine';
import AccountingFormConnected from '../AccountingForm/AccountingFormConnected';

type Props = {
    shipment: shipment;
    billingLines?: Array<billingLine>;
    localization: any;
    settings?: settings;
    printMode?: boolean;
    featureFlags?: featureFlagsModel;
};

const ShipmentAccounting = ({ shipment, billingLines, localization, settings, printMode, featureFlags }: Props) => {
    return (
        <Accounting<shipment, settings>
            entity={shipment}
            billingLines={billingLines}
            localization={localization}
            settings={settings}
            menuType="shipmentAccountingMenu"
            AccountingFormConnected={AccountingFormConnected}
            printMode={printMode}
            featureFlags={featureFlags}
        />
    );
};

export default ShipmentAccounting;
