import React, { useEffect, useState } from 'react';
import { styled } from '../../../shared/Theme/theme';
import FiltersStripe from './Filter/FiltersStripe';
import Button from '../../../shared/SmallComponents/Button';
import FilterPopover from './Filter/FilterPopover';
import ExportMenu from './ExportMenu';
import { Link } from 'react-router-dom';
import { eShipmentFormMode } from '../../singleShipment/ShipmentForm/ShipmentFormConnected';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { connect } from 'react-redux';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { exportTypes, filterFieldsType } from '../../../../state/ducks/allBusinessPartnersPage/allBusinessPartnersPage';
import { allShipmentsPageSelectors } from '../../../../state/ducks/allShipmentsPage/selectors';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { RootState } from '../../../../state/store/store';
import { TextInput } from '../../../shared/inputs/base';
import Loader from '../../../shared/SmallComponents/Loader';
import { ColumnType } from '../../../shared/Grid/types/Column';
import { UploadShipmentsModal } from './UploadShipments';
import { paginationType } from '../../../../state/ducks/allShipmentsPage/allShipmentsPage';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import claimsType from '../../../../models/entities/claimsType';
import { claimsDomainsType } from '../../../../userClaims';
import { applicationSelectors } from '../../../../state/ducks/application/selectors';
import { Pagination } from '@material-ui/lab';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { css } from 'styled-components';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';

const useMobilePaginationStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: '#008ac9'
        }
    })
);

type Props = {
    shipments: Array<shipment>;
    activeShipmentsCount: number;
    shipmentActiveFilter: { field: string; value: any } | undefined;
    filteredShipmentsCount: number;
    pagination: paginationType;
    currentGridFilter: string | null;
    gridColumns: Array<ColumnType<shipment>>;
    localization: any;
    hidePagination?: boolean;
    isLoading?: boolean;
    isMobile: boolean;
    setGridFilter: (text: string) => void;
    fetchShipments: () => void;
    exportDataTable: (type: exportTypes) => void;
    fetchShipmentsByFilter: (filter: filterFieldsType) => Array<shipment>;
    setCurrentPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    featureFlags?: featureFlagsModel;
};
const AllShipmentsHeader = ({
    shipments,
    activeShipmentsCount,
    filteredShipmentsCount,
    shipmentActiveFilter,
    currentGridFilter,
    gridColumns,
    pagination,
    localization,
    hidePagination,
    isLoading,
    isMobile,
    setGridFilter,
    exportDataTable,
    fetchShipments,
    fetchShipmentsByFilter,
    setCurrentPage,
    setRowsPerPage,
    getUserClaims,
    featureFlags
}: Props) => {
    const [textFilterValue, setTextFilterValue] = useState(currentGridFilter);
    const [isTypingTextFilter, setIsTypingTextFilter] = useState(false);

    const { currentPage, rowsPerPage } = pagination;

    const classes = useMobilePaginationStyles();

    useEffect(() => {
        setIsTypingTextFilter(true);
        const timeout = setTimeout(() => {
            setGridFilter(textFilterValue || '');
            setIsTypingTextFilter(false);
            setCurrentPage(0);
        }, 500);

        return () => clearTimeout(timeout);
    }, [textFilterValue]);

    const shipmentsFrom = currentPage * rowsPerPage + 1;
    const shipmentsTo = (currentPage + 1) * rowsPerPage;

    return (
        <>
            <PageHeader>
                <Title>{localization.title}</Title>
                <FiltersStripe localization={localization} />

                <HeaderRightSide>
                    <Button
                        width="32px"
                        height="32px"
                        borderRadius="50%"
                        margin="7px"
                        buttonType="hollow"
                        onClick={() => {
                            fetchShipmentsByFilter(shipmentActiveFilter ? [shipmentActiveFilter] : []);
                        }}
                    >
                        <Tooltip title={localization.refresh_btn} aria-label="refresh">
                            <span className="material-icons">refresh</span>
                        </Tooltip>
                    </Button>
                    <TextInput
                        style={{
                            height: '30px',
                            margin: '4px',
                            width: 'auto'
                        }}
                        allowClear={true}
                        onChange={(value: string) => setTextFilterValue(value)}
                        placeHolder={localization.filterPlaceHolder}
                        value={textFilterValue || ''}
                        suffix={isTypingTextFilter && <Loader wrapperWidth="auto" showText={false} marginTop="0" width="15px" />}
                    />
                    <FilterPopover localization={localization.filter} />
                    {!isMobile && (
                        <ExportMenu localization={localization} exportDataTable={exportDataTable} shipments={shipments} gridColumns={gridColumns} />
                    )}
                    {getUserClaims('shipment').add_shipment && !featureFlags?.UNDER_FORWARDER && (
                        <>
                            {!isMobile && <UploadShipmentsModal localization={localization.upload_form} />}
                            <Link to={{ pathname: PagesRoutes.NewShipment, state: { mode: eShipmentFormMode.NEW } }}>
                                <NewButtonWrapper>
                                    <Button width="32px" height="32px" borderRadius="50%" margin="7px" buttonType="filled">
                                        <Tooltip title={localization.new_shipment} aria-label="new_shipment">
                                            <span className="material-icons">add</span>
                                        </Tooltip>

                                        {/* <span className="new-label">{localization.new_shipment}</span> */}
                                    </Button>
                                </NewButtonWrapper>
                            </Link>
                        </>
                    )}
                    {!isMobile && !hidePagination && !(isLoading && (activeShipmentsCount <= 1 || !gridColumns.length)) && (
                        <NavButtonsContainer isMobile={isMobile}>
                            <IconButton
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={isLoading || shipmentsFrom <= 1}
                                className="material-icons nav-btn"
                            >
                                navigate_before
                            </IconButton>
                            {filteredShipmentsCount > 0
                                ? `${shipmentsFrom} – ${filteredShipmentsCount <= shipmentsTo ? filteredShipmentsCount : shipmentsTo}${
                                      shipments.length <= filteredShipmentsCount ? ' of ' + filteredShipmentsCount : ''
                                  }`
                                : ''}
                            <IconButton
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={isLoading || filteredShipmentsCount <= shipmentsTo}
                                className="material-icons nav-btn"
                            >
                                navigate_next
                            </IconButton>
                        </NavButtonsContainer>
                    )}
                </HeaderRightSide>
            </PageHeader>

            {isMobile && !hidePagination && !(isLoading && (activeShipmentsCount <= 1 || !gridColumns.length)) && (
                <NavButtonsContainer isMobile={isMobile}>
                    <Pagination
                        className={classes.root}
                        count={Math.floor(filteredShipmentsCount / rowsPerPage)}
                        page={currentPage + 1}
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                            setCurrentPage(value - 1);
                        }}
                    />
                </NavButtonsContainer>
            )}
        </>
    );
};

const PageHeader = styled.div`
    display: flex;
    padding: 20px 12px 0 12px;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 20px 8px 8px 8px;
    }

    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #fff;
`;

const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: ${(props) => props.theme.fontSizes.pageTitle};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const HeaderRightSide = styled.div`
    display: flex;
    flex-direction: columm;
    width: 100%;
    max-width: fit-content;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: 40px;
        justify-content: center;
    }

    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;
const NewButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        width: auto;
    }
    .add-icon {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
        }
    }

    .new-label {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
`;

const NavButtonsContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    align-items: center;
    ${(props) =>
        props.isMobile
            ? css`
                  justify-content: center;
              `
            : css`
                  margin: 0 15px;
              `}
    color: ${(props) => props.theme.colors.primaryBlue};
    font-weight: bold;
    .nav-btn {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.primaryBlue};
    }
    .MuiPaginationItem-page.Mui-selected {
        background-color: #008ac914;
    }
    .MuiPaginationItem-root {
        color: ${(props) => props.theme.colors.primaryBlue};
    }
`;
const PaginationDetails = styled.div`
    text-align: center;
`;

const mapStateToProps = (state: RootState) => ({
    activeShipmentsCount: allShipmentsPageSelectors.activeShipmentsCount(state),
    currentGridFilter: allShipmentsPageSelectors.gridFilter(state),
    filteredShipmentsCount: allShipmentsPageSelectors.filteredShipmentsCount(state),
    pagination: allShipmentsPageSelectors.pagination(state),
    gridColumns: allShipmentsPageSelectors.gridColumns(state),
    shipments: allShipmentsPageSelectors.gridData(state),
    shipmentActiveFilter: allShipmentsPageSelectors.shipmentActiveFilter(state),
    localization: localizationSelectors.allShipmentsPage(state),
    isMobile: applicationSelectors.isMobile(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    setGridFilter: (text: string) => dispatch.allShipmentsPage.setGridFilter(text),
    setCurrentPage: (page: number) => dispatch.allShipmentsPage.setCurrentPage(page),
    setRowsPerPage: (rowsPerPage: number) => dispatch.allShipmentsPage.setRowsPerPage(rowsPerPage),
    exportDataTable: (type: exportTypes) => dispatch.allShipmentsPage.exportDataTable(type),
    fetchShipmentsByFilter: (filter: filterFieldsType) => dispatch.allShipmentsPage.fetchShipmentsByFilter(filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(AllShipmentsHeader);
