import EntityDetails, { detail } from '../../../shared/EntityDetails/EntityDetails';
import { Option, enumToDropdownOptions } from '../../../shared/inputs/base/DropDown';
import ProductsModal, { productGridRow } from '../../products/components/ProductsModal/ProductsModal';
import { carriersDropDownOptionsByShipmentType, filterIncoterms, operatorDropDownOptions } from '../ShipmentForm/sections/utils';
import { Checkbox } from 'antd';
import { ModalTriggerProps } from '../../../shared/Modal/Modal';
import PackagesFormModal from './shipmentPackages/packagesFormModal/PackagesFormModal';
import React from 'react';
import WeightIcon from '../../../../static/icons/weight.svg';
import { calcShipmentItemsTotalVolumeWeight } from './ShipmentItems/utils';
import carriers from '../../../../static/data/carriers.json';
import { chain } from 'lodash';
import { currencyFormatter } from '../../../shared/inputs/form/CurrencyInput/CurrencyInput';
import { eCommodityType } from '../../../../models/entities/shipment/commodityType';
import { eContainerTypes } from '../../../../models/entities/shipment/containerTypes';
import { eIncoterms } from '../../../../models/entities/shipment/incoterms';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import moment from 'moment';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { shipmentCalcWeightVolume } from '../ShipmentForm/sections/Packages/util';
import { shipmentFormLogics } from '../util';
import shipmentItem from '../../../../models/entities/shipment/shipmentItem';
import { shipmentPackage } from '../../../../models/entities/shipment/shipmentPackage';
import styled from 'styled-components';

export type editTypes = 'dropdown' | 'date' | 'currency' | 'unit' | 'checkbox' | 'autocomplete';

export type editableDetail = {
    name: string;
    value?: any;
    type?: editTypes;
    options?: Option[];
    placeholder?: string;
};
export type shipmentDetail = {
    showCondition: boolean;
    editable: false | editableDetail;
    content?: JSX.Element;
    title: string | JSX.Element;
    value?: string;
    tooltip?: string;
    hideTitle?: boolean;
};

export const getDetailsGridData = (
    shipment: shipment,
    localization: any,
    detailsEdit: boolean,
    updateTransportationPaid?: (id: string, transportationPaid: boolean) => Promise<void>,
    createShipmentItems?: (shipmentId: string, items: shipmentItem[]) => Promise<void>,
    setTransportationPaidCallBack?: (paid: boolean) => Promise<void>,
    isFreelancerOrForwarder?: boolean,
    featureFlags?: featureFlagsModel,
    isAllowedShipment?: boolean,
    removeQuantityModal?: boolean,
    isAdmin?: boolean
): Array<Array<detail>> => {
    const { chargeableWeight } = shipmentCalcWeightVolume(shipment);
    const transportationCost = Number(shipment.transportationPrice) / Number(shipment.goodsValue);
    let hideWeight = false;
    let hideChargeableWeight = false;
    let isOcean = ['OI', 'OE', 'OEX', 'DROP_OCEAN', 'INLAND', 'CUSTOMS', 'RAIL'].includes(shipment.typeCode);
    if (!['DROP_AIR', 'AI', 'AE'].includes(shipment.typeCode)) hideChargeableWeight = true;
    const showEditTotals: boolean = !shipment.packages?.length;
    const isContainers = shipmentFormLogics.container_no(shipment.typeCode) && (shipment.subTypeCode === 'FCL' || shipment.subTypeCode === 'CON');
    const isContainersLCL = shipmentFormLogics.container_no(shipment.typeCode) && (shipment.subTypeCode === 'LCL' || shipment.subTypeCode === 'BOX');
    if (isContainers && !showEditTotals) hideWeight = true;
    const containerTypesDropDownOptions = eContainerTypes.map((item) => {
        return { text: item.type, value: item.type };
    });

    const {
        volume: itemsTotalVolume,
        weight: itemsTotalWeight,
        quantity: itemsTotalQuantity
    } = calcShipmentItemsTotalVolumeWeight(shipment.items || []);

    const packagesTotalQuantity = shipment.packages?.reduce((totalQuantity: number, item: shipmentPackage) => {
        return totalQuantity + (item.quantity || 0);
    }, 0);

    const containersQuantity = shipment.containers?.length;

    const itemsTotalCost = shipment?.items
        ? chain(shipment.items)
              .groupBy((item) => item.currency)
              .map((items, index) => {
                  const totalItemsCost = items
                      ?.reduce((total: number, item: shipmentItem) => {
                          const { price, quantity } = item;
                          if (!price || !quantity) return total;
                          return total + Number(price) * quantity;
                      }, 0)
                      ?.toString();

                  const value = currencyFormatter(items[0].currency)(totalItemsCost);
                  return <div key={`currency${index}`}>{value}</div>;
              })
              .value()
        : undefined;

    const totalCartons =
        shipment.totalCartons ||
        shipment.items?.reduce(
            (acc, item) => acc + (item.quantity && item.unitsPerMasterCarton ? Math.ceil(item.quantity / item.unitsPerMasterCarton) : 0),
            0
        );

    const details: Array<Array<detail>> = [
        [
            {
                name: 'master',
                showCondition: shipmentFormLogics.master(shipment.typeCode),
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.master,
                value: shipment.master
            },
            {
                name: 'house',
                showCondition: shipmentFormLogics.house(shipment.typeCode),
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.house,
                value: shipment.house
            },
            {
                name: 'customsFile',
                showCondition: shipmentFormLogics.customs_file(shipment.typeCode),
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.customs_file,
                value: shipment.customsFile
            },
            {
                name: 'operator',
                showCondition: shipmentFormLogics.operator(shipment.typeCode),
                editable: {
                    type: 'dropdown',
                    placeholder: localization.enter_name,
                    options: operatorDropDownOptions()
                },
                title: localization.operator,
                value: shipment.operator
            },
            {
                name: 'incoterms',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'incoterms'),
                editable: {
                    type: 'dropdown',
                    placeholder: localization.pick_type,
                    options: filterIncoterms(shipment.typeCode, enumToDropdownOptions(eIncoterms))
                },
                title: localization.incoterms,
                value: shipment.incoterms
            },
            {
                name: 'deliveryNote',
                showCondition: shipmentFormLogics.delivery_note(shipment.typeCode),
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.delivery_note,
                value: shipment.deliveryNote
            }
        ],
        [
            {
                name: 'goodsReady',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'goodsReady'),
                editable: {
                    value: shipment.goodsReady,
                    type: 'date',
                    placeholder: localization.goods_ready
                },
                title: localization.goods_ready,
                value: shipment.goodsReady ? moment(shipment.goodsReady).format('DD/MM/YYYY') : undefined
            },
            {
                name: 'pickUp',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'pickUp'),
                editable: {
                    value: shipment.pickUp,
                    type: 'date',
                    placeholder: localization.pick_up
                },
                title: localization.pick_up,
                value: shipment.pickUp ? moment(shipment.pickUp).format('DD/MM/YYYY') : undefined
            },
            {
                name: 'etd',
                showCondition: shipmentFormLogics.etd(shipment.typeCode),
                editable: {
                    type: 'date',
                    value: shipment?.etd,
                    placeholder: localization.etd
                },
                title: localization.etd,
                value: shipment.etd ? moment(shipment.etd).format('DD/MM/YYYY') : undefined
            },
            {
                name: 'atd',
                showCondition: shipmentFormLogics.atd(shipment.typeCode),
                editable: {
                    type: 'date',
                    value: shipment?.atd,
                    placeholder: localization.atd
                },
                title: localization.atd,
                value: shipment.atd ? moment(shipment.atd).format('DD/MM/YYYY') : undefined
            },
            {
                name: 'eta',
                showCondition: shipmentFormLogics.eta(shipment.typeCode),
                editable: {
                    type: 'date',
                    value: shipment?.eta,
                    placeholder: localization.eta
                },
                title: localization.eta,
                value: shipment.eta ? moment(shipment.eta).format('DD/MM/YYYY') : undefined
            },
            {
                name: 'ata',
                showCondition: shipmentFormLogics.ata(shipment.typeCode),
                editable: {
                    type: 'date',
                    value: shipment?.ata,
                    placeholder: localization.ata
                },
                title: localization.ata,
                value: (() => {
                    const ata = shipment.ata || shipment.events?.find((item) => item.eventCode === 'ARRIVAL')?.eventDate;
                    return ata ? moment(ata).format('DD/MM/YYYY') : undefined;
                })()
            }
        ],
        [
            {
                name: 'carrierCode',
                showCondition: shipmentFormLogics.carrier(shipment.typeCode),
                editable: {
                    value: shipment.carrierCode,
                    type: 'autocomplete',
                    placeholder: localization.pick_carrier,
                    options: carriersDropDownOptionsByShipmentType(shipment.typeCode)
                },
                title: localization.carrier,
                value: carriers.find((item) => item.carrierCode === shipment.carrierCode)?.carrierName || shipment.carrierCode
            },
            {
                name: 'vesselName',
                showCondition: shipmentFormLogics.vessel_name(shipment.typeCode) ? (detailsEdit ? true : !!shipment.vesselName) : false,
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.vessel,
                value: shipment.vesselName
            },
            {
                name: 'voyageNumber',
                showCondition: shipmentFormLogics.voyage_number(shipment.typeCode),
                editable: {
                    placeholder: localization.enter_name
                },
                title: localization.voyage_number,
                value: shipment.voyageNumber
            },
            {
                name: 'commodity',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'commodity'),
                editable: {
                    placeholder: localization.commodity
                },
                title:
                    !isAllowedShipment || !createShipmentItems ? (
                        localization.commodity
                    ) : (
                        <ProductsModal
                            title="Products"
                            showAttributesModal
                            showQuantities
                            onSubmitProductsCallBack={(products: productGridRow[]) => {
                                const itemsToAdd: shipmentItem[] = [];

                                products.forEach((product) => {
                                    const itemToAdd = {
                                        shipmentId: shipment.id,
                                        productId: product.id,
                                        price: product.cost,
                                        currency: product.costCurrency,
                                        name: product.name,
                                        length: product.length,
                                        width: product.width,
                                        height: product.height,
                                        dimensionsUnit: product.dimensionUnit,
                                        weight: product.weight,
                                        weightUnit: product.weightUnit,
                                        productSku: product.sku,
                                        attributes: product.selectedAttributes,
                                        quantity: product.quantity || 1
                                    } as shipmentItem;

                                    itemsToAdd.push(itemToAdd);
                                });

                                createShipmentItems(shipment.id, itemsToAdd);
                            }}
                            Trigger={(props: ModalTriggerProps) => (
                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={props.onClick}>
                                    {localization.commodity}
                                    <span
                                        className="material-icons"
                                        style={{
                                            fontSize: 15,
                                            marginLeft: 5,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        add_circle_outline
                                    </span>
                                </div>
                            )}
                        />
                    ),
                value: shipment.commodity
            },
            {
                name: 'commodityType',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'commodityType'),
                editable: {
                    type: 'dropdown',
                    options: enumToDropdownOptions(eCommodityType),
                    placeholder: localization.commodity_type,
                    value: shipment.commodityType
                },
                title: localization.commodity_type,
                value: shipment.commodityType ? eCommodityType[shipment.commodityType] : undefined
            }
        ],
        [
            {
                name: 'quantity',
                showCondition: detailsEdit ? true : !isExpressAndEmpty(shipment, 'quantity'),
                editable:
                    showEditTotals && isAllowedShipment
                        ? {
                              value: shipment.quantity
                          }
                        : false,
                title: localization.quantity,
                value: removeQuantityModal ? `${shipment.quantity || '-'}` : undefined,
                content: removeQuantityModal ? undefined : (
                    <>
                        <DetailValue>
                            <Row>
                                {Number(shipment.quantity) || itemsTotalQuantity || packagesTotalQuantity || containersQuantity}
                                {!shipment.isTotals && !isContainers && (
                                    <PackagesFormModal
                                        title={localization.packages_field}
                                        shipment={shipment}
                                        Trigger={(props) => <PackageSeeMore onClick={props.onClick}>{localization.see_more}</PackageSeeMore>}
                                    />
                                )}
                            </Row>
                        </DetailValue>
                    </>
                )
            },
            {
                name: 'totalCartons',
                showCondition: !!totalCartons,
                editable:
                    showEditTotals && isAllowedShipment
                        ? {
                              value: totalCartons,
                              placeholder: localization.total_cartons
                          }
                        : false,
                title: localization.total_cartons,
                value: `${totalCartons}`
            },
            {
                name: 'volume',
                showCondition: detailsEdit ? true : isExpressAndEmpty(shipment, 'volume') || !Number(shipment.volume) ? false : !hideWeight,
                editable: showEditTotals
                    ? {
                          type: 'unit',
                          value: shipment.volume,
                          options: [
                              { value: 'CBM', text: 'CBM' },
                              { value: 'CFT', text: 'CFT' }
                          ],
                          placeholder: localization.total_volume
                      }
                    : false,
                title: localization.volume,
                value: shipment.volume ? `${Number(shipment.volume).toFixed(3)} ${shipment.volumeUnit?.toUpperCase()}` : undefined
            },
            {
                name: 'weight',
                showCondition: detailsEdit ? true : isExpressAndEmpty(shipment, 'weight') || !Number(shipment.weight) ? false : !hideWeight,
                editable: showEditTotals
                    ? {
                          type: 'unit',
                          value: shipment.weight,
                          options: [
                              { value: 'KG', text: 'KG' },
                              { value: 'LB', text: 'LB' }
                          ],
                          placeholder: localization.total_weight
                      }
                    : false,
                title: (
                    <>
                        {!detailsEdit && <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={localization.weight} />}
                        {localization.weight}
                    </>
                ),
                value: shipment.weight ? `${Number(shipment.weight).toFixed(3)} ${shipment.weightUnit?.toUpperCase()}` : undefined
            },
            {
                name: 'estimatedItemsVolume',
                showCondition: !Number(shipment.volume) && !!shipment.items?.length,
                editable: false,
                title: localization.estimated_items_volume,
                value: <div>{shipment.items ? `${Number(itemsTotalVolume.volume.toFixed(3))} ${itemsTotalVolume.unit}` : ''}</div>
            },
            {
                name: 'estimatedItemsWeight',
                showCondition: !Number(shipment.weight) && !!shipment.items?.length,
                editable: false,
                title: localization.estimated_items_weight,
                value: <div>{shipment?.items ? `${Number(itemsTotalWeight.weight.toFixed(3))} ${itemsTotalWeight.unit}` : ''}</div>
            },
            {
                name: 'chargeableWeight',
                showCondition: !hideChargeableWeight,
                editable: false,
                title: (
                    <>
                        {!detailsEdit && <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={localization.chargeable_weight} />}
                        {localization.chargeable_weight}
                    </>
                ),
                value: chargeableWeight.toFixed(3)
            },
            {
                name: 'containers',
                showCondition: isOcean,
                editable: false,
                title: removeQuantityModal ? (
                    localization.containers
                ) : (
                    <PackagesFormModal
                        title={localization.containers}
                        isContainers={true}
                        shipment={shipment}
                        Trigger={(props) => (
                            <div style={{ display: 'flex', alignItems: 'center' }} onClick={props.onClick}>
                                {localization.containers}
                                <span
                                    className="material-icons"
                                    style={{
                                        fontSize: 15,
                                        marginLeft: 5,
                                        cursor: 'pointer'
                                    }}
                                >
                                    add_circle_outline
                                </span>
                            </div>
                        )}
                    />
                ),
                value: (
                    <>
                        {shipment.containers?.length
                            ? shipment.containers.map((c) => {
                                  return <div>{c.number || c.type}</div>;
                              })
                            : '-'}
                    </>
                )
            },
            {
                name: 'containerNumber',
                showCondition: !isContainers
                    ? false
                    : !!shipment.containers?.length
                    ? false
                    : detailsEdit
                    ? true
                    : shipmentFormLogics.container_no(shipment.typeCode) && (!!shipment.packages?.length || !!shipment.containerNumber),
                editable: {
                    placeholder: localization.containers
                },
                title: localization.containers,
                value: (
                    <>
                        {shipment.packages?.length
                            ? shipment.packages?.map((c) => {
                                  return <div>{c.containerNumber}</div>;
                              })
                            : shipment.containerNumber?.split(',').map((c) => {
                                  return <div>{c}</div>;
                              })}
                    </>
                )
            },
            {
                name: 'isOverweight',
                showCondition: isContainers && !!shipment.containers?.find((cont) => cont.isOverweight) ? true : false,
                editable: false,
                title: localization.is_overweight,
                value: (
                    <>
                        <span className="material-icons-outlined">done</span>
                    </>
                ),
                tooltip: localization.overview.tooltips.is_overweight
            }
        ],
        [
            {
                name: 'goodsValue',
                showCondition: detailsEdit ? true : (!!shipment.goodsValueCurrency && !!Number(shipment.goodsValue)) || !!itemsTotalCost,
                editable: !isAllowedShipment
                    ? false
                    : {
                          value: shipment.goodsValue,
                          type: 'currency',
                          placeholder: localization.goods_value
                      },
                title: localization.goods_value,
                value:
                    !!shipment.goodsValueCurrency && !!Number(shipment.goodsValue) ? (
                        currencyFormatter(shipment.goodsValueCurrency)(shipment.goodsValue)
                    ) : itemsTotalCost ? (
                        <div>{itemsTotalCost}</div>
                    ) : undefined
            },
            {
                name: 'transportationPrice',
                showCondition:
                    !isFreelancerOrForwarder &&
                    (detailsEdit ? true : !!shipment.transportationPriceCurrency && !!Number(shipment.transportationPrice)),
                editable: isFreelancerOrForwarder
                    ? false
                    : {
                          value: shipment.transportationPrice,
                          type: 'currency',
                          placeholder: localization.trans_price
                      },
                title: localization.trans_price,
                value:
                    !!shipment.transportationPriceCurrency && !!shipment.transportationPrice
                        ? currencyFormatter(shipment.transportationPriceCurrency)(shipment.transportationPrice)
                        : undefined,
                tooltip: localization.overview.tooltips.transportationPrice
            },
            {
                name: 'transportationCostPercent',
                showCondition:
                    !!Number(shipment.goodsValue) &&
                    !!Number(shipment.transportationPrice) &&
                    shipment.transportationPriceCurrency === shipment.goodsValueCurrency,
                editable: false,
                title: localization.transportation_cost_percent,
                value: `${(transportationCost * 100).toFixed(2)}% (${(transportationCost + 1).toFixed(3)})`,
                tooltip: localization.overview.tooltips.transportationPrecent
            },
            {
                name: 'transportationPaid',
                showCondition: true,
                editable: false,
                title: localization.transportation_paid,
                value: (
                    <GreenCheckBoxContainer>
                        <Checkbox
                            disabled={!isAdmin}
                            checked={!!shipment.transportationPaid}
                            onChange={(e) => {
                                const paid = !shipment.transportationPaid;
                                updateTransportationPaid?.(shipment.id, paid);
                                setTransportationPaidCallBack?.(paid);
                            }}
                        />
                    </GreenCheckBoxContainer>
                )
            },
            {
                name: 'buyingRate',
                showCondition: isFreelancerOrForwarder
                    ? detailsEdit
                        ? true
                        : !!shipment.buyingRateCurrency && !!Number(shipment.buyingRate)
                    : false,
                editable: {
                    value: shipment.buyingRate,
                    type: 'currency',
                    placeholder: localization.buying_rate
                },
                title: localization.buying_rate,
                value:
                    !!shipment.buyingRateCurrency && !!shipment.buyingRate
                        ? currencyFormatter(shipment.buyingRateCurrency)(shipment.buyingRate)
                        : undefined
            },
            {
                name: 'sellingRate',
                showCondition: isFreelancerOrForwarder
                    ? detailsEdit
                        ? true
                        : !!shipment.sellingRateCurrency && !!Number(shipment.sellingRate)
                    : false,
                editable: {
                    value: shipment.sellingRate,
                    type: 'currency',
                    placeholder: localization.selling_rate
                },
                title: localization.selling_rate,
                value:
                    !!shipment.sellingRateCurrency && !!shipment.sellingRate
                        ? currencyFormatter(shipment.sellingRateCurrency)(shipment.sellingRate)
                        : undefined
            },
            {
                name: 'totalProfit',
                showCondition: isFreelancerOrForwarder
                    ? !!Number(shipment.buyingRate) && !!Number(shipment.sellingRate) && shipment.buyingRateCurrency === shipment.sellingRateCurrency
                    : false,
                editable: false,
                title: localization.total_profit,
                value: `${currencyFormatter(shipment.sellingRateCurrency)(shipment.sellingRate - shipment.buyingRate)}`
            }
        ]
    ];

    if (featureFlags?.AMAZON) {
        details.splice(
            details.length - 2,
            0,

            [
                {
                    name: 'amazonAppointmentDate',
                    showCondition: true,
                    editable: {
                        type: 'date',
                        placeholder: localization.amazon_appointment_date,
                        value: shipment?.amazonAppointmentDate
                    },
                    title: localization.amazon_appointment_date,
                    value: shipment.amazonAppointmentDate ? moment(shipment.amazonAppointmentDate).format('DD/MM/YYYY') : undefined
                }
            ]
        );
    }

    return details;
};

const renderShipmentDetailsGrid = (
    values: any,
    detailsEdit: boolean,
    shipment: shipment,
    localization: any,
    isAdmin: boolean,
    updateTransportationPaid: (id: string, transportationPaid: boolean) => Promise<void>,
    createShipmentItems: (shipmentId: string, items: shipmentItem[]) => Promise<void>,
    setTransportationPaidCallBack?: (paid: boolean) => Promise<void>,
    isFreelancerOrForwarder?: boolean,
    featureFlags?: featureFlagsModel,
    isAllowedShipment?: boolean,
    removeQuantityModal?: boolean
) => {
    return (
        <EntityDetails
            detailsData={getDetailsGridData(
                shipment,
                localization,
                detailsEdit,
                updateTransportationPaid,
                createShipmentItems,
                setTransportationPaidCallBack,
                isFreelancerOrForwarder,
                featureFlags,
                isAllowedShipment,
                removeQuantityModal,
                isAdmin
            )}
            values={values}
            detailsEdit={detailsEdit}
        />
    );
};

function isExpressAndEmpty(shipment: shipment, prop?: keyof shipment, value?: any) {
    return shipment.typeCode === 'EXPRESS' && (prop ? isValueEmpty(shipment[prop]) : isValueEmpty(value));

    function isValueEmpty(value?: any) {
        return !value || value === '0';
    }
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 20px;
`;
const CheckboxContainer = styled.div`
    padding: 22px 0 0 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
export const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

export const DetailBox = styled.div`
    flex: 1;
    margin: 10px 5px;
    max-width: 200px;
    white-space: nowrap;
    word-break: break-word;
`;

export const DetailTitle = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
const PackageSeeMore = styled.div`
    font-size: 12px;
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

const GreenCheckBoxContainer = styled.div`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #629e31;
        border-color: #629e31;
    }
`;

export { renderShipmentDetailsGrid };
