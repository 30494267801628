import React, { useEffect, useState } from 'react';
import { styled } from '../../../../shared/Theme/theme';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../shared/SmallComponents/Button';
import { ModalFooter, ModalFormContainer } from '../../../../shared/Modal/Modal';
import Loader from '../../../../shared/SmallComponents/Loader';
import { FormCheckbox, FormDatePicker, FormDropDown, FormNumberInput, FormTextArea } from '../../../../shared/inputs/form';
import FieldLabel from '../../../../shared/SmallComponents/FieldLabel';
import { billingLine } from '../../../../../models/entities/common_subentities/billingLine';
import currencyList from '../../../../shared/inputs/form/CurrencyInput/currencyList';
import getSymbolFromCurrency from 'currency-symbol-map';

export enum formMode {
    NEW,
    EDIT
}

interface IBillingLineSettings {
    billingLineServiceType?: { code: string; name: string; description: string }[];
}

interface formFields {
    isRelativeCostEnabled?: boolean;
    serviceType?: string;
    currency?: string;
    income?: number;
    expense?: number;
    totalCost?: number;
    relativeCost?: number;
    remarks?: string;
    profit?: number;
    date?: Date;
}

type Props<entityType, settingsType> = {
    entity?: entityType;
    billingLine?: billingLine;
    mode: formMode;
    localization: any;
    settings?: settingsType;
    openedFromModal?: {
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
    confirmation?: { setConfirm: React.Dispatch<React.SetStateAction<boolean>> };
    createBillingLine: (entityId: string, billingLine: billingLine) => void;
    updateBillingLine: (entityId: string, billingLine: billingLine) => void;
    showLoader?: boolean;
    UserCompanyID?: string;
};

let AFTER_LOADING: boolean = false;

function AccountingFormConnected<entityType extends { id: string }, settingsType extends IBillingLineSettings>({
    entity,
    billingLine,
    localization,
    mode,
    settings,
    openedFromModal,
    confirmation,
    createBillingLine,
    updateBillingLine,
    showLoader,
    UserCompanyID
}: Props<entityType, settingsType>) {
    const [submitLoading, setLoading] = useState(false);

    const billingLineServiceTypeOptions = settings?.billingLineServiceType?.map((item) => ({
        text: item.name,
        value: item.code
    }));

    const currencyOptions = currencyList.data.map((c) => ({
        text: getSymbolFromCurrency(c.Ccy) || c.Ccy || c.CcyNm,
        value: c.Ccy || c.CcyNm
    }));

    const initFormValues: formFields = {
        isRelativeCostEnabled: false,
        serviceType: '',
        currency: '',
        income: undefined,
        expense: undefined,
        totalCost: undefined,
        relativeCost: undefined,
        date: undefined,
        remarks: '',
        profit: undefined
    };
    const setInitialValues = () => {
        if (!billingLine || mode === formMode.NEW) {
            return initFormValues;
        } else {
            initFormValues.isRelativeCostEnabled = billingLine.relativeCost < 100;
            console.log('relative cost: ' + billingLine.relativeCost);
            console.log('is enabled: ' + initFormValues.isRelativeCostEnabled);
            initFormValues.serviceType = billingLine.serviceType;
            initFormValues.currency = billingLine.currency;
            initFormValues.income = billingLine.income;
            initFormValues.expense = billingLine.expense;
            initFormValues.totalCost = billingLine.totalCost;
            initFormValues.relativeCost = billingLine.relativeCost;
            initFormValues.date = billingLine.date || new Date();
            initFormValues.remarks = billingLine.remarks;
            initFormValues.profit = billingLine.profit;
            return initFormValues;
        }
    };
    const validate = (values: any) => {
        if (confirmation) {
            if (JSON.stringify(values) === JSON.stringify(initFormValues)) {
                confirmation.setConfirm(false);
            } else {
                confirmation.setConfirm(true);
            }
        }

        const errors: formFields = {};
        if (!values.serviceType) errors.serviceType = localization.billing_lines.form.required_error;
        if (!values.currency) errors.currency = localization.billing_lines.form.required_error;
        if (values.relativeCost > 100) errors.relativeCost = localization.billing_lines.form.relative_cost_error;
        if (values.remarks.length > 1000) errors.remarks = localization.billing_lines.form.remarks_max_length_error;
        return errors;
    };

    const submit = (values: any) => {
        if (entity) {
            const payload: billingLine = {
                serviceType: values.serviceType,
                currency: values.currency,
                income: Number(values.income) || 0,
                expense: Number(values.expense) || 0,
                totalCost: (!values.isRelativeCostEnabled ? Number(values.expense) : Number(values.expense / (values.relativeCost / 100))) || 0,
                relativeCost: (!initFormValues.isRelativeCostEnabled ? Number(values.relativeCost) : 100) || 100,
                remarks: values.remarks,
                date: values.date || new Date(),
                profit: Number(values.income - values.expense) || 0 - Number(values.expense) || Number(values.income) || 0,
                displayOnlyForCompanyId: UserCompanyID
            } as billingLine;
            if (mode === formMode.NEW) createBillingLine(entity.id, { ...payload });
            else if (billingLine)
                updateBillingLine(entity.id, {
                    ...payload,
                    id: billingLine.id
                });
        }
    };

    useEffect(() => {
        if (showLoader) {
            setLoading(true);
            AFTER_LOADING = true;
        } else {
            setLoading(false);
            if (AFTER_LOADING && openedFromModal) {
                openedFromModal.setVisible(false);
                AFTER_LOADING = false;
            }
        }
    }, [showLoader, openedFromModal]);
    return (
        <Formik initialValues={setInitialValues()} validate={validate} onSubmit={submit}>
            {({ values }) => (
                <Form>
                    <Container>
                        <FieldLabel>{localization.billing_lines.form.billinglineservicetype}</FieldLabel>
                        <FieldContainer>
                            <FormDropDown
                                name="serviceType"
                                placeHolder={localization.billing_lines.form.billinglineservicetype_placeholder}
                                options={billingLineServiceTypeOptions || []}
                                allowClear={true}
                                showSearch={true}
                            />
                        </FieldContainer>
                        <FieldLabel>{localization.billing_lines.form.billinglinecurrency}</FieldLabel>
                        <FieldContainer>
                            <FormDropDown
                                name="currency"
                                placeHolder={localization.billing_lines.form.billinglinecurrency_placeholder}
                                options={currencyOptions || []}
                                allowClear={true}
                                showSearch={true}
                                //style={{ width: '150px', marginRight: '15px' }}
                            />
                        </FieldContainer>
                        <FlexRow>
                            <FlexItem>
                                <FieldLabel>{localization.billing_lines.form.billinglineincome}</FieldLabel>
                            </FlexItem>
                            <FlexItem>
                                <FieldLabel>{localization.billing_lines.form.billinglineexpense}</FieldLabel>
                            </FlexItem>
                        </FlexRow>
                        <FlexRow>
                            <FieldContainer>
                                <FormNumberInput
                                    name="income"
                                    placeHolder={localization.billing_lines.form.billinglineincome_placeholder}
                                    style={{ width: '150px', marginRight: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormNumberInput
                                    name="expense"
                                    placeHolder={localization.billing_lines.form.billinglineexpense_placeholder}
                                    style={{ width: '150px', marginRight: '15px' }}
                                />
                            </FieldContainer>
                        </FlexRow>
                        {values.isRelativeCostEnabled && (
                            <>
                                <FlexItem>
                                    <FieldLabel>{localization.billing_lines.form.billinglinerelativecost}</FieldLabel>
                                </FlexItem>
                                <FieldContainer>
                                    <FormNumberInput
                                        name="relativeCost"
                                        placeHolder={localization.billing_lines.form.billinglinerelativecost_placeholder}
                                        style={{ width: '150px', marginRight: '15px' }}
                                    />
                                </FieldContainer>
                            </>
                        )}
                        <FieldContainer>
                            <FieldLabel>
                                <FormCheckbox name="isRelativeCostEnabled" style={{ color: '#008ac9' }}>
                                    {'Relative Cost?'}
                                </FormCheckbox>
                            </FieldLabel>
                        </FieldContainer>
                        <FieldLabel>{localization.billing_lines.form.billinglineremarks}</FieldLabel>
                        <FieldContainer>
                            <FormTextArea name="remarks" placeHolder={localization.billing_lines.form.billinglineremarks_placeholder} />
                        </FieldContainer>
                        <FieldLabel>{localization.billing_lines.form.billinglinedate}</FieldLabel>
                        <FieldContainer>
                            <FormDatePicker name="date" placeHolder={localization.billing_lines.form.billinglinedate_placeholder} />
                        </FieldContainer>
                    </Container>
                    {openedFromModal && (
                        <FormFooter>
                            <Button minWidth="55px" onClick={() => openedFromModal.setVisible(false)}>
                                <span>{'cancel'}</span>
                            </Button>
                            <Button minWidth="55px" buttonType="filled" type="submit">
                                {submitLoading ? (
                                    <Loader width="20px" marginTop="0px" showText={false} />
                                ) : (
                                    <span>
                                        {mode === formMode.NEW ? localization.billing_lines.modal.add_btn : localization.billing_lines.modal.edit_btn}
                                    </span>
                                )}
                            </Button>
                        </FormFooter>
                    )}
                </Form>
            )}
        </Formik>
    );
}

const FieldContainer = styled.div`
    margin-bottom: 12px;
    flex: 1;
`;
const FlexItem = styled.div`
    flex: 1;
`;

const Container = styled(ModalFormContainer)``;

const FormFooter = styled(ModalFooter)`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: white;
`;
const FlexRow = styled.div`
    display: flex;
`;

export default AccountingFormConnected;
