import React, { useState } from 'react';
import { FormCheckbox, FormDatePicker, FormDropDown, FormTextInput } from '../../../../shared/inputs/form';
import { renderShipmentTypeOption } from '../../../../shared/SmallComponents/ShipmentTypeOption';
import { FieldContainer, Flex, renderFieldLabelByName } from '../ShipmentForm';
import { eShipmentTypes } from '../../../../../models/entities/shipment/shipmentTypes';
import { eIncoterms } from '../../../../../models/entities/shipment/incoterms';
import { eContainerCapacity, eCustomsSubTypes, eInlandSubTypes } from '../../../../../models/entities/shipment/containerCapacity';
import { enumToDropdownOptions, Option } from '../../../../shared/inputs/base/DropDown';
import { eCommodityType } from '../../../../../models/entities/shipment/commodityType';
import References from './References';
import { styled } from '../../../../shared/Theme/theme';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import CreateBusinessPartner from './BusinessPartners/CreateBusinessPartner';
import { partiesToDropdownOptions } from '../../../businessPartners/components/BusinessPartnerForm/BusinessPartnerFormConnected';
import { filterIncoterms, getAllowedTypes, updatePickupAddress } from './utils';
import { allowedCompany } from '../../../../../models/entities/user';
import { eShipmentFormMode } from '../ShipmentFormConnected';
import { Radio, Switch } from 'antd';
import { shipmentFormLogics } from '../../util';
import { BidFormModal } from '../../BidForm/ShipmentBidFormConnected';
import { bid } from '../../../../../models/entities/bid/bid';
import { bidObject } from '../../BidForm/ShipmentBidForm';
import { featureFlagsModel } from '../../../../../models/entities/businessPartner/settings';

const shipmentTypesDropDownOptions = enumToDropdownOptions(eShipmentTypes);
const containerCapacityDropDownOptions = enumToDropdownOptions(eContainerCapacity);
const inlandSubTypesDropDownOptions = enumToDropdownOptions(eInlandSubTypes);
const customsSubTypesDropDownOptions = enumToDropdownOptions(eCustomsSubTypes);
const incotermsDropDownOptions = enumToDropdownOptions(eIncoterms);
const commodityDropDownOptions = enumToDropdownOptions(eCommodityType);

type Props = {
    values: any;
    setFieldValue: any;
    businessPartnerForwarders?: Array<businessPartner>;
    businessPartnerBrokers?: Array<businessPartner>;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
    businessPartnerManufacturers?: Array<businessPartner>;
    businessPartnerVendors?: Array<businessPartner>;
    businessPartnerCargoOwners?: Array<businessPartner>;
    localizations: any;
    allowedTypes: Array<string>;
    allowedCompanies: allowedCompany[];
    mode: string;
    userCompanyType: string;
    bidObject?: bidObject;
    setBidObject: React.Dispatch<React.SetStateAction<bidObject | undefined>>;
    featureFlags?: featureFlagsModel;
    isForwarder: boolean;
};
const General = ({
    values,
    setFieldValue,
    businessPartnerForwarders,
    businessPartnerBrokers,
    businessPartnerSuppliers,
    businessPartnerConsignees,
    businessPartnerManufacturers,
    businessPartnerCargoOwners,
    businessPartnerVendors,
    localizations,
    allowedTypes,
    allowedCompanies,
    mode,
    userCompanyType,
    bidObject,
    setBidObject,
    featureFlags,
    isForwarder
}: Props) => {
    const [tdi, setTdi] = useState(values.incoterms === eIncoterms.DDP);
    const types = allowedTypes;
    const allowedShipmentTypes: Option[] = getAllowedTypes(shipmentTypesDropDownOptions, types);
    const filteredIncoterms = filterIncoterms(values.shipment_type, incotermsDropDownOptions);

    const generalSectionLocalizations = localizations.form.sections.general;
    const fieldsLocalizations = generalSectionLocalizations.fields;

    const renderFieldTitle = (name: string, isMandatory: boolean) => renderFieldLabelByName(name, isMandatory, fieldsLocalizations);

    const onChangeSubtype = (value: any) => {
        if (value === 'FCL') setFieldValue('isTotals', false);
    };
    const changeAdressDefault = (value: any, isConsignee: boolean) => {
        if (isConsignee) {
            const consigneeAddresses = businessPartnerConsignees?.find((item) => item.id === values.consigneeId)?.addresses || [];
            const defaultAddress = consigneeAddresses.find((item) => item.isDefault) || (consigneeAddresses.length === 1 && consigneeAddresses[0]);
            if (defaultAddress && mode === eShipmentFormMode.NEW) {
                setFieldValue('consignee_addresses', defaultAddress.id);
                updatePickupAddress(value, 'destination', setFieldValue, defaultAddress, values);
            }
        } else {
            const shipperAddresses = businessPartnerVendors?.find((item) => item.id === values.shipperId)?.addresses || [];
            const defaultAddress = shipperAddresses.find((item) => item.isDefault) || (shipperAddresses.length === 1 && shipperAddresses[0]);
            if (defaultAddress && mode === eShipmentFormMode.NEW) {
                setFieldValue('shipper_addresses', defaultAddress.id);
                updatePickupAddress(value, 'origin', setFieldValue, defaultAddress, values);
            }
        }
    };

    return (
        <GeneralFormSection>
            <div style={{ borderRight: '1px solid #e0e0e0', paddingRight: '30px' }}>
                {shipmentFormLogics.companyId(allowedCompanies) && (
                    <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{renderFieldTitle('company', true)}</div>
                        <FormDropDown
                            name="companyId"
                            style={{ width: '200px', marginRight: '15px' }}
                            placeHolder={fieldsLocalizations.company.placeholder}
                            options={allowedCompanies
                                .filter((c) => !!c.parentCompany || !allowedCompanies.some((comp) => comp.parentCompany === c.companyId))
                                .map((item) => {
                                    return { value: item.companyId, text: item.companyName };
                                })}
                            onChange={(value: any) => {
                                setFieldValue('companyName', allowedCompanies?.find((item) => item.companyId === value)?.companyName);
                            }}
                        />
                    </FieldContainer>
                )}
                <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{renderFieldTitle('shipment_type', true)}</div>
                    <Flex>
                        <FormDropDown
                            name="shipment_type"
                            style={{ width: '200px', marginRight: '15px' }}
                            placeHolder={fieldsLocalizations.shipment_type.placeholder}
                            options={allowedShipmentTypes}
                            optionRenderer={renderShipmentTypeOption}
                            onChange={(value: any) => {
                                setFieldValue('sub_type', '');
                                if (value === 'RAIL') {
                                    setFieldValue('sub_type', 'FCL');
                                    setFieldValue('isTotals', false);
                                }
                                if (value === 'EXPRESS') {
                                    setFieldValue('incoterms', eIncoterms.DAP);
                                }
                                if (value === 'CUSTOMS') setFieldValue('BrokerAsForwarder', false);
                                if (values.consigneeId) {
                                    changeAdressDefault(value, true);
                                }
                                if (values.shipperId) {
                                    changeAdressDefault(value, false);
                                }
                            }}
                        />
                        {['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipment_type) && (
                            <FormDropDown
                                name="sub_type"
                                style={{ width: '120px' }}
                                placeHolder={fieldsLocalizations.sub_type.placeholder}
                                options={containerCapacityDropDownOptions}
                                onChange={onChangeSubtype}
                            />
                        )}
                        {['INLAND'].includes(values.shipment_type) && (
                            <FormDropDown
                                name="sub_type"
                                style={{ width: '120px' }}
                                placeHolder={fieldsLocalizations.sub_type.placeholder}
                                options={inlandSubTypesDropDownOptions}
                                onChange={onChangeSubtype}
                            />
                        )}
                        {['CUSTOMS'].includes(values.shipment_type) && (
                            <FormDropDown
                                name="sub_type"
                                style={{ width: '120px' }}
                                placeHolder={fieldsLocalizations.sub_type.placeholder}
                                options={customsSubTypesDropDownOptions}
                                onChange={onChangeSubtype}
                            />
                        )}
                    </Flex>
                </FieldContainer>
                {shipmentFormLogics.incoterms(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('incoterms', values.shipment_type !== 'INLAND')}</div>
                        <div>
                            <FormDropDown
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.incoterms.placeholder}
                                options={filteredIncoterms}
                                name="incoterms"
                            />
                        </div>
                    </FieldContainer>
                )}
                <FieldContainer>
                    <div>{renderFieldTitle(isForwarder ? 'cargo_owner' : 'forwarder', false)}</div>
                    <Flex style={{ alignItems: 'center' }}>
                        {!bidObject && (
                            <>
                                <FormDropDown
                                    name={isForwarder ? 'cargoOwnerId' : 'forwarderId'}
                                    style={{ width: '200px' }}
                                    placeHolder={
                                        isForwarder ? fieldsLocalizations.cargo_owner.placeholder : fieldsLocalizations.forwarder.placeholder
                                    }
                                    options={partiesToDropdownOptions(isForwarder ? businessPartnerCargoOwners : businessPartnerForwarders)}
                                    showSearch
                                    allowClear
                                />
                                <CreateBusinessPartner
                                    type={isForwarder ? 'CARGO_OWNER' : 'FORWARDER_BROKER'}
                                    modalTitle={
                                        localizations.form.parties.modal.create +
                                        ' ' +
                                        (isForwarder ? fieldsLocalizations.cargo_owner.title : localizations.form.parties.modal['FORWARDER'])
                                    }
                                    submitCallback={(businessPartner: businessPartner) =>
                                        setFieldValue(isForwarder ? 'cargoOwnerId' : 'forwarderId', businessPartner.id)
                                    }
                                    disabled={allowedCompanies.some((c) => (c.companyId === '747b3606-7bdd-42ac-99a6-fb2c8f1d03ad' ? true : false))}
                                />
                                {featureFlags?.BIDS && !isForwarder && <span>Or</span>}
                            </>
                        )}
                        {featureFlags?.BIDS && !isForwarder && (
                            <BidFormModal
                                onPublishCallBack={async (bid, publish) => setBidObject({ bid: { ...bidObject?.bid, ...bid }, publish })}
                                bid={bidObject?.bid}
                                btnDisabled={!!values.forwarderId}
                            />
                        )}
                    </Flex>
                </FieldContainer>
                {!isForwarder && (
                    <>
                        {!values.BrokerAsForwarder && (
                            <FieldContainer>
                                <div>{renderFieldTitle('broker', false)}</div>
                                <Flex>
                                    <FormDropDown
                                        name="brokerId"
                                        style={{ width: '200px' }}
                                        placeHolder={fieldsLocalizations.broker.placeholder}
                                        options={partiesToDropdownOptions(businessPartnerBrokers)}
                                        showSearch
                                    />
                                    <CreateBusinessPartner
                                        type="FORWARDER_BROKER"
                                        modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['BROKER']}
                                        submitCallback={(businessPartner: businessPartner) => setFieldValue('brokerId', businessPartner.id)}
                                    />
                                </Flex>
                            </FieldContainer>
                        )}
                        <FieldContainer>
                            <FormCheckbox name="BrokerAsForwarder">
                                <span>{generalSectionLocalizations.save_broker_same_as_forwarder}</span>
                            </FormCheckbox>
                        </FieldContainer>
                    </>
                )}
                {shipmentFormLogics.shipperId(values.shipment_type, userCompanyType) && (
                    <>
                        {!values.shipper_same_as_company && (
                            <FieldContainer>
                                <div>{renderFieldTitle('shipper', false)}</div>
                                <Flex>
                                    <FormDropDown
                                        name="shipperId"
                                        style={{ width: '200px' }}
                                        placeHolder={fieldsLocalizations.shipper.placeholder}
                                        options={partiesToDropdownOptions(businessPartnerVendors)}
                                        showSearch
                                        onChange={(value: string) => {
                                            const shipperAddresses = businessPartnerVendors?.find((item) => item.id === value)?.addresses || [];
                                            const defaultAddress =
                                                shipperAddresses.find((item) => item.isDefault) ||
                                                (shipperAddresses.length === 1 && shipperAddresses[0]);
                                            if (defaultAddress && mode === eShipmentFormMode.NEW) {
                                                setFieldValue('shipper_addresses', defaultAddress.id);
                                                updatePickupAddress(values.shipment_type, 'origin', setFieldValue, defaultAddress, values);
                                            }
                                        }}
                                    />
                                    <CreateBusinessPartner
                                        type="SUPPLIER"
                                        modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['SHIPPER']}
                                        submitCallback={(businessPartner: businessPartner) => {
                                            setFieldValue('shipperId', businessPartner.id);
                                        }}
                                    />
                                </Flex>
                            </FieldContainer>
                        )}
                        <FieldContainer>
                            <FormCheckbox name="shipper_same_as_company">
                                <span>{generalSectionLocalizations.shipper_same_as_company}</span>
                            </FormCheckbox>
                        </FieldContainer>
                    </>
                )}
                {shipmentFormLogics.consigneeId(values.shipment_type, userCompanyType) && (
                    <>
                        {!values.consignee_same_as_company && (
                            <FieldContainer>
                                <div>{renderFieldTitle('consignee', false)}</div>
                                <Flex>
                                    <FormDropDown
                                        name="consigneeId"
                                        style={{ width: '200px' }}
                                        placeHolder={fieldsLocalizations.consignee.placeholder}
                                        options={partiesToDropdownOptions(businessPartnerConsignees)}
                                        showSearch
                                        onChange={(value: string) => {
                                            const consigneeAddresses = businessPartnerConsignees?.find((item) => item.id === value)?.addresses || [];
                                            const defaultAddress =
                                                consigneeAddresses.find((item) => item.isDefault) ||
                                                (consigneeAddresses.length === 1 && consigneeAddresses[0]);
                                            if (defaultAddress && mode === eShipmentFormMode.NEW) {
                                                setFieldValue('consignee_addresses', defaultAddress.id);
                                                updatePickupAddress(values.shipment_type, 'destination', setFieldValue, defaultAddress, values);
                                            }
                                        }}
                                    />
                                    <CreateBusinessPartner
                                        type="CARGO_OWNER"
                                        modalTitle={localizations.form.parties.modal.create + ' ' + localizations.form.parties.modal['CONSIGNEE']}
                                        submitCallback={(businessPartner: businessPartner) => {
                                            setFieldValue('consigneeId', businessPartner.id);
                                        }}
                                    />
                                </Flex>
                            </FieldContainer>
                        )}
                        <FieldContainer>
                            <FormCheckbox name="consignee_same_as_company">
                                <span>{generalSectionLocalizations.consignee_same_as_company}</span>
                            </FormCheckbox>
                        </FieldContainer>
                    </>
                )}
                {shipmentFormLogics.taxesIncluded(values.shipment_type) && (
                    <FieldContainer>
                        <span>{generalSectionLocalizations.taxes_duties_at_destination_included} </span>
                        <Radio.Group
                            options={[
                                { label: generalSectionLocalizations.yes, value: true },
                                { label: generalSectionLocalizations.no, value: false }
                            ]}
                            onChange={(e) => {
                                const checked = e.target.value;
                                setTdi(checked);
                                setFieldValue('incoterms', checked ? eIncoterms.DDP : eIncoterms.DAP);
                            }}
                            value={tdi}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </FieldContainer>
                )}
                {shipmentFormLogics.tracking_number(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('tracking_number', false)}</div>
                        <div>
                            <FormTextInput
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.tracking_number.placeholder}
                                name="tracking_number"
                            />
                        </div>
                    </FieldContainer>
                )}
                {shipmentFormLogics.tracking_number(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('estimate_delivery', false)}</div>
                        <div>
                            <FormDatePicker
                                name="eta"
                                style={{ width: '200px' }}
                                placeHolder={fieldsLocalizations.estimate_delivery.placeholder}
                                showTime
                            />
                        </div>
                    </FieldContainer>
                )}

                {shipmentFormLogics.commodity(values.shipment_type) && (
                    <FieldContainer>
                        <div>{renderFieldTitle('commodity', false)}</div>
                        <Flex>
                            <FormTextInput
                                name="commodity"
                                placeHolder={fieldsLocalizations.commodity.title}
                                style={{ width: '200px', marginRight: '15px' }}
                            />
                            <FormDropDown name="commodity_type" style={{ width: '120px' }} placeHolder="Type" options={commodityDropDownOptions} />
                        </Flex>
                    </FieldContainer>
                )}
            </div>
            <div style={{ paddingLeft: '30px' }}>
                <References values={values} setFieldValue={setFieldValue} localizations={localizations} />
            </div>
        </GeneralFormSection>
    );
};

const GeneralFormSection = styled.div`
    display: flex;
    flex-direction: row;
`;

export default General;
