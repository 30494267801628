import React, { useEffect } from 'react';
import { shipment } from '../../../../models/entities/shipment/shipment';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '../../../../state/store/store';
import { localizationSelectors } from '../../../../state/ducks/localization/selectors';
import { businessPartnersSelectors } from '../../../../state/ducks/businessPartner/selectors';
import { businessPartner } from '../../../../models/entities/businessPartner/businessPartner';
import { singleShipmentSelectors } from '../../../../state/ducks/singleShipment/selectors';
import ShipmentForm from './ShipmentForm';
import Loader from '../../../shared/SmallComponents/Loader';
import { userSelectors } from '../../../../state/ducks/user/selectors';
import { allowedCompany, userInfo } from '../../../../models/entities/user';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import { bid } from '../../../../models/entities/bid/bid';
import { featureFlagsModel } from '../../../../models/entities/businessPartner/settings';
import { purchaseOrderItem } from '../../../../models/entities/purchaseOrder/purchaseOrderItem';

type PathParams = {
    shipmentId: string;
};

export type BaseProps = {
    shipment: shipment | undefined;
    localizations?: any;
    businessPartnersOptions?: any;
    businessPartnerForwarders?: Array<businessPartner>;
    businessPartnerBrokers?: Array<businessPartner>;
    businessPartnerSuppliers?: Array<businessPartner>;
    businessPartnerConsignees?: Array<businessPartner>;
    businessPartnerManufacturers?: Array<businessPartner>;
    businessPartnerVendors?: Array<businessPartner>;
    businessPartnerCargoOwners?: Array<businessPartner>;
    businessPartners?: Array<businessPartner>;
    createShipment: (shipment: shipment) => Promise<shipment>;
    updateShipment: (id: string, shipment: shipment) => Promise<boolean>;
    allowedTypes: Array<string>;
    allowedCompanies: allowedCompany[];
    shipmentFormError?: string;
    userCompanyType: string;
    createBid: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    updateBid: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    featureFlags?: featureFlagsModel;
    isForwarder: boolean;
};
type Props = RouteComponentProps<PathParams> &
    BaseProps & {
        setCurrentShipmentId: (currentShipmentId: string) => void;
        fetchSingleShipment: (shipmentId: string) => void;
    };
export const eShipmentFormMode = {
    NEW: 'new',
    EDIT: 'edit',
    DUPLICATE: 'duplicate'
};
const SingleShipmentFormConnected = ({
    localizations,
    businessPartnerForwarders,
    businessPartnerBrokers,
    businessPartnerSuppliers,
    businessPartnerConsignees,
    businessPartnerManufacturers,
    businessPartnerVendors,
    businessPartners,
    match,
    createShipment,
    updateShipment,
    shipment,
    setCurrentShipmentId,
    fetchSingleShipment,
    allowedTypes,
    allowedCompanies,
    shipmentFormError,
    userCompanyType,
    createBid,
    updateBid,
    featureFlags,
    isForwarder,
    businessPartnerCargoOwners
}: Props) => {
    const urlShipmentId = match.params.shipmentId;
    const location = useLocation<
        { initialValues?: any; poItems?: purchaseOrderItem[]; purchaseOrderId?: string; purchaseOrderCzNumber?: string } | undefined
    >();

    useEffect(() => {
        if (urlShipmentId) {
            setCurrentShipmentId(urlShipmentId);
            fetchSingleShipment(urlShipmentId);
        }
    }, [urlShipmentId, setCurrentShipmentId, fetchSingleShipment]);
    let mode: string = eShipmentFormMode.NEW;
    if (urlShipmentId) mode = eShipmentFormMode.EDIT;
    if (match.path.indexOf(PagesRoutes.DuplicateShipment) !== -1) mode = eShipmentFormMode.DUPLICATE;
    return urlShipmentId && !shipment ? (
        <Loader />
    ) : (
        <ShipmentForm
            mode={mode}
            shipment={shipment}
            localizations={localizations}
            businessPartnerForwarders={businessPartnerForwarders}
            businessPartnerBrokers={businessPartnerBrokers}
            businessPartnerSuppliers={businessPartnerSuppliers}
            businessPartnerConsignees={businessPartnerConsignees}
            businessPartnerManufacturers={businessPartnerManufacturers}
            businessPartnerVendors={businessPartnerVendors}
            businessPartners={businessPartners}
            createShipment={createShipment}
            updateShipment={updateShipment}
            allowedTypes={allowedTypes}
            allowedCompanies={allowedCompanies}
            shipmentFormError={shipmentFormError}
            userCompanyType={userCompanyType}
            createBid={createBid}
            updateBid={updateBid}
            featureFlags={featureFlags}
            initialValues={location?.state?.initialValues}
            poItems={location?.state?.poItems}
            purchaseOrderId={location?.state?.purchaseOrderId}
            purchaseOrderCzNumber={location?.state?.purchaseOrderCzNumber}
            businessPartnerCargoOwners={businessPartnerCargoOwners}
            isForwarder={isForwarder}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    localizations: localizationSelectors.allShipmentsPage(state),
    businessPartnerForwarders: businessPartnersSelectors.businessPartnerForwarders(state),
    businessPartnerBrokers: businessPartnersSelectors.businessPartnerBrokers(state),
    businessPartnerSuppliers: businessPartnersSelectors.businessPartnerSuppliers(state),
    businessPartnerConsignees: businessPartnersSelectors.businessPartnerConsignees(state),
    businessPartnerManufacturers: businessPartnersSelectors.businessPartnerManufacturers(state),
    businessPartnerVendors: businessPartnersSelectors.businessPartnerVendors(state),
    businessPartnerCargoOwners: businessPartnersSelectors.businessPartnerCargoOwners(state),
    businessPartners: businessPartnersSelectors.businessPartners(state),
    shipment: singleShipmentSelectors.shipment(state),
    allowedTypes: userSelectors.allowedTypes(state),
    allowedCompanies: userSelectors.allowedCompanies(state),
    shipmentFormError: state.error.effects.shipments.updateShipment || state.error.effects.shipments.createShipment,
    userCompanyType: userSelectors.userInfo(state).companyType,
    featureFlags: userSelectors.featureFlags(state),
    isForwarder: userSelectors.userInfo(state).companyType === 'FORWARDER'
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createShipment: (shipment: string) => dispatch.shipments.createShipment({ shipment }),
    updateShipment: (id: string, shipment: string) => dispatch.shipments.updateShipment({ id, shipment }),
    setCurrentShipmentId: (currentShipmentId: string) => dispatch.singleShipment.setCurrentShipmentId(currentShipmentId),
    fetchSingleShipment: (shipmentId: string) => dispatch.shipments.fetchShipmentById(shipmentId),
    createBid: (shipment: shipment, bid: bid, publish: boolean) => dispatch.bids.createBid({ shipment, bid, publish }),
    updateBid: (id: string, bid: bid, shipment: shipment, publish: boolean) => dispatch.bids.updateBid({ id, bid, publish, shipment })
});

export default connect(mapStateToProps, mapDispatchToProps as any)(SingleShipmentFormConnected);
