import React from 'react';
import { styled } from '../../../shared/Theme/theme';
import { settings, shipment } from '../../../../models/entities/shipment/shipment';
import WeightIcon from '../../../../static/icons/weight.svg';
import CustomModal, { ModalTriggerProps } from '../../../shared/Modal/Modal';
import PackagesTable from '../sections/shipmentPackages/PackagesTable';
import moment from 'moment';
import { shipmentCalcWeightVolume } from '../ShipmentForm/sections/Packages/util';
import carriers from '../../../../static/data/carriers.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Pagination]);

type Props = {
    shipment: shipment;
    localization: any;
    settings: settings;
};

const ShipmentOverviewTab = ({ shipment, localization, settings }: Props) => {
    let hideWeight = false;
    let hideChargeableWeight = false;
    if (shipment.subTypeCode === 'FCL') hideWeight = true;
    if (shipment.subTypeCode === 'LCL') hideChargeableWeight = true;

    const { chargeableWeight } = shipmentCalcWeightVolume(shipment);

    return (
        <OverviewWrapper>
            <DetailsWrapper>
                <Swiper
                    tag="section"
                    pagination={{
                        // el: '.swiper-pagination',
                        clickable: true
                    }}
                    style={{ width: '100%', height: '100%' }}
                >
                    <SwiperSlide style={{ width: '100%', height: '100%' }}>
                        <DetailsRow>
                            <DetailBox>
                                <DetailTitle>{localization.house}</DetailTitle>
                                <DetailValue>{shipment.house}</DetailValue>
                            </DetailBox>
                            <DetailBox>
                                <DetailTitle>{localization.master}</DetailTitle>
                                <DetailValue>{shipment.master}</DetailValue>
                            </DetailBox>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailBox>
                                <DetailTitle>{localization.etd}</DetailTitle>
                                <DetailValue>{moment(shipment.etd).format('DD/MM/YYYY')}</DetailValue>
                            </DetailBox>
                            <DetailBox>
                                <DetailTitle>{localization.incoterms}</DetailTitle>
                                <DetailValue>{shipment.incoterms}</DetailValue>
                            </DetailBox>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailBox>
                                <DetailTitle>{localization.carrier}</DetailTitle>
                                <DetailValue>{carriers.find((item) => item.carrierCode === shipment.carrierCode)?.carrierName}</DetailValue>
                            </DetailBox>
                            <DetailBox>
                                <DetailTitle>{localization.commodity}</DetailTitle>
                                <DetailValue>{shipment.commodity}</DetailValue>
                            </DetailBox>
                        </DetailsRow>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: '100%', height: '100%' }}>
                        <DetailsRow>
                            <DetailBox>
                                <DetailTitle>{localization.customs_file}</DetailTitle>
                                <DetailValue>{shipment.customsFile}</DetailValue>
                            </DetailBox>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailBox>
                                <DetailTitle>{localization.quantity}</DetailTitle>
                                <DetailValue>
                                    <Row>
                                        {shipment.quantity}
                                        <CustomModal
                                            title={{ text: localization.packages.modal_title }}
                                            Trigger={(props: ModalTriggerProps) => (
                                                <PackageSeeMore onClick={props.onClick}>{localization.see_more}</PackageSeeMore>
                                            )}
                                            Content={() => (
                                                <PackagesTable
                                                    packages={shipment.packages}
                                                    localization={localization}
                                                    type={shipment.typeCode}
                                                    sub_type={shipment.subTypeCode}
                                                />
                                            )}
                                            width="700px"
                                        />
                                    </Row>
                                </DetailValue>
                            </DetailBox>
                            {!hideWeight && (
                                <DetailBox>
                                    <DetailTitle>
                                        <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={localization.weight} />
                                        {localization.weight}
                                    </DetailTitle>
                                    <DetailValue>
                                        {shipment.weight} {shipment.weightUnit}
                                    </DetailValue>
                                </DetailBox>
                            )}
                        </DetailsRow>
                        <DetailsRow>
                            {!hideWeight && (
                                <>
                                    <DetailBox>
                                        <DetailTitle>{localization.volume}</DetailTitle>
                                        <DetailValue>
                                            {shipment.volume} {shipment.volumeUnit}
                                        </DetailValue>
                                    </DetailBox>
                                    {!hideChargeableWeight && (
                                        <DetailBox>
                                            <DetailTitle>
                                                <img style={{ verticalAlign: 'sub' }} src={WeightIcon} alt={localization.chargeable_weight} />{' '}
                                                {localization.chargeable_weight}
                                            </DetailTitle>
                                            <DetailValue>{chargeableWeight.toFixed(3)}</DetailValue>
                                        </DetailBox>
                                    )}
                                </>
                            )}
                        </DetailsRow>
                    </SwiperSlide>
                </Swiper>
            </DetailsWrapper>
        </OverviewWrapper>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    line-height: 20px;
`;

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;
const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 16px 0 16px;
    height: 100%;
`;
const StyledBullet = styled.span`
    display: flex;
    flex-direction: column;
    padding: 10px 16px 0 16px;
    height: 100%;
`;

const DetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1000px;
`;

const DetailBox = styled.div`
    flex: 1;
    white-space: nowrap;
    margin-bottom: 10px;
`;

const DetailTitle = styled.div`
    color: #008ac9;
`;

const DetailValue = styled.div`
    color: #394372;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
`;
const PackageSeeMore = styled.div`
    font-size: 12px;
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;
export default ShipmentOverviewTab;
