import * as Yup from 'yup';
import moment from 'moment';
import { AnyObject } from 'yup/lib/types';

export const validationSchema = (localizations: any) => {
    const requiredFieldMessage = localizations.form.requiredFieldMessage;
    const dateAfterMessage = localizations.form.dateAfterMessage;
    return Yup.object({
        // forwarderId: Yup.string().nullable().required(requiredFieldMessage),
        // shipperId: Yup.string().when('shipment_type', {
        //     is: (value: string) => ['DROP_AIR', 'DROP_OCEAN', 'AI', 'OI', 'INLAND', 'RAIL'].includes(value),
        //     then: Yup.string().nullable().required(requiredFieldMessage),
        //     otherwise: Yup.string().nullable()
        // }),
        // consigneeId: Yup.string().when('shipment_type', {
        //     is: (value: string) => ['DROP_AIR', 'DROP_OCEAN', 'AE', 'OE', 'INLAND', 'RAIL'].includes(value),
        //     then: Yup.string().nullable().required(requiredFieldMessage),
        //     otherwise: Yup.string().nullable()
        // }),
        // brokerId: Yup.string().when('shipment_type', {
        //     is: (value: string) => ['CUSTOMS'].includes(value),
        //     then: Yup.string().nullable().required(requiredFieldMessage),
        //     otherwise: Yup.string().nullable()
        // }),
        commodity: Yup.string().nullable().test('len', 'Max 200 characters', maxLengthValidation(200)),
        shipment_type: Yup.string().required(requiredFieldMessage),
        // origin: Yup.string().when('shipment_type', {
        //     is: (value: string) => !['EXPRESS', 'INLAND'].includes(value),
        //     then: Yup.string().nullable().required(requiredFieldMessage),
        //     otherwise: Yup.string().nullable()
        // }),
        origin_country: Yup.string().required(requiredFieldMessage),
        destination: Yup.string().when('shipment_type', {
            is: (value: string) => !['EXPRESS', 'INLAND'].includes(value),
            then: Yup.string().nullable().required(requiredFieldMessage),
            otherwise: Yup.string().nullable()
        }),
        destination_country: Yup.string().required(requiredFieldMessage),
        incoterms: Yup.string().when('shipment_type', {
            is: (value: string) => !['INLAND'].includes(value),
            then: Yup.string().nullable().required(requiredFieldMessage),
            otherwise: Yup.string().nullable()
        }),
        sub_type: Yup.string().when('shipment_type', {
            is: (value: string) => ['OI', 'OEX', 'OE', 'DROP_OCEAN', 'INLAND'].includes(value),
            then: Yup.string().required(requiredFieldMessage).nullable(),
            otherwise: Yup.string().nullable()
        }),
        destination_pickup_address: Yup.string().nullable().test('len', 'Max 150 characters', maxLengthValidation(150)),
        origin_pickup_address: Yup.string().nullable().test('len', 'Max 150 characters', maxLengthValidation(150)),
        destination_zipcode: Yup.string()
            .when('shipment_type', {
                is: (value: string) => ['INLAND', 'RAIL'].includes(value),
                then: Yup.string().required(requiredFieldMessage).nullable(),
                otherwise: Yup.string().nullable()
            })
            .test('len', 'Max 200 characters', maxLengthValidation(200)),
        origin_zipcode: Yup.string()
            .when('shipment_type', {
                is: (value: string) => ['INLAND', 'RAIL'].includes(value),
                then: Yup.string().required(requiredFieldMessage).nullable(),
                otherwise: Yup.string().nullable()
            })
            .test('len', 'Max 200 characters', maxLengthValidation(200)),
        eta: Yup.date()
            .when('etd', (st: string, schema: any) => {
                if (st) {
                    return schema.min(new Date(st), dateAfterMessage(moment(st).format('DD/MM/YYYY HH:mm')));
                }

                return;
            })
            .nullable()
    });
};

const maxLengthValidation = (maxLength: number) => {
    return (val: any) => (val ? val.toString().length <= maxLength : true);
};
