import { RootState } from '../../../state/store/store';
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { shipmentsSelectors } from '../shipments/selectors';
import { settings, shipment } from '../../../models/entities/shipment/shipment';
import filterShipments from './shipmentsGridFilter';
import { BoardColumnMap } from '../../../components/shared/Board/types/BoardColumn';
import { gridSortCallback } from '../../../utils/sortArray';

const gridColumns = (state: RootState) => state.allShipmentsPage.gridColumns;
const fetchGridColumnsError = (state: RootState) => state.allShipmentsPage.fetchGridColumnsError;
const gridSort = (state: RootState) => state.allShipmentsPage.gridSort;
const pagination = (state: RootState) => state.allShipmentsPage.pagination;
const gridFilter = (state: RootState) => state.allShipmentsPage.gridFilter;
const filterFields = (state: RootState) => state.allShipmentsPage.filterFields;

const shipmentActiveFilter = createSelector(filterFields, (filterFields) => {
    return filterFields?.find((f: { field: string; value: string }) => f.field === 'isActive');
});

const activeFiltersCount = createSelector(filterFields, (filterFields) => {
    if (!filterFields) return 0;
    return filterFields.length;
});

const activeShipmentsCount = createSelector(shipmentsSelectors.shipmentsData, (shipmentsData: Array<shipment>) => {
    return shipmentsData.length;
});

const shipmentsBoardData = createSelector(
    shipmentsSelectors.shipmentsData,
    shipmentsSelectors.settingsByLanguage,
    gridFilter,
    filterFields,
    (shipmentsData: Array<shipment>, settingsByLanguage, gridFilter, filterFields) => {
        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            shipmentsData = shipmentsData.filter((s) => JSON.stringify(s).toLocaleLowerCase().includes(gridFilter.toLocaleLowerCase()));
        }

        if (filterFields) {
            shipmentsData = filterShipments(shipmentsData, filterFields);
        }

        shipmentsData = orderBy(shipmentsData, ['ata', 'eta', 'createdAt'], ['desc']);

        let boardData: BoardColumnMap<shipment> = {};

        settingsByLanguage()
            .state?.sort((a, b) => a.displayOrder - b.displayOrder)
            .forEach((state) => {
                const shipmentsByState = shipmentsData.filter((shipment: shipment) => shipment.state === state.code);
                boardData[state.code] = {
                    isOpen: !!shipmentsByState.length,
                    title: state.name,
                    toolTipText: state.toolTipText,
                    items: shipmentsByState.sort((a, b) => (a.alertStatus || 10) - (b.alertStatus || 10))
                };
            });
        return boardData;
    }
);

const gridData = createSelector(
    shipmentsSelectors.shipmentsData,
    gridSort,
    gridFilter,
    filterFields,
    shipmentsSelectors.settingsByLanguage,
    (shipmentsData: Array<shipment>, gridSort, gridFilter, filterFields, settingsByLanguage) => {
        const settings: settings = settingsByLanguage();

        if (gridFilter && gridFilter !== '' && gridFilter.length > 2) {
            shipmentsData = shipmentsData.filter((s) => JSON.stringify(s).toLocaleLowerCase().trim().includes(gridFilter.toLocaleLowerCase().trim()));
        }

        if (filterFields) {
            shipmentsData = filterShipments(shipmentsData, filterFields);
        }

        if (gridSort) {
            switch (gridSort.column) {
                case 'state': {
                    const getStateOrder = (state: string) => settings.state?.find((s) => s.code === state)?.displayOrder || 0;
                    shipmentsData = shipmentsData.sort((a, b) => {
                        return (gridSort.direction === 'asc' ? -1 : 1) * (getStateOrder(b.state) - getStateOrder(a.state));
                    });
                    break;
                }
                case 'CargoZoneNumber':
                case 'typeCode':
                case 'clientRef':
                case 'forwarderRef':
                case 'originCountry':
                default:
                    shipmentsData = orderBy(shipmentsData, gridSortCallback(gridSort.column), [gridSort.direction]);
            }
        }

        return shipmentsData;
    }
);

const filteredShipmentsCount = createSelector(gridData, (gridData) => {
    return gridData?.length || 0;
});

const currentGridDataPage = createSelector(pagination, gridData, (pagination, gridData) => {
    const { currentPage, rowsPerPage } = pagination;

    return gridData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
});

export { default as allShipmentsPageSelectors } from './selectors';

export default {
    gridColumns,
    fetchGridColumnsError,
    gridSort,
    gridFilter,
    filterFields,
    gridData,
    currentGridDataPage,
    shipmentsBoardData,
    activeFiltersCount,
    activeShipmentsCount,
    filteredShipmentsCount,
    shipmentActiveFilter,
    pagination
};
