import moment from 'moment';
import { filterFieldsType } from '../../../state/ducks/allShipmentsPage/allShipmentsPage';
import { eShipmentTypes } from '../../../models/entities/shipment/shipmentTypes';
import countries from '../../../static/data/countries.json';
import stations from '../../../static/data/stations.json';
import { allowedCompany } from '../../../models/entities/user';
import { settings } from '../../../models/entities/shipment/shipment';

export type parsedFilterFieldType = {
    field: string;
    parsedValue: string;
    originalValue: any;
};

export const parseFilterFields = (filterFields: filterFieldsType, allowedCompanies: allowedCompany[], settings: settings, localization: any) => {
    let parsedFilterFields: parsedFilterFieldType[] = [];
    filterFields.forEach(({ field, value }: { field: string; value: any }) => {
        switch (field) {
            case 'companyId':
                value.forEach((type: string) => {
                    parsedFilterFields.push({
                        field,
                        parsedValue: allowedCompanies.find((c) => c.companyId === type)?.companyName || '',
                        originalValue: type
                    });
                });
                break;
            case 'typeCode':
                value.forEach((type: string) => {
                    parsedFilterFields.push({ field, parsedValue: eShipmentTypes[type], originalValue: type });
                });
                break;

            case 'incoterms':
                value.forEach((type: string) => {
                    parsedFilterFields.push({ field, parsedValue: type, originalValue: type });
                });
                break;
            case 'forwarderName':
                value.forEach((type: string) => {
                    parsedFilterFields.push({ field, parsedValue: type, originalValue: type });
                });
                break;

            case 'fromDate':
            case 'toDate':
            case 'fromETA':
            case 'toETA':
                let title = (() => {
                    switch (field) {
                        case 'fromDate':
                            return localization.date_from;
                        case 'toDate':
                            return localization.date_to;
                        case 'fromETA':
                            return localization.eta_from;
                        case 'toETA':
                            return localization.eta_to;
                        default:
                            return '';
                    }
                })();
                parsedFilterFields.push({ field, parsedValue: title + moment(value).format('DD/MM/YYYY'), originalValue: value });

                break;

            case 'originCountry':
            case 'destinationCountry':
                let countryTitle = (() => {
                    switch (field) {
                        case 'originCountry':
                            return localization.origin_country;
                        case 'destinationCountry':
                            return localization.destination_country;
                        default:
                            return '';
                    }
                })();

                const country = countries.find((country) => country.code === value);
                parsedFilterFields.push({ field, parsedValue: countryTitle + country?.name || '', originalValue: value });
                break;

            case 'originStation':
            case 'destinationStation':
                let stationTitle = (() => {
                    switch (field) {
                        case 'originStation':
                            return localization.origin_station;
                        case 'destinationStation':
                            return localization.destination_station;
                        default:
                            return '';
                    }
                })();
                const station = stations.find((s) => s.code === value && s.country);
                parsedFilterFields.push({ field, parsedValue: stationTitle + station?.location || '', originalValue: value });
                break;

            case 'alertStatus': {
                value.forEach((v: string) => {
                    const parsedValue = (() => {
                        switch (v) {
                            case '1':
                                return localization.critical;
                            case '2':
                                return localization.warning;
                            default:
                                return localization.all_good;
                        }
                    })();
                    parsedFilterFields.push({ field, parsedValue, originalValue: v });
                });
                break;
            }
            case 'salesman': {
                parsedFilterFields.push({ field, parsedValue: 'Salesman', originalValue: value });
                break;
            }
            case 'state':
                value.forEach((v: string) => {
                    parsedFilterFields.push({ field, parsedValue: settings.state?.find((s) => s.code === v)?.name || v, originalValue: v });
                });
                break;
            case 'carrierCode':
                parsedFilterFields.push({ field, parsedValue: localization.carrier + value, originalValue: value });
                break;
            case 'shipperName':
                parsedFilterFields.push({ field, parsedValue: localization.vendor + value, originalValue: value });
                break;
            case 'isActive':
                const parsedValue = (() => {
                    switch (value) {
                        case 'true':
                            return localization.active;
                        case 'false':
                            return localization.inactive;
                        default:
                            return localization.all;
                    }
                })();
                parsedFilterFields.push({ field, parsedValue, originalValue: value });
                break;
            default:
                parsedFilterFields.push({ field, parsedValue: value, originalValue: value });

                break;
        }
    });

    return parsedFilterFields;
};

export const removeFilterItem = (removedFilter: parsedFilterFieldType, filterFields: filterFieldsType) => {
    let newFilterFields: filterFieldsType = [...filterFields];
    const { field, originalValue } = removedFilter;

    switch (field) {
        case 'typeCode':
        case 'incoterms':
        case 'forwarderName':
        case 'companyId':
        case 'alertStatus':
        case 'state':
            let arrayFilterIndex = newFilterFields.findIndex((filter) => filter.field === field);
            if (arrayFilterIndex > -1) {
                const newArray = (newFilterFields[arrayFilterIndex]?.value as string[]).filter((type) => type !== originalValue);
                newFilterFields[arrayFilterIndex] = {
                    field: field,
                    value: newArray
                };
                if (!newArray.length) {
                    newFilterFields.splice(arrayFilterIndex, 1);
                }
            }
            break;

        default:
            let filterIndex = newFilterFields.findIndex((filter) => filter.field === field && filter.value === originalValue);
            if (filterIndex > -1) {
                newFilterFields.splice(filterIndex, 1);
            }
            break;
    }

    return newFilterFields;
};
