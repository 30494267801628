import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { shipment, shipmentSubType } from '../../../../models/entities/shipment/shipment';
import BackButton from '../../../shared/SmallComponents/BackButton';
import RouteLeavingGuard from '../../../shared/SmallComponents/RouteLeavingGuard';
import { PagesRoutes } from '../../../../routing/PagesRoutes';
import theme, { styled } from '../../../shared/Theme/theme';
import FormSection from './FormSection';
import FormFieldLabel from '../../../shared/Form/FormFieldLabel';
import { Formik, Form } from 'formik';
import Button from '../../../shared/SmallComponents/Button';
import { validationSchema } from '../../../../validationSchemas/shipmentForm';
import { shipmentType } from '../../../../models/entities/shipment/shipment';
import { useHistory } from 'react-router-dom';
import General from './sections/General';
import Geography from './sections/Geography';
import Billing from './sections/Billing';
import Packages from './sections/Packages';
import Amazon from './sections/Amazon';
import stations from '../../../../static/data/stations.json';

import {
    defaultContainers,
    defaultPackages,
    packagesPrepareSubmit,
    containersPrepareSubmit,
    packagesPrepareEdit,
    containersPrepareEdit,
    validatePackages,
    calcWeightVolume,
    validateContainers
} from './sections/Packages/util';
import { emptyRefObj } from './sections/References';
import { filterBusinessPartners, partiesPrepareSubmit, poItemsToShipmentItems, duplicateShipmentItems } from './sections/utils';
import { BaseProps, eShipmentFormMode } from './ShipmentFormConnected';
import Loader from '../../../shared/SmallComponents/Loader';
import CustomModal from '../../../shared/Modal/Modal';
import { bidObject } from '../BidForm/ShipmentBidForm';
import { purchaseOrderItem } from '../../../../models/entities/purchaseOrder/purchaseOrderItem';

type Props = BaseProps & {
    mode: string;
    shipmentFormError?: string;
    initialValues?: any;
    poItems?: purchaseOrderItem[];
    purchaseOrderId?: string;
    purchaseOrderCzNumber?: string;
};

type formSectionType = { name: string; component: JSX.Element; errors: string[] };

export const renderFieldLabelByName = (fieldName: string, isMandatory: boolean, sectionFieldLocalization: any) => {
    const { title, tooltip } = sectionFieldLocalization[fieldName];

    return <FormFieldLabel text={title} isMandatory={isMandatory} tooltipText={tooltip} />;
};
let sectionErrorIndexes: Array<number> = [];
const SingleShipmentForm = ({
    localizations,
    businessPartnerForwarders,
    businessPartnerBrokers,
    businessPartnerSuppliers,
    businessPartnerConsignees,
    businessPartnerManufacturers,
    businessPartnerVendors,
    businessPartners,
    createShipment,
    updateShipment,
    shipment,
    mode,
    allowedTypes,
    allowedCompanies,
    shipmentFormError,
    userCompanyType,
    createBid,
    updateBid,
    featureFlags,
    initialValues,
    poItems,
    purchaseOrderId,
    purchaseOrderCzNumber,
    isForwarder,
    businessPartnerCargoOwners
}: Props) => {
    const history = useHistory();
    const [activeFormSection, setActiveFormSection] = useState(1);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [currentShipmentId, setCurrentShipmentId] = useState<string | null>(null);
    const [showGoBackModal, setShowGoBackModal] = useState(false);
    const [bidObject, setBidObject] = useState<bidObject | undefined>();

    useEffect(() => {
        if ([eShipmentFormMode.EDIT, eShipmentFormMode.DUPLICATE].includes(mode) && shipment) {
            setCurrentShipmentId(shipment.id);
            //if (shipment.bid) {
            //setBidObject({ bid: shipment?.bid } as bidObject);
            //}
        } else {
            setCurrentShipmentId(null);
        }
    }, [mode, shipment]);
    const onCancelClick = () => {
        history.push(PagesRoutes.Shipments);
    };

    const renderGoBackModal = () => {
        return (
            <CustomModal
                title={{ text: localizations.form.where_do_you_want_to_go }}
                isOpen
                // Trigger={(props: ModalTriggerProps) => <>{props.onClick()}</>}
                Content={(setVisible?: React.Dispatch<React.SetStateAction<boolean>>) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.Shipments);
                                }}
                            >
                                {localizations.form.back_modal.shipments_page}
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push(PagesRoutes.Shipments + '/' + currentShipmentId);
                                }}
                            >
                                {localizations.form.back_modal.shipment_details}
                            </Button>
                        </div>
                    );
                }}
                onCancelCallback={() => {
                    setShowGoBackModal(false);
                }}
                width="393px"
            />
        );
    };

    const setInitialValues = () => {
        const initValues = {
            forwarderId: null,
            cargoOwnerId: null,
            shipperId: null,
            consigneeId: null,
            brokerId: null,
            BrokerAsForwarder: true,
            isTotals: true,
            commodity: '',
            sub_type: '',
            companyId: '',
            companyName: '',
            commodity_type: '',
            origin: '',
            origin_zipcode: '',
            origin_country: '',
            origin_pickup_address: '',
            destination: '',
            destination_zipcode: '',
            destination_country: '',
            destination_pickup_address: '',
            master: '',
            house: '',
            customs_file: '',
            port_terminal: '',
            incoterms: '',
            operator: '',
            carrier: '',
            vessel_name: '',
            etd: null,
            eta: null,
            goods_ready: null,
            pick_up: null,
            voyage_number: '',
            shipment_type: '',
            weight: 0,
            weightUnit: 'KG',
            volume: 0,
            volumeUnit: 'CBM',
            quantity: 0,
            goods_value: 0,
            goods_value_currency: 'USD',
            trans_price: 0,
            trans_price_currency: 'USD',
            buying_rate: 0,
            buying_rate_currency: 'USD',
            selling_rate: 0,
            selling_rate_currency: 'USD',
            chargeable_weight_unit: 'KG',
            packages: defaultPackages,
            containers: defaultContainers,
            references: [emptyRefObj],
            parties: [],
            shipper_same_as_company: false,
            consignee_same_as_company: false,
            tracking_number: '',
            delivery_note: '',
            amazon_id: '',
            fba_id: '',
            amazon_appointment_date: null,
            ...(initialValues || {})
        };
        if ([eShipmentFormMode.EDIT, eShipmentFormMode.DUPLICATE].includes(mode) && shipment) {
            let etdDateValue;
            if (shipment.etd) {
                const etdDate = new Date(shipment.etd);
                etdDateValue = moment(etdDate).isValid() ? etdDate : new Date();
            }
            let etaDateValue;
            if (shipment.eta) {
                const etaDate = new Date(shipment.eta);
                etaDateValue = moment(etaDate).isValid() ? etaDate : new Date();
            }
            let goodsReadyValue;
            if (shipment.goodsReady) {
                const goodsReady = new Date(shipment.goodsReady);
                goodsReadyValue = moment(goodsReady).isValid() ? goodsReady : null;
            }
            let pickUpValue;
            if (shipment.pickUp) {
                const pickUp = new Date(shipment.pickUp);
                pickUpValue = moment(pickUp).isValid() ? pickUp : null;
            }
            return {
                forwarderId: isForwarder ? shipment.companyId : shipment.forwarderId,
                cargoOwnerId: shipment.cargoOwnerId,
                shipperId: shipment.shipperId,
                consigneeId: shipment.consigneeId,
                brokerId: shipment.brokerId,
                BrokerAsForwarder: true,
                isTotals: !!shipment.isTotals,
                commodity: shipment.commodity,
                commodity_type: shipment.commodityType,
                companyId: shipment.companyId,
                companyName: shipment.companyName,
                origin: shipment.originStation,
                origin_zipcode: shipment.originZipCode,
                origin_country: shipment.originCountry,
                destination: shipment.destinationStation,
                destination_zipcode: shipment.destinationZipCode,
                destination_country: shipment.destinationCountry,
                master: shipment.master,
                house: shipment.house,
                customs_file: shipment.customsFile,
                // port_terminal: shipment.port_terminal,
                incoterms: shipment.incoterms,
                carrier: shipment.carrierCode,
                operator: shipment.operator,
                vessel_name: shipment.vesselName || '',
                etd: etdDateValue,
                eta: etaDateValue,
                goods_ready: goodsReadyValue,
                pick_up: pickUpValue,
                voyage_number: shipment.voyageNumber,
                shipment_type: shipment.typeCode,
                sub_type: (shipment.subTypeCode && shipment.subTypeCode.trim()) || '',
                origin_pickup_address: shipment.originPickupAddress,
                destination_pickup_address: shipment.destinationPickupAddress,
                packages: packagesPrepareEdit(shipment.packages),
                containers: containersPrepareEdit(shipment.containers),
                references: shipment.references && shipment.references.length > 0 ? shipment.references : [emptyRefObj],
                weight: shipment.weight,
                weightUnit: shipment.weightUnit,
                volume: shipment.volume,
                volumeUnit: shipment.volumeUnit,
                parties: shipment.parties,
                shipper_same_as_company: shipment.shipperSameAsCompany,
                consignee_same_as_company: shipment.consigneeSameAsCompany,
                quantity: shipment.quantity,
                goods_value: shipment.goodsValue,
                goods_value_currency: shipment.goodsValueCurrency,
                trans_price: shipment.transportationPrice,
                trans_price_currency: shipment.transportationPriceCurrency,
                buying_rate: shipment.buyingRate,
                buying_rate_currency: shipment.buyingRateCurrency,
                selling_rate: shipment.sellingRate,
                selling_rate_currency: shipment.sellingRateCurrency,
                tracking_number: shipment.forwarderUniqueId,
                delivery_note: shipment.deliveryNote,
                amazon_appointment_date: shipment.amazonAppointmentDate
            };
        }
        return initValues;
    };

    const onNextClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection + 1);
    };
    const onPreviousClick = (event: any) => {
        event.preventDefault();
        setActiveFormSection(activeFormSection - 1);
    };
    const onSectionNumberClick = (sectionNumber: number) => {
        setActiveFormSection(sectionNumber);
    };

    const formValidationSchema = validationSchema(localizations);

    const validate = (values: any) => {
        const errors: any = {};
        if (values.packages) validatePackages(values.packages, errors, localizations);
        if (values.containers) validateContainers(values.containers, errors, localizations);
        if (allowedCompanies.length > 1 && !values.companyId) errors.companyId = localizations.form.requiredFieldMessage;
        return errors;
    };

    const getFormSections = (values: any, setFieldValue: any): formSectionType[] => {
        const sections = [
            {
                name: 'General',
                component: (
                    <General
                        values={values}
                        setFieldValue={setFieldValue}
                        businessPartnerForwarders={filterBusinessPartners(businessPartnerForwarders, values.companyId, allowedCompanies)}
                        businessPartnerBrokers={filterBusinessPartners(businessPartnerBrokers, values.companyId, allowedCompanies)}
                        businessPartnerSuppliers={filterBusinessPartners(businessPartnerSuppliers, values.companyId, allowedCompanies)}
                        businessPartnerManufacturers={filterBusinessPartners(businessPartnerManufacturers, values.companyId, allowedCompanies)}
                        businessPartnerVendors={filterBusinessPartners(businessPartnerVendors, values.companyId, allowedCompanies)}
                        businessPartnerConsignees={filterBusinessPartners(businessPartnerConsignees, values.companyId, allowedCompanies)}
                        businessPartnerCargoOwners={filterBusinessPartners(businessPartnerCargoOwners, values.companyId, allowedCompanies)}
                        localizations={localizations}
                        allowedTypes={allowedTypes}
                        allowedCompanies={allowedCompanies}
                        mode={mode}
                        userCompanyType={userCompanyType}
                        bidObject={bidObject}
                        setBidObject={setBidObject}
                        featureFlags={featureFlags}
                        isForwarder={isForwarder}
                    />
                ),
                errors: [
                    'shipment_type',
                    'sub_type',
                    'forwarderId',
                    'cargoOwnerId',
                    'shipperId',
                    'consigneeId',
                    'incoterms',
                    'commodity',
                    'commodity_type'
                ]
            },
            {
                name: 'Geography',
                component: (
                    <Geography
                        values={values}
                        setFieldValue={setFieldValue}
                        businessPartnerSuppliers={filterBusinessPartners(businessPartnerSuppliers, values.companyId)}
                        businessPartnerConsignees={filterBusinessPartners(businessPartnerConsignees, values.companyId)}
                        localizations={localizations}
                    />
                ),
                errors: ['origin_country', 'destination_country', 'destination', 'destination_pickup_address']
                //errors: ['origin_country', 'origin', 'origin_pickup_address', 'destination_country', 'destination', 'destination_pickup_address']
            },
            {
                name: 'Goods & shipping details',
                component: (
                    <Billing
                        values={values}
                        setFieldValue={setFieldValue}
                        isFreeLancerOrForwarder={userCompanyType === 'FREELANCER' || userCompanyType === 'FORWARDER'}
                        localizations={localizations}
                    />
                ),
                errors: ['carrier', 'master', 'etd', 'house', 'eta', 'customs_file']
            },
            {
                name: 'Packages',
                component: <Packages values={values} setFieldValue={setFieldValue} localizations={localizations} />,
                errors: ['packages', 'containers']
            }
        ];

        if (featureFlags?.AMAZON) {
            sections.splice(2, 0, {
                name: 'Amazon',
                component: <Amazon values={values} localizations={localizations} />,
                errors: []
            });
        }
        return sections;
    };
    return (
        <>
            <Formik
                initialValues={setInitialValues()}
                validationSchema={formValidationSchema}
                validate={validate}
                onSubmit={async (values) => {
                    setSubmitClicked(true);

                    const originStationName = stations
                        .filter(
                            (station) =>
                                station.country === values.origin_country &&
                                station.code === values.origin &&
                                station.type === (['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipment_type) ? 'seaport' : 'airport')
                        )
                        .map((station) => (station?.name !== '' ? station?.name + ', ' : '') + station?.location)[0];

                    const destinationStationName = stations
                        .filter(
                            (station) =>
                                station.country === values.destination_country &&
                                station.code === values.destination &&
                                station.type === (['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(values.shipment_type) ? 'seaport' : 'airport')
                        )
                        .map((station) => (station?.name !== '' ? station?.name + ', ' : '') + station?.location)[0];

                    const isContainersShipment = values.sub_type === 'FCL' || values.sub_type === 'CON';
                    const packages = isContainersShipment ? [] : packagesPrepareSubmit(values, shipment?.id);
                    const containers = isContainersShipment ? containersPrepareSubmit(values, shipment?.id) : [];
                    const items =
                        poItems?.length && purchaseOrderId && purchaseOrderCzNumber
                            ? poItemsToShipmentItems(poItems, purchaseOrderId, purchaseOrderCzNumber)
                            : shipment?.items
                            ? duplicateShipmentItems(shipment.items)
                            : undefined;

                    const isTotals = values.isTotals || (!packages?.length && !containers?.length);
                    let references = values.references;
                    if (references.length === 1 && !references[0].value) references = [];
                    const { weight, weightUnit, volume, volumeUnit } = calcWeightVolume(
                        values.shipment_type as shipmentType,
                        packages,
                        undefined,
                        values.isTotals,
                        {
                            weight: values.weight,
                            weightUnit: values.weightUnit,
                            volume: values.volume,
                            volumeUnit: values.volumeUnit
                        }
                    );

                    const quantity = values.quantity;

                    const shipmentObject = {
                        carrierCode: values.carrier,
                        operator: values.operator,
                        vesselName: values.vessel_name,
                        forwarderId: isForwarder ? shipment?.companyId : values?.forwarderId || null,
                        cargoOwnerId: values.cargoOwnerId || null,
                        shipperId: values.shipper_same_as_company ? null : values.shipperId,
                        brokerId: values.BrokerAsForwarder ? values.forwarderId : values.brokerId,
                        companyId: values.companyId,
                        companyName: values.companyName,
                        consigneeId: values.consignee_same_as_company ? null : values.consigneeId,
                        incoterms: values.incoterms,
                        goodsReady: values.goods_ready,
                        pickUp: values.pick_up,
                        voyageNumber: values.voyage_number,
                        eta: values.eta,
                        etd: values.etd,
                        customsFile: values.customs_file,
                        originPickupAddress: values.origin_pickup_address,
                        destinationPickupAddress: values.destination_pickup_address,
                        destinationStation: values.destination,
                        destinationZipCode: values.destination_zipcode,
                        destinationStationName,
                        originStation: values.origin,
                        originZipCode: values.origin_zipcode,
                        originStationName,
                        destinationCountry: values.destination_country,
                        originCountry: values.origin_country,
                        // port_terminal: values.port_terminal,
                        typeCode: values.shipment_type as shipmentType,
                        subTypeCode: values.sub_type && (values.sub_type.trim() as shipmentSubType),
                        commodityType: values.commodity_type,
                        commodity: values.commodity,
                        house: values.house,
                        master: values.master,
                        weight: !!weight ? weight : undefined,
                        weightUnit: !!weightUnit ? weightUnit : undefined,
                        volume: !!volume ? volume : undefined,
                        volumeUnit: !!volumeUnit ? volumeUnit : undefined,
                        isTotals: isTotals,
                        quantity: quantity,
                        packages: packages,
                        containers: containers,
                        references: references,
                        goodsValue: values.goods_value ? Number(values.goods_value) : null,
                        goodsValueCurrency: values.goods_value ? values.goods_value_currency : null,
                        transportationPrice: values.trans_price ? Number(values.trans_price) : null,
                        transportationPriceCurrency: values.trans_price ? values.trans_price_currency : null,
                        buyingRate: values.buying_rate ? Number(values.buying_rate) : null,
                        buyingRateCurrency: values.buying_rate_currency ? values.buying_rate_currency : null,
                        sellingRate: values.selling_rate ? Number(values.selling_rate) : null,
                        sellingRateCurrency: values.selling_rate_currency ? values.selling_rate_currency : null,
                        forwarderUniqueId: values.tracking_number,
                        deliveryNote: values.delivery_note,
                        amazonAppointmentDate: values.amazon_appointment_date,
                        parties: partiesPrepareSubmit(values, setInitialValues(), shipment?.id, businessPartners, allowedCompanies),
                        items,
                        shipperSameAsCompany: values.shipper_same_as_company,
                        consigneeSameAsCompany: values.consignee_same_as_company
                    } as shipment;

                    if ([eShipmentFormMode.NEW, eShipmentFormMode.DUPLICATE].includes(mode)) {
                        await createShipment(shipmentObject).then(async (newShipment: shipment) => {
                            if (newShipment) {
                                await bidHandler(newShipment);
                                setCurrentShipmentId(newShipment.id);
                                setShowGoBackModal(true);
                            }
                        });
                    } else if (shipment) {
                        await updateShipment(shipment.id, shipmentObject).then(async (response) => {
                            await bidHandler(shipment);
                            if (response) history.push(PagesRoutes.Shipments + '/' + currentShipmentId);
                        });
                    }
                    async function bidHandler(shipment: shipment) {
                        if (bidObject) {
                            if (bidObject.bid.id) await updateBid(bidObject.bid.id, bidObject.bid, shipment, bidObject.publish);
                            else
                                await createBid(
                                    shipment,
                                    { ...bidObject.bid, shipmentId: shipment.id, companyId: shipmentObject.companyId },
                                    bidObject.publish
                                );
                        }
                    }
                }}
            >
                {({ values, errors, setFieldValue, isSubmitting, isValidating }) => {
                    const formSections = getFormSections(values, setFieldValue);
                    if (isSubmitting && !isValidating) sectionErrorIndexes = sectionErrors(errors, formSections);
                    return (
                        <PageContainer>
                            {isSubmitting && !isValidating && (
                                <LoaderOverlay>
                                    <Loader></Loader>
                                </LoaderOverlay>
                            )}
                            {showGoBackModal && currentShipmentId && renderGoBackModal()}
                            <Form>
                                <Container>
                                    <FormHeader>
                                        <BackButton
                                            style={{}}
                                            toPath={currentShipmentId ? PagesRoutes.Shipments + '/' + currentShipmentId : PagesRoutes.Shipments}
                                        />
                                        <NewShipmentTitle>
                                            <IdLabel>{localizations.form.new_shipment}</IdLabel>
                                            <ShipmentId>{mode === eShipmentFormMode.NEW ? '#00000000' : shipment?.CargoZoneNumber}</ShipmentId>
                                            <MandaotoryFields>* {localizations.form.mandatory_fields}</MandaotoryFields>
                                        </NewShipmentTitle>
                                    </FormHeader>
                                    {formSections.map(({ name, component }, index) => (
                                        <FormSection
                                            onSectionNumberClick={onSectionNumberClick}
                                            onNextClick={onNextClick}
                                            onPreviousClick={onPreviousClick}
                                            isLast={false}
                                            isActive={sectionErrorIndexes.includes(index + 1) || activeFormSection === index + 1}
                                            name={name}
                                            number={index + 1}
                                            localizations={localizations.form}
                                        >
                                            {component}
                                        </FormSection>
                                    ))}
                                </Container>
                                <Buttons>
                                    <Button buttonType="filled" width="100px" height="30px" type="submit">
                                        {localizations.form.save_btn}
                                    </Button>
                                    <Button width="100px" height="30px" type="button" onClick={onCancelClick}>
                                        {localizations.form.cancel_btn}
                                    </Button>
                                </Buttons>
                            </Form>
                        </PageContainer>
                    );
                }}
            </Formik>
            <RouteLeavingGuard
                title={localizations.form.route_guard}
                when={!submitClicked}
                navigate={(path) => history.push(path)}
                shouldBlockNavigation={(location) => {
                    return true;
                }}
            />
        </>
    );
};

function sectionErrors(errors: any, formSections: formSectionType[]) {
    const sectionsErrors: {
        [key: number]: Array<string>;
    } = formSections.reduce((errorsObj: { [key: number]: Array<string> }, section: formSectionType, index: number) => {
        return {
            ...errorsObj,
            [index + 1]: section.errors
        };
    }, {});

    const indexes = [];
    for (const key of Object.keys(sectionsErrors)) {
        const index = Number(key);
        for (const item of sectionsErrors[index]) {
            if (Object.keys(errors).includes(item)) indexes.push(index);
        }
    }
    return indexes;
}

const Buttons = styled.div`
    width: 100%;
    border-top: 1px solid #e0e0e0;
    margin-top: -15px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 70px;
    display: flex;

    button {
        margin-right: 20px;
    }
`;

const LoaderOverlay = styled.div`
    position: absolute;
    width: inherit;
    z-index: 1001;
    height: 100%;
    background: ${theme.colors.overlayBG};
`;
export const FieldContainer = styled.div`
    margin-bottom: 12px;
`;
const PageContainer = styled.div`
    width: 100%;
`;

const NewShipmentTitle = styled.div`
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    left: 0;
    margin-top: 20px;
`;

const IdLabel = styled.div`
    color: ${(props) => props.theme.colors.pageTitle};
    font-size: 18px;
    font-weight: bold;
`;

const MandaotoryFields = styled.div`
    font-style: italic;
    line-height: 30px;
    font-size: 11px;
    color: red;
`;

const ShipmentId = styled.div`
    color: ${(props) => props.theme.colors.grey};
    margin-left: 5px;
    margin-right: 30px;
    line-height: 30px;
`;

const FormHeader = styled.div`
    flex-direction: row;
    justify-content: space-between;

    & a {
        position: relative;
        left: -30px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 25px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const Flex = styled.div`
    display: flex;
`;
export default SingleShipmentForm;
