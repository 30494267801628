// eslint-disable-next-line import/no-anonymous-default-export
export default {
    en: {
        login: {
            email: 'Email',
            password: 'Password',
            rememberMe: 'Remember me',
            forget_password: 'Forgot password?',
            rights: '© 2020 CargoZone, Inc. All rights reserved.',
            login: 'Login',

            errors: {
                email: 'Email must be valid',
                email_required: 'Email is required',
                pwd_required: 'Password is required'
            },

            forgot_pswd_form: {
                email: 'Email',
                send_btn: 'Send email',
                login: 'Login',
                errors: {
                    email_required: 'Email is required',
                    email_invalid: 'Email must be valid'
                }
            },
            reset_pswd_form: {
                new_pwd: 'New Password',
                confirm_pwd: 'Confirm Password',
                send_btn: 'Change Password',
                login: 'Login',
                welcome_msg: 'Welcome aboard! Please choose your password',
                password_requirments_title: `Password Requirements`,
                password_requirments: `   • be a minimum of eight (8) characters in length
   • contain at least one (1) character from the 
      following categories:
            1. Uppercase letter (A-Z)
            2. Lowercase letter (a-z)
            3. Digit (0-9)
                `,

                errors: {
                    not_match: 'Passwords must match',
                    pwd_invalid: 'Password must be valid',
                    pwd_required: 'Password is required'
                }
            }
        },
        get_started: {
            used_link: 'Someone already used this link',
            invalid_token: 'Token is invalid or has been expired',
            create_new: {
                title: 'First, enter your email',
                sub_title: 'We suggest using the email address you use at work.',
                email_placeholder: 'name@company.com',
                continue_btn: 'Continue',
                ask_account: 'Already have an account?',
                sign_in: 'Sign in',
                email_exist_err: 'This email is already in use',
                email_not_valid: 'Not valid email',
                required: 'Required'
            },
            confirm_email: {
                title: 'Check your email for a code',
                sub_title: (email: string) => `We’ve sent a 6-digit code to ${email}. The code expires shortly, so please enter it soon.`,
                ask_cannot_find: 'Can’t find your code? Check your spam folder!',
                checking_code: 'Checking your code',
                validate_email_err: "That code wasn't valid or has been expired. Give it another go!"
            },
            setup_company: {
                title: 'Set up your company',
                name: 'Company Name',
                type: 'Company Type',
                create_btn: 'Create',
                company_name_err: 'This company name already exists. Please try another.',
                required: 'Required'
            },
            create_user: {
                title: 'Provide your details',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                username: 'Username',
                role: 'Role',
                phone_num: 'Phone number',
                birth_date: 'Birth Date',
                create_btn: 'Done',
                errors: {
                    max_100: 'Max 100 characters',
                    max_50: 'Max 50 characters',
                    max_40: 'Max 40 digits',
                    required: 'Required',
                    invalid_phone_num: 'Invalid phone number',
                    email_invalid: 'Email must be valid',
                    email_exist: 'This email address is already being used'
                }
            },
            registration_success: {
                title: 'Great!',
                sub_title: "We've sent you an email with link for setting your password"
            }
        },
        sidebar: {
            links: {
                dashboard: 'Dashboard',
                shipments: 'Shipments',
                purchaseOrders: 'Purchase Orders',
                purchaseDemands: 'Purchase Demands',
                procurement: 'Procurement',
                shipmentsBoard: 'Shipments Board',
                calendar: 'Calendar',
                companies: 'Business Partners',
                bids: 'Bids',
                messages: 'Messages',
                containers: 'FCL Guard',
                admin: 'Admin',
                users_mgmt: 'Users',
                companies_mgmt: 'Companies',
                partners_relations: 'Partners Relations',
                deleted_records: 'Trash',
                catalog: 'Catalog',
                products: 'Products',
                inventory: 'Inventory',
                orders: 'Orders',
                tariffs: 'Tariff'
            }
        },
        tasksStrip: {
            title: 'Tasks',
            add_task: 'ADD',
            priority: {
                high: 'High',
                medium: 'Medium',
                low: 'Low'
            },
            status: {
                done: 'Done',
                pending: 'Pending',
                new: 'New',
                stuck: 'Stuck',
                onWork: 'On Work',
                archived: 'Archived'
            },
            form: {
                required_error: 'Required',
                max_length_error: 'max 500 characters',
                status_placeholder: 'Status',
                priority_placeholder: 'Priority',
                content_placeholder: 'Content',
                duedate_placeholder: 'Due date',
                reminder_placeholder: 'Reminder'
            },
            modal: {
                submit_btn: 'Create',
                add_title: 'Add Task',
                create_task: 'Create'
            },
            empty_state: {
                title: "You're all caught up! Grab a cup of coffee and enjoy the moment.",
                sub_title: 'Click the add button for new task.'
            }
        },
        followingShipments: {
            following: 'Following',
            clear: 'Clear',
            shipment_type: 'Shipment type',
            invite_to_follow: 'invite you to follow shipment',
            follow: 'Follow',
            shipment_item: {
                shipment: 'Shipment',
                last_event: 'Last Event',
                eta: 'ETA',
                commodity: 'Commodity',
                unfollow: 'Unfollow',
                ref: 'Ref'
            },
            empty_state:
                'No shipments are followed. You can follow any shipment by clicking the follow button in the shipment menu and get notified of any progress or changes made in this shipment.',
            following_tabs: {
                following: 'Following Shipments',
                invitations: 'Invitations'
            }
        },
        followingPurchaseOrders: {
            following: 'Following',
            clear: 'Clear',
            purchase_order_type: 'PurchaseOrder type',
            invite_to_follow: 'invite you to follow PO',
            follow: 'Follow',
            purchase_order_item: {
                purchaseOrder: 'PurchaseOrder',
                last_event: 'Last Event',
                eta: 'ETA',
                commodity: 'Commodity',
                unfollow: 'Unfollow',
                ref: 'Ref'
            },
            empty_state: `No PO's are followed. You can follow any PO's by clicking the follow button in the PO's menu and get notified of any progress or changes made in this PO's.`,
            following_tabs: {
                following: `Following PO's`,
                invitations: 'Invitations'
            }
        },
        containers: {
            header: {
                title: 'FCL Guard (Storage, Demurrage & Detention)',
                text_filter: {
                    placeholder: 'Filter'
                },
                exportOptions: {
                    pdf: 'Pdf',
                    excel: 'Excel',
                    export_btn: 'Export',
                    export_file_name: 'businessPartners',
                    export_excel_modal: {
                        title: 'Export Business Partners Excel',
                        cancel: 'Cancel',
                        export: 'Export',
                        all_columns: 'All columns'
                    }
                }
            },
            stats: {
                totalContainers: 'Total containers',
                totalContainersTooltip: 'Displays active containers with ETA. From booking state and further.',
                returnOverdue: 'Containers with Demurrage or Detention charges',
                returnOverdueTooltip:
                    'Displays the number of containers that already passed the average Free Days at the port of discharge (6 days).',
                storageOverdue: 'Containers with storage charges',
                storageOverdueTooltip:
                    'Displays the number of containers that already passed the average storage free days at the port of discharge (4 days).',
                estimateCosts: 'Cumulative Estimated Costs.',
                estimateCostsTooltip: 'Displays the total estimated charges for Storage, Detention & Demurrage according to the global average.'
            },
            grid: {
                tooltips: {
                    state: 'Indicates the current location & status of the container/s. For top accuracy, it is recommended to update the container state as close as possible to the physical progress of the container.'
                },
                fields: {
                    containerNumber: 'Container#',
                    shipmentCargoZoneNumber: 'Shipment#',
                    containerType: 'Type',
                    lastEvent: 'Last Event',
                    arrivalDate: 'Arrival',
                    storageDays: 'Storage days',
                    returnDays: 'Demurrage or Detention overdue',
                    state: 'State',
                    estimateCosts: 'Est. Costs'
                }
            }
        },
        admin: {
            users_mgmt: {
                add_edit_user: {
                    add_user_form: {
                        title: 'Create User',
                        allowed_companies: 'Allowed Companies',
                        allowed_shipment_types: 'Allowed Shipment Types',
                        allowed_related_companies: 'Allowed Related Companies',
                        success: 'Success',
                        email_sent: 'Email has been sent to',

                        fields: {
                            first_name: 'First Name',
                            last_name: 'Last Name',
                            email: 'Email',
                            username: 'User Name',
                            role: 'Role',
                            phone_num: 'Phone Number',
                            birth_date: 'Birth Date'
                        },
                        permissions: 'Permissions',
                        claims: {
                            shipment: 'Shipment',
                            po: 'Purchase Order',
                            pd: 'Purchase Demand',
                            edit_state: 'Edit State',
                            add_document: 'Add Documents',
                            edit_document: 'Edit Documents',
                            view_document: 'View Documents',
                            delete_document: 'Delete Documents',
                            add_event: 'Add Events',
                            edit_event: 'Edit Events',
                            view_event: 'View Events',
                            delete_event: 'Delete Events',
                            admin: 'Admin',
                            view: 'Access to Admin',
                            add_shipment: 'Add Shipment',
                            duplicate_shipment: 'Duplicate Shipment',
                            view_pd: 'Purchase Demand Access',
                            edit_pd_item: 'Edit Purchase Demand Item',
                            delete_pd_item: 'Delete Purchase Demand Item',
                            edit_pd_item_cost: 'Edit Purchase Demand Item Cost',
                            add_pd_item: 'Add Purchase Demand Item',
                            add_pd: 'Add Purchase Demand',
                            edit_pd: 'Edit Purchase Demand',
                            delete_pd: 'Delete Purchase Demand',
                            add_approver: 'Add Purchase Demand Approver',
                            delete_approver: 'Delete Purchase Demand Approver'
                        },
                        errors: {
                            max_100: 'Max 100 characters',
                            max_50: 'Max 50 characters',
                            max_40: 'Max 40 digits',
                            required: 'Required',
                            invalid_phone_num: 'Invalid phone number',
                            email_invalid: 'Email must be valid',
                            email_exist: 'This email address is already being used'
                        },
                        submit_btn: 'Submit'
                    },
                    edit_user_form: {
                        title: 'Edit User Details',
                        allowed_companies: 'Allowed Companies',
                        allowed_shipment_types: 'Allowed Shipment Types',
                        allowed_related_companies: 'Allowed Related Companies',
                        fields: {
                            email: 'Email',
                            firstName: 'First Name',
                            lastName: 'Last Name',
                            role: 'Role',
                            companyName: 'Company Name',
                            phoneNumber: 'Phone Number',
                            birthDate: 'Birth Date'
                        },
                        permissions: 'Permissions',
                        claims: {
                            shipment: 'Shipment',
                            po: 'Purchase Order',
                            pd: 'Purchase Demand',
                            edit_state: 'Edit State',
                            add_document: 'Add Documents',
                            edit_document: 'Edit Documents',
                            view_document: 'View Documents',
                            delete_document: 'Delete Documents',
                            add_event: 'Add Events',
                            edit_event: 'Edit Events',
                            view_event: 'View Events',
                            delete_event: 'Delete Events',
                            admin: 'Admin',
                            view: 'Access to Admin',
                            add_shipment: 'Add Shipment',
                            duplicate_shipment: 'Duplicate Shipment',
                            view_pd: 'Purchase Demand Access',
                            edit_pd_item: 'Edit Purchase Demand Item',
                            delete_pd_item: 'Delete Purchase Demand Item',
                            edit_pd_item_cost: 'Edit Purchase Demand Item Cost',
                            add_pd_item: 'Add Purchase Demand Item',
                            add_pd: 'Add Purchase Demand',
                            edit_pd: 'Edit Purchase Demand',
                            delete_pd: 'Delete Purchase Demand',
                            add_approver: 'Add Purchase Demand Approver',
                            delete_approver: 'Delete Purchase Demand Approver'
                        },
                        submit_btn: 'Save',
                        reset_pass: 'Reset Password',
                        delete: 'Delete',
                        restore: 'Restore'
                    }
                },
                header: {
                    title: 'Users Management',
                    text_filter: {
                        placeholder: 'Filter'
                    }
                },
                grid: {
                    columns: {
                        birthDate: 'Birth Date',
                        companyName: 'Company Name',
                        createdAt: 'Created At',
                        email: 'Email',
                        firstName: 'Full Name',
                        lastName: 'Last Name',
                        phoneNumber: 'Phone Number',
                        role: 'Role',
                        shouldResetPassword: 'Should reset pass'
                    }
                }
            },
            company: {
                backLink: 'Back to Companies page',
                save_btn: 'Save',
                access_key: 'Access Key',
                create_keys: 'Create Keys',
                company_id: 'Company Id',
                api_keys: 'API Keys',
                documentation: 'Documentation',
                keys_modal: {
                    title: 'Your access key and secret key has been created successfully.',
                    info: 'Copy and save your secret key now. If you do not copy it now, you will not be able to retrieve your secret key again.',
                    secret_key: 'Secret Key',
                    access_key: 'Access Key',
                    company_id: 'Company Id'
                },
                name: 'Name',
                vatNumber: 'Vat Number',
                marketplaces: 'Marketplaces',
                address: 'Address',
                phoneNumber: 'Phone number',
                color: 'Color',
                addresses: {
                    grid: {
                        columns: {
                            name: 'Name',
                            country: 'Country',
                            airPort: 'Air Port',
                            seaPort: 'Sea Port',
                            phoneNumber: 'Phone number',
                            city: 'City',
                            address: 'Address',
                            personInChargeName: 'Person in charge',
                            description: 'Description',
                            isDefault: 'Is Default'
                        },
                        filter: {
                            noResults: 'No results found for your search : (',
                            resetBtn: 'Reset Filter',
                            noData: 'No data'
                        },
                        menu: {
                            edit: 'Edit',
                            delete: 'Delete'
                        }
                    },
                    empty_state: 'No addresses right now!',
                    add_address: 'Add address',
                    modal: {
                        add_title: 'Add company address',
                        edit_title: 'Edit company address',
                        add_btn: 'Create',
                        edit_btn: 'Update'
                    },
                    form: {
                        name: 'Name',
                        country: 'Country',
                        airPort: 'Air Port',
                        seaPort: 'Sea Port',
                        city: 'City',
                        address: 'Address',
                        description: 'Description',
                        phoneNumber: 'Phone number',
                        personInCharge: 'Person in charge',
                        isDefault: 'Is Default',
                        required_error: 'Required',
                        phone_error: 'Does not match phone pattern',
                        city_max_length_error: 'Max 200 characters',
                        address_max_length_error: 'Max 20 characters',
                        description_max_length_error: 'Max 200 characters',
                        name_max_length_error: 'Max 20 characters',
                        phone_number_max_length_error: 'Max 20 characters'
                    }
                }
            },
            companies_mgmt: {
                api_connection: {
                    connection_successful: 'Connection Successful!',
                    connect: 'Connect',
                    connected: 'Connected'
                },
                add_edit_company: {
                    add_company_form: {
                        title: 'Create Company',
                        fields: {
                            name: 'Name',
                            type: 'Type',
                            vat_number: 'Vat Number',
                            phone_num: 'Phone Number',
                            address: 'Address',
                            marketplaces: 'Marketplaces'
                        },
                        errors: {
                            max: 'Max',
                            chars: 'characters',
                            required: 'Required',
                            invalid_phone_num: 'Invalid phone number'
                        },
                        submit_btn: 'Submit'
                    },
                    edit_company_form: {
                        title: 'Edit Company',
                        fields: {
                            email: 'Email'
                        }
                    }
                },
                header: {
                    title: 'Companies Management',
                    text_filter: {
                        placeholder: 'Filter'
                    }
                },
                grid: {
                    columns: {
                        name: 'Name',
                        vatNumber: 'Vat Number',
                        type: 'Type',
                        address: 'Address',
                        phoneNumber: 'Phone Number',
                        createdAt: 'Created At',
                        sellingPartnerId: 'Selling Partner API',
                        adsApiRefreshToken: 'Amazon Advertising API'
                    }
                }
            },
            companies_relations: {
                relations: 'Relations',
                companyName: 'Company Name',
                relatedCompanyName: 'Related Company Name',
                relationType: 'Relation Type',
                filters: {
                    requested: 'Requested',
                    approved: 'Approved',
                    all: 'All'
                },
                relations_empty_state: 'No Relations Found',
                required: 'Required',
                forwarder: 'Forwarder',
                supplier: 'Supplier',
                cargo_owner: 'Cargo Owner',
                send: 'Send',
                requests_empty_state: 'No Requests Found',
                token: 'Token',
                my_token: 'My token',
                new_token: 'New token',
                generate_token: 'Generate token',
                approve: 'Approve',
                waiting_for: 'Waiting for',
                to_approve: 'to approve',
                sent: 'Sent',
                recieved: 'Recieved',
                are_you_sure: 'Are you sure?'
            },
            deleted_records: {
                header: {
                    title: 'Trash',
                    deleted_time_message: 'Deleted entities from the last 30 days',
                    text_filter: {
                        placeholder: 'Filter'
                    }
                },
                grid: {
                    columns: {
                        CargoZoneNumber: 'Number',
                        entity: 'Entity',
                        deletedAt: 'Deleted at',
                        restoreBtn: 'Restore',
                        id: ' '
                    },
                    emptyState: 'No deleted records'
                },
                undo_modal_title: 'Entity has been restored',
                undo_btn: 'Undo',
                goto_btn: 'Go to the page'
            }
        },
        dashboard: {
            title: 'Welcome back',
            sub_title:
                'Thank you for using CargoZone, the digital workspace. Its a pleasure to have you here!! \n This is your CargoZone dashboard. Here you can find data and summaries about your cargo and shipping activity & trends.',
            summary: {
                title: 'Dashboard',
                activeShipments: {
                    title: 'Active Shipments'
                },
                monthArrivals: {
                    title: 'Current Month Arrivals'
                },
                missingDocuments: {
                    title: 'Missing Documents'
                },
                starredShipments: {
                    title: 'Following Shipments'
                },
                charts: {
                    shipmentsByForwarder: 'Shipments by Forwarder',
                    shipmentsByType: 'Shipments by Type',
                    shipmentsByVendor: 'Shipments by Vendor',
                    shipmentsByCargoOwner: 'Shipments by CargoOwner',
                    shipmentsByCarrier: 'Shipments by Carrier',
                    shipmentsByRoute: 'Preferred routes',
                    shipmentsByCommodity: 'Shipments By Commodity',
                    tooltips: {
                        shipmentsByVendor: 'Display (active) shipments by vendor',
                        shipmentsByCarrier: 'Display (active) shipments by carrier'
                    }
                },
                warning_alerts: 'Warning Alerts',
                critical_alerts: 'Critical Alerts',
                avg_trans_cost: 'Transportation Cost Precent Avg.',
                avg_shipment_profit: 'Shipment Profit Avg.',
                avg_transit_time: 'Avg Transit Time',
                inbound_spend: 'Open Inbound Spend',
                toolTips: {
                    warning_alerts: 'Attention / action required',
                    critical_alerts: 'Significant risk / potential disruption detected',
                    avg_trans_cost: 'Show the extra costs (%) added on goods value (active shipments only)',
                    avg_transit_time: 'Acoording to shipments that have ETD/ATD & ETA/ATA',
                    inbound_spend: `Display the inbound total amount of all transportation + goods costs (Active PO's, shipments, overheads, and related transportation costs)`
                }
            },
            orderOrientedSummary: {
                activeOrders: {
                    title: 'Active Orders'
                },
                exceptions: {
                    title: 'Exceptions'
                },
                warnings: {
                    title: 'Warnings'
                },
                activeOrdersValue: {
                    title: 'Active Orders Value'
                },
                charts: {
                    ordersAmountByMonth: 'Orders By Month',
                    exceptionsByMonth: 'Exceptions By Month',
                    ordersByCarrier: 'Orders By Carrier',
                    ordersByState: 'Orders By State',
                    ordersByCountry: 'Orders By Country'
                }
            },
            my_rfq: {
                title: 'My RFQ',
                tool_tip: 'My Request for Quote',
                grid: {
                    columns: {
                        companyName: 'Company Name',
                        expirationDate: 'Expiration Date',
                        includeInsurance: 'Include Insurance',
                        shipmentTypes: 'Shipment Types',
                        note: 'Note'
                    },
                    filtersForm: {
                        purchaseDemand_type: 'PD Type',
                        from_date: 'From Date',
                        to_date: 'To Date'
                    },
                    emptyState: 'No RFQ yet'
                }
            },
            sent_quotations: {
                title: 'Sent Quotations',
                tool_tip: 'Sent Quotations',
                grid: {
                    columns: {
                        shipmentType: 'Shipment Type',
                        freightPrice: 'Freight Price',
                        validTill: 'Valid Till',
                        note: 'Note',
                        state: 'State'
                    },
                    emptyState: 'No Sent Quotations yet'
                }
            },
            purchase_demands: {
                title: 'Purchase Demands',
                tool_tip: 'Purchase Demands',
                grid: {
                    columns: {
                        CargoZoneNumber: 'PD Number',
                        createdAt: 'Open Date',
                        state: 'State',
                        companyName: 'Company',
                        userName: 'User',
                        quantity: 'Number of items',
                        approvers: 'Approvers'
                    },
                    filtersForm: {
                        purchaseDemand_type: 'PD Type',
                        from_date: 'From Date',
                        to_date: 'To Date'
                    },
                    menu: {
                        delete: 'Delete',
                        export: 'Export',
                        pdf: 'Pdf',
                        excel: 'Excel',
                        confirmMessage: 'Are you sure?'
                    },
                    purchaseDemandWarning: {
                        critical: 'Critical',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No PD yet, to create your first PD, you should click on the new PD button up right.'
                }
            },
            ready_soon_po: {
                title: 'Ready soon PO’s',
                tool_tip: 'Ready soon PO’s',
                grid: {
                    columns: {
                        containerNumber: 'Container Number',
                        containerType: 'Container Type',
                        arrivalDate: 'Arrival Date',
                        daysFromArrival: 'Days From Arrival',
                        state: 'State',
                        id: '-',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        incoterms: 'Incoterms'
                    },
                    filtersForm: {
                        purchaseOrder_type: 'PO Type',
                        incoterms: 'Incoterms',
                        forworder: 'Forwarder',
                        from_date: 'From Date',
                        to_date: 'To Date',
                        origin_country: 'Origin Country',
                        origin_station: 'Origin Station',
                        destination_country: 'Destination Country',
                        destination_station: 'Destination Station'
                    },
                    menu: {
                        openRelatedTalk: 'Open related talk',
                        share: 'Share',
                        delete: 'Delete',
                        follow: 'Follow',
                        unfollow: 'Unfollow',
                        invite_to_follow: 'Invite to follow',
                        share_shipment: 'Share Shipment',
                        make_shipment: 'Make shipment',
                        export: 'Export',
                        pdf: 'Pdf',
                        excel: 'Excel',
                        confirmMessage: 'Are you sure?'
                    },
                    purchaseOrderWarning: {
                        critical: 'Critical',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No POs right now!'
                }
            },
            upcoming_arrivals: {
                title: 'Upcoming Arrivals',
                tool_tip: 'Display shipments that arrived at destination within 5 days back + 10 days forward from today',
                grid: {
                    columns: {
                        CargoZoneNumber: 'Shipment Number',
                        alertStatus: '',
                        alertStatus_tooltip: `The risk indicator will be yellow (!) when your attention or action is needed to promote the shipment's progress.
                        Red (X), when a significant risk or potential disruption was detected. Green (V) means all good so far.`,
                        typeCode: 'Type',
                        createdAt: 'Open Date',
                        createdAt_tooltip: 'Displays the date and time the shipment was created ',
                        companyName: 'Company',
                        forwarderName: 'Forwarder',
                        clientRef: 'Client Ref',
                        clientRef_tooltip: `Cargo Owner's reference number`,
                        forwarderRef: 'Forwarder Ref',
                        incoterms: 'Incoterms',
                        originStation: 'Origin',
                        originStation_tooltip: 'Port of Loading',
                        destinationStation: 'Destination',
                        destinationStation_tooltip: 'Port of Discharge',
                        shipperName: 'Shipper',
                        consigneeName: 'Consignee',
                        userName: 'Created by',
                        quantity: 'Quantity',
                        quantity_tooltip: 'Number of total packing units in the shipment',
                        weight: 'Weight',
                        carrierCode: 'Carrier',
                        carrierCode_tooltip: 'The physical/Actual transporter service provider (airline,shipping line, trucking company etc..)',
                        containerNumber: 'Container#',
                        eta: 'ETA',
                        commodity: 'Commodity',
                        state: 'State',
                        state_tooltip: 'Displays current state of the shipment (Generally)',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        updatedAt: 'Last Update',
                        updatedAt_tooltip: 'Displays the date & time of the last update was received'
                    },
                    menu: {
                        openRelatedTalk: 'Open related talk',
                        share: 'Share',
                        delete: 'Delete',
                        follow: 'Follow',
                        unfollow: 'Unfollow',
                        invite_to_follow: 'Invite to follow',
                        share_shipment: 'Share Shipment',
                        make_shipment: 'Make shipment',
                        export: 'Export',
                        pdf: 'Pdf',
                        excel: 'Excel',
                        confirmMessage: 'Are you sure?'
                    },
                    shipmentWarning: {
                        error: 'Error',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    alert_status: {
                        critical: 'Critical',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No shipments right now!'
                }
            },
            order_shipments: {
                title: 'Shipments in order',
                tool_tip: 'Display shipments that have order state',
                grid: {
                    columns: {
                        CargoZoneNumber: 'Shipment Number',
                        alertStatus: '',
                        alertStatus_tooltip: `The risk indicator will be yellow (!) when your attention or action is needed to promote the shipment's progress.
                        Red (X), when a significant risk or potential disruption was detected. Green (V) means all good so far.`,
                        typeCode: 'Type',
                        createdAt: 'Open Date',
                        createdAt_tooltip: 'Displays the date and time the shipment was created ',
                        companyName: 'Company',
                        forwarderName: 'Forwarder',
                        clientRef: 'Client Ref',
                        clientRef_tooltip: `Cargo Owner's reference number`,
                        forwarderRef: 'Forwarder Ref',
                        incoterms: 'Incoterms',
                        originStation: 'Origin',
                        originStation_tooltip: 'Port of Loading',
                        destinationStation: 'Destination',
                        destinationStation_tooltip: 'Port of Discharge',
                        shipperName: 'Shipper',
                        consigneeName: 'Consignee',
                        userName: 'Created by',
                        quantity: 'Quantity',
                        quantity_tooltip: 'Number of total packing units in the shipment',
                        weight: 'Weight',
                        carrierCode: 'Carrier',
                        carrierCode_tooltip: 'The physical/Actual transporter service provider (airline,shipping line, trucking company etc..)',
                        containerNumber: 'Container#',
                        eta: 'ETA',
                        commodity: 'Commodity',
                        state: 'State',
                        state_tooltip: 'Displays current state of the shipment (Generally)',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        updatedAt: 'Last Update',
                        updatedAt_tooltip: 'Displays the date & time of the last update was received'
                    },
                    menu: {
                        openRelatedTalk: 'Open related talk',
                        share: 'Share',
                        delete: 'Delete',
                        follow: 'Follow',
                        unfollow: 'Unfollow',
                        invite_to_follow: 'Invite to follow',
                        share_shipment: 'Share Shipment',
                        make_shipment: 'Make shipment',
                        export: 'Export',
                        pdf: 'Pdf',
                        excel: 'Excel',
                        confirmMessage: 'Are you sure?'
                    },
                    shipmentWarning: {
                        error: 'Error',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    alert_status: {
                        critical: 'Critical',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No shipments right now!'
                }
            },
            fcl_containers: {
                title: 'Fcl Containers',
                tool_tip: 'Display Fcl containers',
                grid: {
                    columns: {
                        containerNumber: 'Container Number',
                        containerType: 'Container Type',
                        arrivalDate: 'Arrival Date',
                        daysFromArrival: 'Days From Arrival',
                        state: 'State',
                        id: '-',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        incoterms: 'Incoterms'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No containers right now!'
                }
            },
            ready_soon_shipments: {
                title: 'Ready soon shipments',
                tool_tip: '',
                grid: {
                    columns: {
                        CargoZoneNumber: 'Shipment Number',
                        alertStatus: '',
                        alertStatus_tooltip: `The risk indicator will be yellow (!) when your attention or action is needed to promote the shipment's progress.
                        Red (X), when a significant risk or potential disruption was detected. Green (V) means all good so far.`,
                        typeCode: 'Type',
                        createdAt: 'Open Date',
                        createdAt_tooltip: 'Displays the date and time the shipment was created ',
                        companyName: 'Company',
                        forwarderName: 'Forwarder',
                        clientRef: 'Client Ref',
                        clientRef_tooltip: `Cargo Owner's reference number`,
                        forwarderRef: 'Forwarder Ref',
                        incoterms: 'Incoterms',
                        originStation: 'Origin',
                        originStation_tooltip: 'Port of Loading',
                        destinationStation: 'Destination',
                        destinationStation_tooltip: 'Port of Discharge',
                        shipperName: 'Shipper',
                        consigneeName: 'Consignee',
                        userName: 'Created by',
                        quantity: 'Quantity',
                        quantity_tooltip: 'Number of total packing units in the shipment',
                        weight: 'Weight',
                        carrierCode: 'Carrier',
                        carrierCode_tooltip: 'The physical/Actual transporter service provider (airline,shipping line, trucking company etc..)',
                        containerNumber: 'Container#',
                        eta: 'ETA',
                        commodity: 'Commodity',
                        state: 'State',
                        state_tooltip: 'Displays current state of the shipment (Generally)',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        updatedAt: 'Last Update',
                        updatedAt_tooltip: 'Displays the date & time of the last update was received'
                    },
                    menu: {
                        openRelatedTalk: 'Open related talk',
                        share: 'Share',
                        delete: 'Delete',
                        follow: 'Follow',
                        unfollow: 'Unfollow',
                        invite_to_follow: 'Invite to follow',
                        share_shipment: 'Share Shipment',
                        make_shipment: 'Make shipment',
                        export: 'Export',
                        pdf: 'Pdf',
                        excel: 'Excel',
                        confirmMessage: 'Are you sure?'
                    },
                    shipmentWarning: {
                        error: 'Error',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    alert_status: {
                        critical: 'Critical',
                        warning: 'Warning',
                        success: 'All good'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    emptyState: 'No shipments right now!'
                }
            }
        },
        shipments: {
            title: 'All Shipments',
            filterPlaceHolder: 'Filter shipments',
            new_shipment: 'New Shipment',
            refresh_btn: 'Refresh',
            export_btn: 'Export',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel',
                export_file_name: 'shipments',
                export_excel_modal: {
                    title: 'Export Shipments Excel',
                    cancel: 'Cancel',
                    export: 'Export',
                    all_columns: 'All columns'
                }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'Shipment Number',
                    alertStatus: ' ',
                    carrierCode: 'Carrier',
                    clientRef: 'Client Ref',
                    commodity: 'Commodity',
                    companyName: 'Company',
                    consigneeName: 'Consignee',
                    containerNumber: 'Container#',
                    createdAt: 'Open Date',
                    destinationStation: 'Destination',
                    destinationCountry: 'Destination Country',
                    eta: 'ETA',
                    etd: 'ETD',
                    ata: 'ATA',
                    atd: 'ATD',
                    forwarderName: 'Forwarder',
                    forwarderRef: 'Forwarder Ref',
                    goodsReady: 'Goods Ready',
                    incoterms: 'Incoterms',
                    lastEvent: 'Last event',
                    originStation: 'Origin',
                    originCountry: 'Origin Country',
                    quantity: 'Quantity',
                    shipperName: 'Shipper',
                    state: 'State',
                    transportationCost: 'Trans. cost',
                    transportationPaid: 'Forwarder Inv. Paid',
                    typeCode: 'Type',
                    updatedAt: 'Last Update',
                    userName: 'Created by',
                    weight: 'Weight',
                    shipment_number: 'Shipment Number',
                    type: 'Type',
                    open_date: 'Open Date',
                    client_ref: 'Client Ref',
                    forwarder_ref: 'Forwarder Ref',
                    forwarder: 'Forwarder',
                    origin: 'Origin',
                    destination: 'Destination',
                    shipper: 'Shipper',
                    consignee: 'Consignee',
                    master_bl: 'Master',
                    house_bl: 'House',
                    container_no: 'Container Number',
                    carrier: 'Carrier',
                    customs_file: 'Custom File',
                    last_event: 'Last Event'
                },
                filtersForm: {
                    shipment_type: 'Shipment Type',
                    incoterms: 'Incoterms',
                    forworder: 'Forwarder',
                    from_date: 'From Date',
                    to_date: 'To Date',
                    origin_country: 'Origin Country',
                    origin_station: 'Origin Station',
                    destination_country: 'Destination Country',
                    destination_station: 'Destination Station'
                },
                menu: {
                    openRelatedTalk: 'Open related talk',
                    share: 'Share',
                    delete: 'Delete',
                    follow: 'Follow',
                    unfollow: 'Unfollow',
                    invite_to_follow: 'Invite to follow',
                    share_shipment: 'Share Shipment',
                    make_shipment: 'Make shipment',
                    export: 'Export',
                    pdf: 'PDF',
                    excel: 'Excel',
                    confirmMessage: 'Are you sure?'
                },
                shipmentWarning: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                },
                alert_status: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No shipments yet, to create your first shipment, you should click on the new shipment button up right.'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                back_modal: {
                    shipments_page: 'Shipments Page',
                    shipment_details: 'Shipment Details'
                },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                parties: {
                    modal: {
                        create: 'Create',
                        FORWARDER: 'Forwarder',
                        BROKER: 'Broker',
                        SHIPPER: 'Shipper',
                        CONSIGNEE: 'Consignee'
                    }
                },
                sections: {
                    general: {
                        fields: {
                            company: {
                                title: 'Company',
                                placeholder: 'Pick company'
                            },
                            shipment_type: {
                                title: 'Shipment Type',
                                tooltip: 'Choose the mode of transportation required.',
                                placeholder: 'Pick shipment type'
                            },
                            forwarder: {
                                title: 'Forwarder',
                                tooltip: 'Forwarder field',
                                placeholder: 'Pick forwarder'
                            },
                            cargo_owner: {
                                title: 'Cargo Owner',
                                tooltip: 'Cargo owner field',
                                placeholder: 'Pick cargo owner'
                            },
                            broker: {
                                title: 'Broker',
                                tooltip: 'Broker Field',
                                placeholder: 'Pick broker'
                            },
                            shipper: {
                                title: 'Shipper',
                                tooltip: 'The exporter mentioned as the shipper on the BL/AWB, Invoice & Packing List',
                                placeholder: 'Pick shipper'
                            },
                            consignee: {
                                title: 'Consignee',
                                tooltip: 'The receiver of the goods',
                                placeholder: 'Pick consignee'
                            },
                            tracking_number: {
                                title: 'Tracking number',
                                tooltip: 'Tracking number field',
                                placeholder: 'Enter tracking number'
                            },
                            estimate_delivery: {
                                title: 'Estimate delivery',
                                tooltip: 'Estimate delivery field',
                                placeholder: 'Estimate delivery'
                            },
                            incoterms: {
                                title: 'Incoterms',
                                tooltip: 'The agreed trade terms as mentioned in the shipment invoice',
                                placeholder: 'Pick type'
                            },
                            commodity: {
                                title: 'Commodity',
                                tooltip: 'Goods Type Description'
                            },
                            sub_type: {
                                placeholder: 'Mode'
                            }
                        },
                        save_broker_same_as_forwarder: 'Broker same as forwarder',
                        shipper_same_as_company: 'I am the shipper',
                        consignee_same_as_company: 'I am the consignee',
                        taxes_duties_at_destination_included: 'Taxes & Duties at Destination Included?',
                        yes: 'Yes',
                        no: 'No'
                    },
                    geography: {
                        fields: {
                            address: {
                                title: 'Pick address',
                                placeholder: 'Pick address'
                            },
                            origin_country: {
                                title: 'Origin Country',
                                tooltip: 'The name of the country shipment is export from',
                                placeholder: 'Pick country'
                            },
                            origin_port: {
                                title: 'Origin Port',
                                tooltip: 'Shipment gateway/Port of loading',
                                placeholder: 'Pick port'
                            },
                            origin_zipcode: {
                                title: 'Origin City \\ Zipcode',
                                placeholder: 'Pick zipcode'
                            },
                            pick_up_address: {
                                title: 'Pick-Up Address',
                                placeholder: 'Origin address'
                            },
                            consignee_addresses: {
                                title: 'Pick address',
                                placeholder: 'Pick address'
                            },
                            destination_country: {
                                title: 'Destination Country',
                                tooltip: 'The name of the country to which the goods are sent to',
                                placeholder: 'Pick country'
                            },
                            destination_port: {
                                title: 'Destination Port',
                                tooltip: 'Port of Discharge',
                                placeholder: 'Pick port'
                            },
                            destination_zipcode: {
                                title: 'Destination City \\ Zipcode',
                                placeholder: 'Pick zipcode'
                            },
                            delivery_address: {
                                title: 'Delivery Address',
                                placeholder: 'Destination address'
                            }
                        }
                    },
                    billing: {
                        fields: {
                            goods_ready: {
                                title: 'Goods ready',
                                tooltip: 'The date of goods expected to be ready to ship'
                            },
                            master: {
                                title: 'Master',
                                tooltip:
                                    'Master Bill of Lading issued by main carrier of goods on receipt of goods from a freight forwarder to deliver at destination as per agreed terms'
                            },
                            awb: {
                                title: 'AWB'
                            },
                            carrier: {
                                title: 'Carrier',
                                tooltip:
                                    'The company entitled to transport the shipment/ The physical/Actual transporter service provider (airline, shipping line, rail ,trucking company etc..).',
                                placeholder: 'Pick carrier'
                            },
                            etd: {
                                title: 'ETD',
                                tooltip: 'Estimate Time of Departure'
                            },
                            pick_up: {
                                title: 'Pick Up Date',
                                tooltip: 'This is the initial Pick up date\nIt might change if the shipment will not be picked up or wasn’t ready.'
                            },
                            house: {
                                title: 'house',
                                tooltip:
                                    'House Bill of Lading issued by a freight forwarder on receipt of goods from shipper agreeing to deliver goods at destination'
                            },
                            delivery_note: {
                                title: 'Delivery Note'
                            },
                            vessel_name: {
                                title: 'Vessel name',
                                tooltip: 'Final Voyage \\ flight number'
                            },
                            eta: {
                                title: 'ETA',
                                tooltip: 'Estimated Time of Arrival'
                            },
                            customs_file: {
                                title: 'Customs File',
                                tooltip: 'The import customs file number/indication'
                            },
                            operator: {
                                title: 'Operator',
                                tooltip:
                                    'OPERATOR is the business partner that handling the shipment. It can be the forwarder/broker/shipping line or airline.',
                                placeholder: 'Pick operator'
                            },
                            voyage_number: {
                                title: 'Voyage Number',
                                tooltip: 'Final Voyage \\ flight number',
                                placeholder: 'Enter Voyage Number'
                            },
                            goods_value: {
                                title: 'Goods Value'
                            },
                            trans_price: {
                                title: 'Transportation Price'
                            },
                            buying_rate: {
                                title: 'Buying Rate'
                            },
                            selling_rate: {
                                title: 'Selling Rate'
                            }
                        },
                        enter_name: 'Enter name'
                    },
                    amazon: {
                        fields: {
                            amazon_id: {
                                title: 'Amazon Id'
                            },
                            fba_id: {
                                title: 'FBA Id'
                            },
                            amazon_appointment_date: {
                                title: 'Amazon Appointment Date'
                            }
                        }
                    },
                    packages: {
                        fields: {
                            container_type: {
                                title: 'Container Type',
                                tooltip: 'Choose the container size & type necessary.'
                            },
                            container_number: {
                                title: 'Container Number',
                                tooltip:
                                    'The unique alpha-numeric sequence of four letters and seven numbers to identify containers internationally. As mentioned in  the BL & displayed on the top right part of the container door.'
                            },
                            description: {
                                title: 'Description'
                            },
                            overweight: {
                                title: 'Overweight'
                            },
                            dimensions: {
                                title: 'Dimensions'
                            },
                            quantity: {
                                title: 'Quantity'
                            },
                            weight: {
                                title: 'Weight'
                            },
                            weight_unit: {
                                KG: 'KG',
                                LB: 'LB'
                            },
                            total_volume: {
                                title: 'Total Volume'
                            },
                            total_weight: {
                                title: 'Total Weight'
                            },
                            volume_unit: {
                                CBM: 'CBM',
                                CFT: 'CFT'
                            },
                            dimensions_unit: {
                                CM: 'CM',
                                IN: 'IN'
                            },
                            hs_code: {
                                title: 'HS Code'
                            }
                        },
                        add_container: 'Add container',
                        add_package: 'Add package'
                    },
                    references: {
                        fields: {
                            reference_no: {
                                title: 'Reference No.',
                                tooltip: 'This can be your Purchase order number/name or nickname'
                            }
                        },
                        add_ref_no: 'Add Ref. No.'
                    }
                },
                route_guard: 'Close without saving?',
                next_btn: 'Next',
                previous_btn: 'Previous',
                where_do_you_want_to_go: 'Where do you want to go?',
                save_btn: 'Save',
                cancel_btn: 'Cancel',
                new_shipment: 'New Shipment:',
                mandatory_fields: 'Mandatory Fields'
            },
            filter: {
                active_shipments: 'Active Shipments',
                active: 'Active',
                inactive: 'Inactive',
                state: 'State',
                company: 'Company',
                pick_company: 'Pick Company',
                alert_status: 'Alert Status',
                all_good: 'All Good',
                warning: 'Warning',
                critical: 'Critical',
                shipment_type: 'Shipment Type',
                incoterms: 'Incoterms',
                forwarder: 'Forwarder',
                open_date: 'Open Date',
                from_date: 'From Date',
                to_date: 'To Date',
                eta_from_date: 'ETA From Date',
                eta_to_date: 'ETA To Date',
                origin_country: 'Origin Country',
                origin_station: 'Origin Station',
                destination_country: 'Destination Country',
                destination_station: 'Destination Station',
                vendor: 'Supplier',
                carrier: 'Carrier',
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload shipments'
            },
            date_from: 'Date from: ',
            date_to: 'Date to: ',
            eta_from: 'Eta from: ',
            eta_to: 'Eta to: ',
            origin_country: 'Origin Country: ',
            destination_country: 'Destination Country: ',
            origin_station: 'Origin Station: ',
            destination_station: 'Destination Station: ',
            critical: 'Critical',
            warning: 'Warning',
            all_good: 'All Good',
            carrier: 'Carrier: ',
            vendor: 'Supplier: ',
            active: 'Active',
            inactive: 'Inactive',
            all: 'All'
        },
        categories: {
            form: {
                name: 'Name',
                company: 'Company',
                errors: {
                    required: 'Required',
                    max_length: (length: number) => `Max ${length} characters`
                }
            },
            modal: {
                add_btn: 'Add Category',
                edit_btn: 'Edit Category'
            }
        },
        products: {
            title: 'All Products',
            filterPlaceHolder: 'Filter products',
            new_product: 'New Product',
            refresh_btn: 'Refresh',
            edit_btn: 'Edit',
            duplicate_btn: 'Duplicate',
            active: 'Active',
            inactive: 'Inactive',
            add_product: 'Add Product',
            grid: {
                columns: {
                    number: 'Number',
                    name: 'Name',
                    sku: 'SKU',
                    description: 'Description',
                    supplierName: 'Supplier',
                    latestProductImgUrl: 'Image',
                    madeIn: 'Made in',
                    marketplace: 'Marketplace',
                    category: 'Category',
                    brand: 'Brand',
                    cost: 'Cost',
                    retailPrice: 'Retail Price',
                    salePrice: 'Sale Price',
                    weight: 'Weight',
                    width: 'Width',
                    length: 'Length',
                    height: 'Height',
                    dimensionUnit: 'Dimension',
                    note: 'Note',
                    companyName: 'Company Name'
                },
                filtersForm: {},
                menu: {
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No products yet, to create your first product, you should click on the new product button up right.',
                relatedEmptyState: 'No related products'
            },
            filter: {
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter',
                active_products: 'Active Products',
                brand: 'Brand',
                madeIn: 'Made in',
                supplier: 'Supplier',
                category: 'Category',
                company: 'Company',
                active: 'Active',
                inactive: 'Inactive'
            },
            form: {
                cancel_btn: 'Cancel',
                save_btn: 'Save',
                required_field_message: 'Required',
                max_length_message: (length: number) => `Max ${length} characters`,
                note: 'Note',
                company: 'Company',
                name: 'Name',
                sku: 'SKU',
                description: 'Description',
                supplier: 'Supplier',
                category: 'Category',
                brand: 'Brand',
                cost: 'Cost',
                retailPrice: 'Retail Price',
                salePrice: 'Sale Price',
                weight: 'Weight',
                height: 'H',
                width: 'W',
                length: 'L',
                dimension: 'Dimension',
                masterCartonWeight: 'Master Carton Weight',
                madeIn: 'Made in',
                marketplace: 'Marketplace',
                masterCartonDimension: 'Master Carton Dimension',
                unitsPerMasterCarton: 'Units per carton',
                isActive: 'Is Active',
                productionDays: 'LT Production',
                CM: 'CM',
                IN: 'IN',
                user: 'User',
                asin: 'ASIN',
                fnsku: 'FNSKU',
                rank: 'Rank',
                numberOfRatings: 'Number of ratings',
                fbaFee: 'FBA Fee',
                amazonPercentFee: 'Amazon refferal fee %',
                amazon_title: 'Amazon',
                before_duplicate_msg: 'Before saving duplicate product',
                include_documents: 'Include documents?',
                include_attributes: 'Include attributes?',
                hs_codes: 'HS Codes',
                minOrderQuantity: 'Minimum Order Quantity',
                minOrderQuantityAlert: 'Minimum Order Quantity Alert',
                code: 'Code',
                hs_code: 'HS Code',
                add_hscode: 'Add HS Code'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload products'
            },
            history: {
                item_dictionary: {
                    CREATE_PRODUCT: 'create new product',
                    UPDATE_PRODUCT: 'update product',
                    DELETE_PRODUCT: 'delete product',
                    CREATE_DOCUMENT: 'create new document',
                    UPDATE_DOCUMENT: 'update document',
                    DELETE_DOCUMENT: 'delete document',
                    CREATE_ATTRIBUTE: 'create new attribute',
                    UPDATE_ATTRIBUTE: 'update attribute',
                    DELETE_ATTRIBUTE: 'delete attribute',
                    CREATE_ATTRIBUTE_OPTION: 'create new attribute option',
                    UPDATE_ATTRIBUTE_OPTION: 'update attribute option',
                    DELETE_ATTRIBUTE_OPTION: 'delete attribute option',
                    CREATE_RELATED_PRODUCT: 'create new relation',
                    DELETE_RELATED_PRODUCT: 'stop relation',
                    CREATE_LINK: 'create new link',
                    UPDATE_LINK: 'update link',
                    DELETE_LINK: 'delete link',
                    CREATE_INVENTORY_LOG: 'create new inventory log'
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add product document',
                    edit_title: 'Edit product document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            attributes: {
                grid: {
                    columns: {
                        name: 'Name',
                        options: 'Options'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No attributes right now!',
                add_attribute: 'Add attribute',
                modal: {
                    add_title: 'Add product attribute',
                    edit_title: 'Edit product attribute',
                    add_btn: 'Create',
                    edit_btn: 'Update'
                },
                form: {
                    name: 'Name',
                    name_max_length_error: 'Max 100 characters',
                    required_error: 'Required'
                },
                attributeOptions: {
                    add_options: 'Add options',
                    option: 'option',
                    options: 'options',
                    grid: {
                        columns: {
                            name: 'Name',
                            skuAdjustment: 'SKU',
                            costAdjustment: 'Cost',
                            priceAdjustment: 'Price',
                            weightAdjustment: 'Weight',
                            dimensionAdjustmentUnit: 'Dimension',
                            displayOrder: 'Display order',
                            isDefault: 'Is Default'
                        },
                        filter: {
                            noResults: 'No results found for your search : (',
                            resetBtn: 'Reset Filter',
                            noData: 'No data'
                        },
                        menu: {
                            edit: 'Edit',
                            delete: 'Delete'
                        }
                    },
                    empty_state: 'No options right now!',
                    add_attribute: 'Add option',
                    modal: {
                        title: 'Attribute options',
                        add_title: 'Add attribute option',
                        edit_title: 'Edit attribute option',
                        add_btn: 'Create',
                        edit_btn: 'Update'
                    },
                    form: {
                        name: 'Name',
                        sku: 'SKU Adjustment',
                        cost: 'Cost Adjustment',
                        price: 'Price Adjustment',
                        weight: 'Weight Adjustment',
                        height: 'H',
                        width: 'W',
                        length: 'L',
                        dimension: 'Dimension Adjustment',
                        displayOrder: 'Display order',
                        isDefault: 'Is Default',
                        CM: 'CM',
                        IN: 'IN',
                        KG: 'KG',
                        LB: 'LB',
                        required_field_message: 'Required',
                        max_length_message: (length: number) => `Max ${length} characters`
                    }
                }
            },
            links: {
                grid: {
                    columns: {
                        title: 'Title',
                        url: 'Url'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No links right now!',
                add_link: 'Add link',
                modal: {
                    add_title: 'Add product link',
                    edit_title: 'Edit product link',
                    add_btn: 'Create',
                    edit_btn: 'Update'
                },
                form: {
                    title: 'Title',
                    title_max_length_error: 'Max 20 characters',
                    url: 'Url',
                    url_max_length_error: 'Max 250 characters',
                    required_error: 'Required'
                }
            },
            related_products: {
                add_btn: 'Add relation'
            },
            inventory: {
                totals_grid: {
                    header: 'Totals by warehouse',
                    columns: {
                        warehouseName: 'Warehouse Name',
                        onHand: 'On hand',
                        reserved: 'Reserved',
                        inbound: 'Inbound',
                        outbound: 'Outbound',
                        available: 'Available',
                        productImgUrl: 'Image',
                        productFnsku: 'FNSKU',
                        productAsin: 'Asin'
                    },
                    emptyState: 'No Totals'
                },
                logs_grid: {
                    header: 'Inventory logs',
                    columns: {
                        cost: 'Cost',
                        actionType: 'Action type',
                        quantity: 'Quantity',
                        location: 'Location',
                        reason: 'Reason',
                        createdAt: 'Created date'
                    },
                    emptyState: 'No Logs'
                }
            }
        },
        inventory: {
            new_inventory: 'New Inventory',
            title: 'All Inventory',
            refresh_btn: 'Refresh',
            filterPlaceHolder: 'Filter inventory',
            edit_btn: 'Edit',
            duplicate_btn: 'Duplicate',
            active: 'Active',
            inactive: 'Inactive',
            add_inventory: 'Add Inventory',
            grid: {
                columns: {
                    productName: 'Name',
                    sku: 'SKU',
                    status: 'Status',
                    reserved: 'Reserved',
                    onHand: 'On hand',
                    inbound: 'Inbound',
                    outbound: 'Outbound',
                    available: 'Available',
                    cost: 'Cost',
                    updatedAt: 'Last update',
                    productImgUrl: 'Image',
                    productFnsku: 'FNSKU',
                    productAsin: 'Asin'
                },
                filtersForm: {},
                menu: {
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No inventory yet, to create your first inventory, you should click on the new inventory button up right.'
            },
            filter: {},
            form: {
                cancel_btn: 'Cancel',
                save_btn: 'Save',
                required_field_message: 'Required',
                max_length_message: (length: number) => `Max ${length} characters`,
                name: 'Name',
                sku: 'SKU',
                product: 'Product',
                actionType: 'Action',
                cost: 'Cost',
                quantity: 'Quantity',
                warehouse: 'Warehouse',
                location: 'Location',
                reason: 'Reason'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload inventory'
            }
        },
        internalFlowInventory: {
            title: 'All Inventory',
            refresh_btn: 'Refresh',
            filterPlaceHolder: 'Filter inventory',
            grid: {
                columns: {
                    name: 'Name',
                    asin: 'ASIN',
                    sku: 'SKU',
                    fnsku: 'FNSKU',
                    reserved: 'Reserved',
                    inbound: 'Inbound',
                    outbound: 'Outbound',
                    pdReservedTotal: 'PD',
                    poInboundTotal: 'PO',
                    shipmentInboundTotal: 'Shipment',
                    productImgUrl: 'Image',
                    onHand: 'On Hand',
                    amazonInboundTotal: 'Inbound',
                    internalFlow: 'Internal Flow',
                    amazon: 'Amazon',
                    manual: 'Manual',
                    amazonOnHandTotal: 'On Hand',
                    amazonReservedTotal: 'Resrved',
                    manualOnHand: 'On Hand',
                    manualInbound: 'Inbound',
                    manualReserved: 'Resrved'
                },
                filtersForm: {},
                menu: {
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No internal flow inventory yet.'
            }
        },
        shipment: {
            backLink: 'Back to shipments page',
            lastUpdate: 'Last update:',
            edit: 'Edit',
            duplicate: 'Duplicate',
            SendToSAP: 'Send to SAP',
            newBid: 'New Bid',
            shipment: 'Shipment',
            client_ref: 'Client Ref:',
            related_shipments: 'Related Shipments:',
            forwarder_ref: 'Forwarder Ref:',
            last_event: 'Last Event:',
            eta: 'ETA',
            tracking_number: 'Tracking number',
            estimate_delivery: 'Esitmate delivery',
            master: 'Master',
            awb: 'AWB',
            house: 'House',
            delivery_note: 'Delivery Note',
            etd: 'ETD',
            atd: 'ATD',
            ata: 'ATA',
            commodity: 'Commodity',
            customs_file: 'Customs File',
            operator: 'Operator',
            amazon_id: 'Amazon Id',
            fba_id: 'FBA Id',
            amazon_appointment_date: 'Amazon Appointment Date',
            goods_ready: 'Goods Ready',
            pick_up: 'Pick up',
            forwarder: 'Forwarder',
            broker: 'Broker',
            company: 'Company',
            voyage_number: 'Voyage Number',
            vessel: 'Vessel Name',
            commodity_type: 'Commodity Type',
            container_no: 'Container Number',
            containers: 'Containers',
            packages_field: 'Packages',
            is_overweight: 'Is Overweight',
            carrier: 'Carrier',
            port_terminal: 'Port terminal',
            incoterms: 'Incoterms',
            quantity: 'Quantity',
            see_more: 'See more >',
            volume: 'Volume',
            weight: 'Weight',
            chargeable_weight: 'Chargeable Weight',
            goods_value: 'Goods Value',
            trans_price: 'Transportation Price',
            buying_rate: 'Buying Rate',
            selling_rate: 'Selling Rate',
            visit_site: 'Visit site',
            save_btn: 'Save',
            enter_name: 'Enter name',
            pick_type: 'Pick type',
            pick_carrier: 'Pick carrier',
            total_volume: 'Total Volume',
            total_weight: 'Total Weight',
            transportation_cost_percent: 'Transportation cost percent',
            transportation_paid: 'Forwarder Inv. Paid',
            total_profit: 'Total profit',
            estimated_items_volume: 'Estimated Items Volume',
            estimated_items_weight: 'Estimated Items Weight',
            total_cartons: 'Total Cartons',
            are_you_sure: 'Are you sure?',
            dismiss: 'Dismiss',
            shipment_state: 'Shipment State',
            you_are_moving_the_shipment_state_to: 'You are moving the shipment state to',
            inactive: 'Inactive',
            deleted: 'Deleted',
            now: 'Now',
            next: 'Next',
            not_available: 'N/A',
            forwarder_modal: {
                title: 'Oops, you need to choose forwarder first',
                forwarder_placeholder: 'Pick forwarder',
                set_btn: 'Set'
            },
            paid_mail_modal: {
                dialog_text: 'Do you want to let the forwarder know the shipment has been paid?',
                title: 'Who to send?',
                contacts_placeholder: 'Pick contacts',
                send_btn: 'Send'
            },
            overview: {
                tooltips: {
                    transportationPrice:
                        'For top accuracy, type a value that includes international and local transportation, insurance, storage costs, customs release Fees, and taxes (Excluding VAT)',
                    transportationPrecent: 'Represent the total % of extra cost apply on goods cost',
                    is_overweight:
                        'Heavy containers normally pays additional fee of 100-400USD for the ocean freight and also extra charge for inland transportation'
                }
            },
            alert_status: {
                critical: 'Critical',
                warning: 'Warning',
                success: 'All good'
            },
            items: {
                po_ref: 'PO Ref',
                transportation_cost_per_unit: 'Transportation Cost Per Unit',
                quantity: 'Quantity',
                sku: 'SKU',
                code: 'Code',
                name: 'Name',
                product_name: 'Name',
                price: 'Cost',
                currency: 'Currency',
                dimensions: 'Dimensions',
                weight: 'Weight',
                total_cost: 'Total Cost',
                attributes: 'Attributes',
                ready_date: 'Ready Date',
                pd_ref: 'PD Ref',
                cartons: 'Cartons',
                shipments_refs: 'Shipments Refs',
                images: 'Images',
                remaining_quantity: 'Remaining Quantity',
                qc_approved: 'Qc Approved',
                shipments: 'Shipments',
                amazon_reference_id: 'Amazon Reference Id',
                amazon_shipment_id: 'Amazon Shipment Id'
            },
            parties: {
                empty_state: 'No parties right now!',
                add_party: 'Add',
                modal: {
                    add_title: 'Add shipment party',
                    edit_title: 'Edit shipment party',
                    add_btn: 'Add',
                    edit_btn: 'Update',
                    cancel_btn: 'Cancel'
                },
                form: {
                    partycompany_placeholder: 'Choose company',
                    partytype_placeholder: 'Choose party type',
                    partysubtype_placeholder: 'Choose party sub type',
                    companyaddresses_placeholder: 'Choose address',
                    companycontacts_placeholder: 'Choose contact',
                    partyname_placeholder: 'Name',
                    partycountry_placeholder: 'Country',
                    partycity_placeholder: 'City',
                    partyaddress_placeholder: 'Address',
                    partyphone_placeholder: 'Phone number',
                    partyemail_placeholder: 'Email',
                    remarks_placeholder: 'Remarks',
                    partycontact_name_placeholder: 'Contact name',
                    partyvat_number_placeholder: 'Vat number',
                    partywebsite_placeholder: 'Website',
                    payment_terms: 'Payment terms',
                    payment_terms_remarks: 'Payment terms remarks',
                    taxes_duties: 'Taxes & Duties',
                    remarks_alert: 'Alert when open new shipment?',
                    monthly_intrest: 'Monthly intrest',
                    credit_line: 'Credit line',
                    credit_line_currency: 'Credit line currency',
                    required_error: 'Required',
                    phone_error: 'Does not match phone pattern',
                    email_error: 'Does not match email pattern',
                    create_company_party: 'Create Business Partner',
                    address_max_length_error: 'Max 250 characters',
                    phone_max_length_error: 'Max 40 digits',
                    email_max_length_error: 'Max 100 characters',
                    website_max_length_error: 'Max 50 characters',
                    payment_terms_max_length_error: 'Max 25 characters',
                    payment_terms_remarks_max_length_error: 'Max 150 characters',
                    vat_number_max_length_error: 'Max 20 characters',
                    vat_number_error: 'This vat number already exists',
                    name_max_length_error: 'Max 100 characters',
                    updatestates_placeholder: 'State Update Notification',
                    salesman_placeholder: 'Salesman'
                },
                menu: {
                    edit: 'Edit',
                    delete: 'Delete'
                }
            },
            billing_lines: {
                empty_state: 'No billing lines right now!',
                add_billingline: 'Add Billing Line',
                modal: {
                    add_title: 'Add billing line',
                    edit_title: 'Edit billing line',
                    add_btn: 'Add',
                    edit_btn: 'Update',
                    cancel_btn: 'Cancel'
                },
                form: {
                    billinglineservicetype: 'Service Type',
                    billinglineservicetype_placeholder: 'Choose service type',
                    billinglinecurrency_placeholder: 'Choose currency',
                    billinglinecurrency: 'Currency',
                    billinglineincome_placeholder: 'Total income',
                    billinglineincome: 'Income',
                    billinglineexpense_placeholder: 'Total expense',
                    billinglineexpense: 'Expense',
                    billinglinetotalcost_placeholder: 'Total',
                    billinglinetotalcost: 'Total cost',
                    billinglinerelativecost_placeholder: '%',
                    billinglinerelativecost: 'Relative cost',
                    billinglinedate_placeholder: 'Billing date',
                    billinglinedate: 'Date',
                    billinglineremarks_placeholder: 'Remarks',
                    billinglineremarks: 'Remarks',
                    required_error: 'Required',
                    relative_cost_error: 'Maximum 100%',
                    remarks_max_length_error: 'Max 1000 characters'
                },
                menu: {
                    edit: 'Edit',
                    delete: 'Delete'
                },
                columns: {
                    serviceType: 'Service Type',
                    type: 'Type',
                    totalCost: 'Total Cost',
                    relativeCost: 'Relative Cost',
                    remarks: 'Remarks',
                    createdAt: 'Created Date'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                }
            },
            events: {
                grid: {
                    columns: {
                        id: 'Id',
                        eventCode: 'Code',
                        name: 'Event',
                        description: 'Description',
                        userName: 'Insert By',
                        eventDate: 'Event Date',
                        createdAt: 'Created Date',
                        comment: 'Comment',
                        displayOnlyForCompanyId: ' ',
                        displayOnlyForCompanyIdTooltip: 'This event is visible only to your company'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No events right now!',
                add_event: 'Add event',
                updated_by: 'updated by',
                modal: {
                    add_title: 'Add shipment event',
                    edit_title: 'Edit shipment event',
                    add_btn: 'Add event',
                    edit_btn: 'Update event',
                    cancel_btn: 'Cancel'
                },
                form: {
                    eventtype: 'Event type',
                    eventtype_placeholder: 'Choose event type',
                    comment: 'Comment',
                    isConfidential: 'Confidential',
                    sendByMail: 'Send Comment By Email?',
                    comments_placeholder: 'Add a comment here...',
                    required_error: 'Required',
                    comment_max_length_error: 'Max 1000 characters'
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment',
                        displayOnlyForCompanyId: ' ',
                        displayOnlyForCompanyIdTooltip: 'This document is visible only to your company',
                        expirationDate: 'Expiration Date'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add shipment document',
                    edit_title: 'Edit shipment document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            quotations: {
                grid: {
                    columns: {
                        forwarderName: 'Forwarder name',
                        freightPrice: 'Total Freight Price (USD)',
                        transitTime: 'Transit time',
                        freeDays: 'Free days',
                        validTill: 'Valid till',
                        note: 'note'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                pane: {
                    no_quotation: 'No quotation',
                    total_price: 'Total price',
                    transit_time: 'Transit time',
                    valid_untill: 'Valid untill',
                    status: 'Status',
                    attached_file: 'Attached File',
                    inform_not_approved: 'Do you want to send email to forwarders that their quote did not approve?',
                    no_file: 'No file',
                    note: 'Note',
                    confirm_btn: 'Confirm this offer'
                },
                empty_state: 'No quotations right now!'
            },
            bid: {
                empty_state: 'No bid were found',
                create_btn: 'Create Bid',
                forwarder_exist: 'Already have forwarder , cannot create bid',
                state_is_not_draft: 'Cannot create bid when shipment state is not Draft',
                edit_btn: 'Edit',
                publish_btn: 'Publish',
                view_btn: 'View',
                draft: 'Draft',
                bid: 'Bid',
                selected_offer: 'Selected offer',
                quotations: {
                    grid: {
                        columns: {
                            forwarder: 'Forwarder',
                            shipment: 'Shipment',
                            transit_time: 'Transit time',
                            total_price: 'Total price',
                            valid_till: 'Valid till',
                            price_per_unit: 'Price Per Volume Unit',
                            status: 'Status',
                            notes: 'Document'
                        },
                        filter: {
                            noResults: 'No results found for your search : (',
                            resetBtn: 'Reset Filter',
                            noData: 'No data'
                        }
                    },
                    empty_state: 'No quotations right now!'
                },
                form: {
                    back_modal_title: 'Where do you want to go?',
                    forwarder_is_required: 'Forwarder is required',
                    contact_is_required: 'Contact is required',
                    shipment_types: 'Shipment Types',
                    pick_types: 'Pick types',
                    note: 'Note',
                    save_as_draft: 'Save as draft',
                    save: 'Save',
                    publish: 'Publish',
                    expirationdate: 'Expiration date',
                    includeInsurance: 'Include Insurance',
                    required_field_message: 'Required',
                    documents: {
                        upload_documents: 'Upload Documents',
                        add_documents: 'Add documents'
                    },
                    parties: {
                        choose_forwarders: 'Choose Forwarders',
                        pick_forwarder: 'Pick forwarder',
                        add: 'Add',
                        pick_contact: 'Pick Contact',
                        add_contact: 'Add Contact',
                        send_email_again: 'Send email again',
                        add_forwarder: 'Add Forwarder',
                        name: 'Name',
                        email: 'Email',
                        cancel: 'Cancel'
                    }
                }
            },
            packages: {
                modal_title: 'Packages',
                grid: {
                    columns: {
                        type: 'Type',
                        quantity: 'Quantity',
                        description: 'Description',
                        containerNumber: 'Container number',
                        containerType: 'Container type',
                        isOverweight: 'Overweight',
                        dimensions: 'Dimensions',
                        weight: 'Weight',
                        volume: 'Volume',
                        hsCode: 'HS Code'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                empty_state: 'No packages right now!'
            },
            routes: {
                modal_title: 'Routes',
                grid: {
                    columns: {
                        origin_station: 'Origin',
                        destination_station: 'Destination',
                        departure: 'Departure',
                        arrival: 'Arrival',
                        flight_number: 'Flight number'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                empty_state: 'No routes right now!'
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            },
            history: {
                item_dictionary: {
                    CREATE_SHIPMENT: 'create new shipment',
                    CREATE_FOLLOWERS: 'start follow shipment',
                    CREATE_DOCUMENTS: 'create new document',
                    CREATE_EVENTS: 'create new event',
                    CREATE_EVENT: 'create new event',
                    CREATE_PARTIES: 'create new party',
                    CREATE_PARTY: 'create new party',
                    CREATE_PACKAGE: 'create new package',
                    CREATE_CONTAINER: 'create new container',
                    CREATE_REFERENCES: 'create new reference',
                    CREATE_REFERENCE: 'create new reference',
                    'CREATE_UPLOAD-MULTIPLE': 'upload files',
                    CREATE_SHIPMENTS: 'create new shipments',
                    CREATE_DISCUSSIONS: 'create new discussion',
                    CREATE_UPLOAD: 'create new upload',
                    CREATE_BID: 'create new bid',
                    CREATE_QUOTATION: 'create new quotation',
                    CREATE_ITEM: 'create new item',

                    UPDATE_SHIPMENT: 'update shipment',
                    UPDATE_EVENTS: 'update event',
                    UPDATE_EVENT: 'update event',
                    UPDATE_DOCUMENTS: 'update document',
                    UPDATE_PARTIES: 'update party',
                    UPDATE_PARTY: 'update party',
                    'UPDATE_UPLOAD-MULTIPLE': 'upload files',
                    UPDATE_PACKAGE: 'update package',
                    UPDATE_CONTAINER: 'update container',
                    UPDATE_SHIPMENTS: 'update shipment',
                    UPDATE_DISCUSSIONS: 'update shipment',
                    UPDATE_REFERENCE: 'update reference',
                    UPDATE_UPLOAD: 'update shipment',
                    UPDATE_STATE: 'update shipment state',
                    UPDATE_BID: 'update shipment bid',
                    UPDATE_ITEM: 'update item',

                    DELETE_SHIPMENT: 'delete shipment',
                    DELETE_FOLLOWERS: 'unfollow shipment',
                    DELETE_EVENTS: 'delete event',
                    DELETE_DOCUMENTS: 'delete document',
                    DELETE_ALERT: 'has dismissed alert',
                    DELETE_PARTIES: 'delete party',
                    'DELETE_UPLOAD-MULTIPLE': 'delete files',
                    DELETE_SHIPMENTS: 'delete shipments',
                    DELETE_DISCUSSIONS: 'delete discussion',
                    DELETE_UPLOAD: 'delete upload'
                }
            },
            references: {
                modal: {
                    add: 'Add',
                    edit: 'Edit'
                },
                types: {
                    CLIENT: 'Client Ref',
                    FORWARDER: 'Forwarder Ref'
                },
                form: {
                    maxLength: (length: number) => `Max ${length} characters`,
                    value: 'Value',
                    cancel_btn: 'Cancel',
                    delete_btn: 'Delete',
                    save_btn: 'Save'
                }
            },
            exceptions: {
                '401': {
                    title: 'Access denied',
                    sub_title: 'Sorry! You have no permission to see this shipment details.'
                },
                back_btn: 'Go to shipments page'
            },
            pane: {
                shipmentDetails: 'Shipment Details',
                ref: 'Ref',
                clientRef: 'Client Ref',
                forwarderRef: 'Forwarder Ref',
                trackingNumber: 'Tracking number',
                commodity: 'Commodity',
                lastEvent: 'Last event',
                nextEvent: 'Next event planned',
                currentState: 'Current state',
                nextState: 'Next state',
                etd: 'ETD',
                etdTooltip: 'Estimated time of departure from origin port',
                eta: 'ETA',
                etaTooltip: 'Estimated time of arrival to destination port',
                chargeableWeight: 'Chargeable Weight',
                incoterms: 'Incoterms',
                quantity: 'Quantity',
                carrier: 'Carrier',
                master: 'Master',
                masterTooltip: 'Master bill of landing',
                house: 'House',
                houseTooltip: 'House bill of landing',
                packages: {
                    modal_title: 'Packages',
                    see_more: 'see more >',
                    grid: {
                        columns: {
                            type: 'Type',
                            quantity: 'Quantity',
                            description: 'Description',
                            containerNumber: 'Container number',
                            containerType: 'Container type',
                            isOverweight: 'Overweight',
                            dimensions: 'Dimensions',
                            weight: 'Weight',
                            volume: 'Volume',
                            hsCode: 'HS Code'
                        },
                        filter: {
                            noResults: 'No results found for your search : (',
                            resetBtn: 'Reset Filter',
                            noData: 'No data'
                        }
                    },
                    empty_state: 'No packages right now!'
                },
                parties: {
                    title: 'Parties'
                }
            },
            board: {
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                board_page: {
                    you_are_moving_message: 'You are moving the shipment state to',
                    you_are_moving_to_bid_with_forwarder:
                        'You are moving to bid and you have forwarder , we will reset the forwarder and let you create new bid!',
                    are_you_sure: 'Are you sure?'
                }
            }
        },
        order_confirmation: {
            ask_confirm_title: 'Do you want to confirm the order?',
            confirmed_title: 'You have confirmed this order',
            rejected_title: 'You have rejected this order',
            confirm: 'Confirm',
            reject: 'Reject'
        },
        orders: {
            title: 'Orders',
            addOrder: 'Add Order',
            emptyState: 'No Orders Yet',
            exceptions: {
                '401': {
                    title: 'Access denied',
                    sub_title: 'Sorry! You have no permission to see this order details.'
                },
                back_btn: 'Go to orders page'
            },
            filtersForm: {
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter'
            },
            overview: {
                order: 'Order',
                orderNumber: 'Order Number',
                company: 'Company',
                saveBtn: 'Save',
                parties: {
                    CUSTOMER: 'Ship To',
                    PICKUP: 'Ship From',
                    PAYER: 'Bill To'
                }
            },
            alerts: {
                status: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                }
            },
            state: {
                NEW: 'New',
                READY_TO_SHIP: 'Ready To Ship',
                EXCEPTION: 'Exception',
                EXCEPTION_HANDLING: 'Exception Handling',
                PICKED_UP: 'Picked Up',
                IN_TRANSIT: 'In Transit',
                DELIVERED: 'Delivered',
                CANCELED: 'Canceled'
            },
            fields: {
                id: {
                    title: 'Id',
                    tooltip: '',
                    placeholder: 'Id'
                },
                orderNumber: {
                    title: 'Order Number',
                    tooltip: '',
                    placeholder: 'Order Number'
                },
                orderDateFrom: {
                    title: 'Order Date From',
                    tooltip: '',
                    placeholder: 'Order Date From'
                },
                orderDateTo: {
                    title: 'Order Date To',
                    tooltip: '',
                    placeholder: 'Order Date To'
                },
                alertStatus: {
                    title: 'Alert Status',
                    tooltip: '',
                    placeholder: 'Alert Status'
                },
                state: {
                    title: 'State',
                    tooltip: '',
                    placeholder: 'State'
                },
                userId: {
                    title: 'User',
                    tooltip: '',
                    placeholder: 'User'
                },
                userName: {
                    title: 'User',
                    tooltip: '',
                    placeholder: 'User'
                },
                companyId: {
                    title: 'Company',
                    tooltip: '',
                    placeholder: 'Company'
                },
                customerId: {
                    title: 'Customer',
                    tooltip: '',
                    placeholder: 'Customer'
                },
                pickupId: {
                    title: 'Pick Up From',
                    tooltip: '',
                    placeholder: 'Pick Up From'
                },
                payerId: {
                    title: 'Bill To',
                    tooltip: '',
                    placeholder: 'Bill To'
                },
                payerSameAsCustomer: {
                    title: 'Same As Customer',
                    tooltip: '',
                    placeholder: 'Same As Customer'
                },
                customerCountry: {
                    title: 'Customer Country',
                    tooltip: '',
                    placeholder: 'Customer Country'
                },
                requiredCustomsClearance: {
                    title: 'Required Customs Clearance',
                    tooltip: '',
                    placeholder: 'Required Customs Clearance'
                },
                companyName: {
                    title: 'Company',
                    tooltip: '',
                    placeholder: 'Company'
                },
                totalItems: {
                    title: 'Total Items',
                    tooltip: '',
                    placeholder: 'Total Items'
                },
                productsName: {
                    title: 'Products',
                    tooltip: '',
                    placeholder: 'Products'
                },
                orderDate: {
                    title: 'Order Date',
                    tooltip: '',
                    placeholder: 'Order Date'
                },
                dueDate: {
                    title: 'Due Date',
                    tooltip: '',
                    placeholder: 'Due Date'
                },
                poDate: {
                    title: 'Po Date',
                    tooltip: '',
                    placeholder: 'Po Date'
                },
                poNumber: {
                    title: 'Po Number',
                    tooltip: '',
                    placeholder: 'Po Number'
                },
                soNumber: {
                    title: 'Order Number',
                    tooltip: '',
                    placeholder: 'Order Number'
                },
                eta: {
                    title: 'ETA',
                    tooltip: '',
                    placeholder: 'ETA'
                },
                lastEvent: {
                    title: 'Last Event',
                    tooltip: '',
                    placeholder: 'Last Event'
                },
                invoiceNumber: {
                    title: 'Invoice Number',
                    tooltip: '',
                    placeholder: 'Invoice Number'
                },
                incoterms: {
                    title: 'Incoterms',
                    tooltip: '',
                    placeholder: 'Incoterms'
                },
                trackingNumber: {
                    title: 'Tracking Number',
                    tooltip: '',
                    placeholder: 'Tracking Number'
                },
                carrier: {
                    title: 'Carrier',
                    tooltip: '',
                    placeholder: 'Carrier'
                },
                weight: {
                    title: 'Weight',
                    tooltip: '',
                    placeholder: 'Weight'
                },
                weightUnit: {
                    title: 'Weight Unit',
                    tooltip: '',
                    placeholder: 'Weight Unit'
                },
                volume: {
                    title: 'Volume',
                    tooltip: '',
                    placeholder: 'Volume'
                },
                volumeUnit: {
                    title: 'Volume Unit',
                    tooltip: '',
                    placeholder: 'Volume Unit'
                },
                estimatedItemsVolume: {
                    title: 'Estimated Items Volume',
                    tooltip: '',
                    placeholder: 'Estimated Items Volume'
                },
                estimatedItemsWeight: {
                    title: 'Estimated Items Weight',
                    tooltip: '',
                    placeholder: 'Estimated Items Weight'
                },
                itemsTotalQuantity: {
                    title: 'Total Items',
                    tooltip: '',
                    placeholder: 'Total Items'
                },
                itemsTotalCost: {
                    title: 'Total Price',
                    tooltip: '',
                    placeholder: 'Total Price'
                },
                paymentTerms: {
                    title: 'Payment Terms',
                    tooltip: '',
                    placeholder: 'Payment Terms'
                },
                shippingInstructions: {
                    title: 'Shipping Instructions',
                    tooltip: '',
                    placeholder: 'Shipping Instructions'
                },
                createdAt: {
                    title: 'Created At',
                    tooltip: '',
                    placeholder: 'Created At'
                },
                updatedAt: {
                    title: 'Last Update',
                    tooltip: '',
                    placeholder: 'Last Update'
                },
                deletedAt: {
                    title: 'Deleted At',
                    tooltip: '',
                    placeholder: 'Deleted At'
                },
                party: {
                    contactId: {
                        title: 'Contact',
                        tooltip: '',
                        placeholder: 'Contact'
                    },
                    name: {
                        title: 'Name',
                        tooltip: '',
                        placeholder: 'Name'
                    },
                    vatNumber: {
                        title: 'Vat Number',
                        tooltip: '',
                        placeholder: 'Vat Number'
                    },
                    address: {
                        title: 'Address',
                        tooltip: '',
                        placeholder: 'Address'
                    },
                    contactName: {
                        title: 'Contact Name',
                        tooltip: '',
                        placeholder: 'Contact Name'
                    },
                    phoneNumber: {
                        title: 'Phone Number',
                        tooltip: '',
                        placeholder: 'Phone Number'
                    },
                    email: {
                        title: 'Email',
                        tooltip: '',
                        placeholder: 'Email'
                    },
                    country: {
                        title: 'Country',
                        tooltip: '',
                        placeholder: 'Country'
                    },
                    city: {
                        title: 'City',
                        tooltip: '',
                        placeholder: 'City'
                    }
                },
                item: {
                    orderId: {
                        title: 'Order Id',
                        tooltip: '',
                        placeholder: 'Order Id'
                    },
                    productId: {
                        title: 'Product Id',
                        tooltip: '',
                        placeholder: 'Product Id'
                    },
                    name: {
                        title: 'Name',
                        tooltip: '',
                        placeholder: 'Name'
                    },
                    productSku: {
                        title: 'SKU',
                        tooltip: '',
                        placeholder: 'SKU'
                    },
                    partNumber: {
                        title: 'Part Number',
                        tooltip: '',
                        placeholder: 'Part Number'
                    },
                    serialNumber: {
                        title: 'Serial Number',
                        tooltip: '',
                        placeholder: 'Serial Number'
                    },
                    quantity: {
                        title: 'Quantity',
                        tooltip: '',
                        placeholder: 'Quantity'
                    },
                    length: {
                        title: 'Length',
                        tooltip: '',
                        placeholder: 'Length'
                    },
                    height: {
                        title: 'Height',
                        tooltip: '',
                        placeholder: 'Height'
                    },
                    width: {
                        title: 'Width',
                        tooltip: '',
                        placeholder: 'Width'
                    },
                    dimensionsUnit: {
                        title: 'Dimensions Unit',
                        tooltip: '',
                        placeholder: 'Dimensions Unit'
                    },
                    weight: {
                        title: 'Weight',
                        tooltip: '',
                        placeholder: 'Weight'
                    },
                    weightUnit: {
                        title: 'Weight Unit',
                        tooltip: '',
                        placeholder: 'Weight Unit'
                    },
                    price: {
                        title: 'Price',
                        tooltip: '',
                        placeholder: 'Price'
                    },
                    currency: {
                        title: 'Currency',
                        tooltip: '',
                        placeholder: 'Currency'
                    },
                    attributes: {
                        title: 'Attributes',
                        tooltip: '',
                        placeholder: 'Attributes'
                    },
                    hsCodes: {
                        title: 'Hs Codes',
                        tooltip: '',
                        placeholder: 'Hs Codes'
                    }
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add product document',
                    edit_title: 'Edit product document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            events: {
                empty_state: 'No events right now!',
                add_event: 'Add event',
                updated_by: 'updated by',
                modal: {
                    add_title: 'Add order event',
                    edit_title: 'Edit order event',
                    add_btn: 'Add event',
                    edit_btn: 'Update event',
                    cancel_btn: 'Cancel'
                },
                form: {
                    eventtype: 'Event type',
                    eventtype_placeholder: 'Choose event type',
                    comment: 'Comment',
                    comments_placeholder: 'Add a comment here...',
                    required_error: 'Required',
                    comment_max_length_error: 'Max 255 characters'
                }
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            },
            history: {
                item_dictionary: {
                    CREATE_ORDER: 'Create Order',
                    UPDATE_ORDER: 'Update Order',
                    DELETE_ORDER: 'Delete Order',

                    CREATE_ITEM: 'Create Item',
                    UPDATE_ITEM: 'Update Item',
                    DELETE_ITEM: 'Delete Item',

                    CREATE_PARTY: 'Create Party',
                    UPDATE_PARTY: 'Update Party',
                    DELETE_PARTY: 'Delete Party',

                    CREATE_EVENT: 'Create Event',
                    UPDATE_EVENT: 'Update Event',
                    DELETE_EVENT: 'Delete Event',

                    CREATE_DOCUMENT: 'Create Document',
                    UPDATE_DOCUMENT: 'Update Document',
                    DELETE_DOCUMENT: 'Delete Document'
                }
            }
        },
        tariffs: {
            newTariff: 'New Tariff',
            title: 'Tariffs'
        },
        tariff: {
            saveBtn: 'Save',
            overview: {
                tariff: 'Tariff'
            },
            form: {
                errors: {
                    required: 'Required'
                },
                fields: {
                    companyId: {
                        title: 'Company',
                        tooltip: '',
                        placeholder: 'Company'
                    },
                    businessPartnerId: {
                        title: 'Customer',
                        tooltip: '',
                        placeholder: 'Customer'
                    },
                    shipmentTypeCode: {
                        title: 'Shipment Type',
                        tooltip: '',
                        placeholder: 'Shipment Type'
                    },
                    shipmentSubTypeCode: {
                        title: 'Shipment Sub Type',
                        tooltip: '',
                        placeholder: 'Shipment Sub Type'
                    },
                    incoterms: {
                        title: 'Incoterms',
                        tooltip: '',
                        placeholder: 'Incoterms'
                    },
                    carrier: {
                        title: 'Carrier',
                        tooltip: '',
                        placeholder: 'Carrier'
                    },
                    transitTime: {
                        title: 'Transit Time',
                        tooltip: '',
                        placeholder: 'Transit Time'
                    },
                    originCountry: {
                        title: 'Origin Country',
                        tooltip: '',
                        placeholder: 'Origin Country'
                    },
                    originStation: {
                        title: 'Origin Station',
                        tooltip: '',
                        placeholder: 'Origin Station'
                    },
                    destinationCountry: {
                        title: 'Destination Country',
                        tooltip: '',
                        placeholder: 'Destination Country'
                    },
                    destinationStation: {
                        title: 'Destination Station',
                        tooltip: '',
                        placeholder: 'Destination Station'
                    },
                    forwarderTariffNumber: {
                        title: 'Forwarder Tariff Number',
                        tooltip: '',
                        placeholder: 'Forwarder Tariff Number'
                    },
                    note: {
                        title: 'Note',
                        tooltip: '',
                        placeholder: 'Note'
                    },
                    activationDate: {
                        title: 'Activation Date',
                        tooltip: '',
                        placeholder: 'Activation Date'
                    },
                    expirationDate: {
                        title: 'Expiration Date',
                        tooltip: '',
                        placeholder: 'Expiration Date'
                    },
                    pol: {
                        title: 'Port Of Load',
                        tooltip: '',
                        placeholder: 'Port Of Load'
                    },
                    pod: {
                        title: 'Port Of Destination',
                        tooltip: '',
                        placeholder: 'Port Of Destination'
                    }
                }
            },
            charges: {
                addCharge: 'Add Charge',
                fields: {
                    chargeCode: {
                        title: 'Charge Code',
                        tooltip: '',
                        placeholder: 'Charge Code'
                    },
                    description: {
                        title: 'Description',
                        tooltip: '',
                        placeholder: 'Description'
                    },
                    chargeType: {
                        title: 'Charge Type',
                        tooltip: '',
                        placeholder: 'Charge Type'
                    },
                    minimum: {
                        title: 'Minimum',
                        tooltip: '',
                        placeholder: 'Minimum'
                    },
                    unitType: {
                        title: 'Unit Type',
                        tooltip: '',
                        placeholder: 'Unit Type'
                    },
                    calcType: {
                        title: 'Calc Type',
                        tooltip: '',
                        placeholder: 'Calc Type'
                    },
                    chargeCalcData: {
                        title: 'Charge Calc Data',
                        tooltip: '',
                        placeholder: 'Charge Calc Data'
                    },
                    price: {
                        title: 'Price',
                        tooltip: '',
                        placeholder: 'Price'
                    },
                    value: {
                        title: 'Value',
                        tooltip: '',
                        placeholder: 'Value'
                    },
                    percent: {
                        title: 'Percent',
                        tooltip: '',
                        placeholder: 'Percent'
                    },
                    currency: {
                        title: 'Currency',
                        tooltip: '',
                        placeholder: 'Currency'
                    },
                    createdAt: {
                        title: 'Created At',
                        tooltip: '',
                        placeholder: 'Created At'
                    }
                }
            }
        },
        share_shipment_modal: {
            errors: {
                email: 'is not a valid email',
                required: 'Required'
            },
            placeholders: {
                emails: 'Share to...',
                content: 'Add your message here…'
            },
            files: 'Files',
            share: 'Share'
        },
        inviteToFollowModal: {
            errors: {
                required: 'Required',
                maxLengthMessage: (chars: string) => `Max ${chars} characters`
            },
            placeholders: {
                users: 'Invite users...',
                message: 'Add your message here…'
            },
            invite: 'Invite'
        },
        calendar: {
            title: 'Calendar Page'
        },
        user: {
            user_menu: {
                edit_profile: 'Edit Profile',
                STATUS: 'Status',
                SIGN_OUT: 'Sign out',
                USER_SETTINGS: 'Settings',
                statuses: {
                    AVAILABLE: 'Available',
                    BUSY: 'Busy',
                    DO_NOT_DISTURB: 'Do not disturb',
                    OOTO: 'Out of the office'
                }
            },
            edit_profile: {
                upload_img: 'Upload',
                details_form: {
                    username: 'User Name',
                    first_name: 'First Name',
                    last_name: 'Last Name',
                    phone_number: 'Phone Number',
                    role: 'Role',
                    submit_btn: 'Save'
                },
                password_form: {
                    current_pswd: 'Current Password',
                    new_pswd: 'New Password',
                    confirm_pswd: 'Confirm Password',
                    submit_btn: 'Update Password'
                },
                errors: {
                    empty_field: 'This field cannot be empty',
                    pswd_match: 'Passwords are not matching',
                    pswd_invalid: 'Invalid password',
                    you_can_only_upload_jpg_png_file: 'You can only upload JPG/PNG file!',
                    image_must_smaller_than_2_mb: 'Image must smaller than 2MB!'
                },
                notification: {
                    details_has_been_updated: 'Details has been updated',
                    password_has_been_updated: 'Password has been updated'
                }
            },
            user_settings: {
                title: 'My Settings',
                create_btn: 'Create',
                update_btn: 'Update',
                key: 'Key',
                value: 'Value'
            }
        },
        notifications: {
            header: {
                text: 'Notifications',
                markAllRead: 'Mark all as read',
                markAllUnread: 'Mark all as unread'
            },
            item: {
                unfollow: 'Unfollow',
                follow: 'Follow',
                tooltipRead: 'Mark as read',
                tooltipUnread: 'Mark as unread',
                tooltipRemove: 'Remove'
            },
            empty_state: {
                title: 'No notifications yet',
                sub_title: 'Stay tuned! Notifications about your activity will show up here.',
                settings_ref: 'Notification settings'
            },
            menu: {
                see_all: 'See All'
            },
            push: {
                default_title: 'Default title',
                go_to_chat: 'Go to chat',
                see_requests: 'See requests'
            }
        },
        companies: {
            title: 'All Business Partners',
            filterPlaceHolder: 'Filter business partners',
            newCompany: 'New partner',
            export_btn: 'Export',
            filter_btn: 'Filter',
            refresh: 'Refresh',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel',
                export_btn: 'Export',
                export_file_name: 'businessPartners',
                export_excel_modal: {
                    title: 'Export Business Partners Excel',
                    cancel: 'Cancel',
                    export: 'Export',
                    all_columns: 'All columns'
                }
            },
            grid: {
                columns: {
                    id: 'Copy Id',
                    name: 'Name',
                    type: 'Type',
                    address: 'Address',
                    contactName: 'Contact name',
                    phoneNumber: 'Phone number',
                    email: 'Email',
                    vatNumber: 'Vat number',
                    paymentTerms: 'Payment terms',
                    paymentTermsRemarks: 'Payment terms remarks',
                    taxesDuties: 'Taxes & Duties',
                    remarksAlert: 'Popup an alert?',
                    monthlyIntrest: 'Monthly intrest',
                    creditLine: 'Credit line',
                    website: 'Website',
                    stateupdatenotification: 'State Update Notification',
                    salesman: 'Salesman'
                },
                filtersForm: {},
                menu: {
                    edit: 'Edit',
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'Time to set up your buisness partners.'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                parties: {
                    modal: {
                        create: 'Create',
                        FORWARDER: 'Forwarder',
                        BROKER: 'Broker',
                        SHIPPER: 'Shipper',
                        CONSIGNEE: 'Consignee'
                    }
                }
            },
            modal: {
                add_title: 'Add Business Partner',
                edit_title: 'Edit Business Partner'
            },
            filter: {
                type: 'Type',
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload business partners'
            }
        },
        company: {
            backLink: 'Back to business partners page',
            edit: 'Edit',
            name: 'Name',
            type: 'Type',
            address: 'Address',
            contactName: 'Contact name',
            phoneNumber: 'Phone number',
            email: 'Email',
            vatNumber: 'Vat number',
            website: 'Website',
            paymentTerms: 'Payment terms',
            paymentTermsRemarks: 'Payment terms remarks',
            remarks: 'Remarks',
            taxesDuties: 'Taxes & Duties',
            remarksAlert: 'Popup an alert?',
            monthlyIntrest: 'Monthly intrest',
            creditLine: 'Credit line',
            active: 'Active',
            inactive: 'Inctive',
            deleted: 'Deleted',
            invite_to_join_cargozone: 'Invite to Join Cargozone',
            stateupdatenotification: 'State Update Notification',
            salesman: 'Salesman',
            modal: {
                edit_title: 'Edit business partner'
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add business partner document',
                    edit_title: 'Edit business partner document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            products: {
                grid: {
                    columns: {
                        number: 'Number',
                        name: 'Name',
                        sku: 'SKU',
                        description: 'Description',
                        supplierName: 'Supplier',
                        category: 'Category',
                        brand: 'Brand',
                        cost: 'Cost',
                        retailPrice: 'Retail Price',
                        salePrice: 'Sale Price',
                        weight: 'Weight',
                        width: 'Width',
                        length: 'Length',
                        height: 'Height',
                        dimensionUnit: 'Dimension',
                        note: 'Note',
                        companyName: 'Company Name'
                    }
                }
            },
            contacts: {
                grid: {
                    columns: {
                        name: 'Name',
                        email: 'Email',
                        phoneNumber: 'Phone number',
                        role: 'Role',
                        backupContactName: 'Backup contact',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No contacts yet.. add the first contact by clicking on the Add contact button on the right.',
                add_contact: 'Add contact',
                modal: {
                    add_title: 'Add business partner contact',
                    edit_title: 'Edit business partner contact',
                    add_btn: 'Create',
                    edit_btn: 'Update'
                },
                form: {
                    name: 'Name',
                    email: 'Email',
                    phoneNumber: 'Phone number',
                    role: 'Role',
                    departments: 'Departments',
                    comment: 'Comment',
                    backupContact: 'Backup contact',
                    required_error: 'Required',
                    phone_error: 'Does not match phone pattern',
                    email_error: 'Does not match email pattern',
                    role_max_length_error: 'Max 50 characters',
                    phone_max_length_error: 'Max 40 characters',
                    comment_max_length_error: 'Max 250 characters'
                }
            },
            addresses: {
                grid: {
                    columns: {
                        name: 'Name',
                        country: 'Country',
                        airPort: 'Air Port',
                        seaPort: 'Sea Port',
                        phoneNumber: 'Phone number',
                        city: 'City',
                        address: 'Address',
                        personInChargeName: 'Person in charge',
                        description: 'Description',
                        isDefault: 'Is Default'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No addresses right now!',
                add_address: 'Add address',
                modal: {
                    add_title: 'Add business partner address',
                    edit_title: 'Edit business partner address',
                    add_btn: 'Create',
                    edit_btn: 'Update'
                },
                form: {
                    name: 'Name',
                    country: 'Country',
                    airPort: 'Air Port',
                    seaPort: 'Sea Port',
                    city: 'City',
                    address: 'Address',
                    description: 'Description',
                    phoneNumber: 'Phone number',
                    personInCharge: 'Person in charge',
                    isDefault: 'Is Default',
                    required_error: 'Required',
                    phone_error: 'Does not match phone pattern',
                    city_max_length_error: 'Max 200 characters',
                    address_max_length_error: 'Max 20 characters',
                    description_max_length_error: 'Max 200 characters',
                    name_max_length_error: 'Max 20 characters',
                    phone_number_max_length_error: 'Max 20 characters'
                }
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            }
        },
        purchaseOrders: {
            title: 'All POs',
            filterPlaceHolder: 'Filter POs',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel',
                export_file_name: 'purchaseOrders',
                export_excel_modal: {
                    title: 'Export Purchase Orders Excel',
                    cancel: 'Cancel',
                    export: 'Export',
                    all_columns: 'All columns'
                }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'PO Number',
                    consigneeName: 'Consignee',
                    createdAt: 'Open Date',
                    destinationStation: 'Destination',
                    incoterms: 'Incoterms',
                    lastEvent: 'Last event',
                    originStation: 'Origin',
                    productsName: 'Products name',
                    quantity: 'Items',
                    product: 'Product',
                    state: 'State',
                    userName: 'Created by',
                    vendorName: 'Supplier',
                    weight: 'Weight',
                    purchaseOrder_number: 'PO Number',
                    type: 'Type',
                    open_date: 'Open Date',
                    client_ref: 'Client Ref',
                    forwarder_ref: 'Forwarder Ref',
                    forwarder: 'Forwarder',
                    origin: 'Origin',
                    destination: 'Destination',
                    shipper: 'Shipper',
                    consignee: 'Consignee',
                    master_bl: 'Master',
                    house_bl: 'House',
                    container_no: 'Container Number',
                    carrier: 'Carrier',
                    customs_file: 'Custom File',
                    eta: 'ETA',
                    last_event: 'Last Event'
                },
                filtersForm: {
                    purchaseOrder_type: 'PO Type',
                    incoterms: 'Incoterms',
                    forworder: 'Forwarder',
                    from_date: 'From Date',
                    to_date: 'To Date',
                    origin_country: 'Origin Country',
                    origin_station: 'Origin Station',
                    destination_country: 'Destination Country',
                    destination_station: 'Destination Station'
                },
                menu: {
                    openRelatedTalk: 'Open related talk',
                    share: 'Share',
                    delete: 'Delete',
                    follow: 'Follow',
                    unfollow: 'Unfollow',
                    invite_to_follow: 'Invite to follow',
                    share_shipment: 'Share Shipment',
                    make_shipment: 'Make shipment',
                    export: 'Export',
                    pdf: 'Pdf',
                    pdf_no_prices: 'Pdf with no prices',
                    excel: 'Excel',
                    confirmMessage: 'Are you sure?'
                },
                purchaseOrderWarning: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No PO yet, to create your first PO, you should click on the new PO button up right.'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                phone_error: 'Does not match phone pattern',
                email_error: 'Does not match email pattern',
                maxLengthMessage: (chars: string) => `Max ${chars} characters`,
                back_modal: {
                    purchaseOrders_page: 'POs Page',
                    purchaseDemand_details: 'PD Details',
                    purchaseOrder_details: 'PO Details'
                },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                parties: {
                    modal: {
                        create: 'Create',
                        FORWARDER: 'Forwarder',
                        VENDOR: 'Supplier',
                        CONSIGNEE: 'Consignee'
                    }
                },
                route_guard: 'Close without saving?',
                pick_contact: 'Pick contact',
                contact_name: 'Contact Name',
                contact_phone: 'Contact Phone',
                contact_email: 'Contact Email',
                pick_address: 'Pick address',
                destination_country: 'Destination Country',
                pick_country: 'Pick Country',
                destination_port: 'Destination Port',
                pick_port: 'Pick port',
                destination_city: 'Destination City \\ Zipcode',
                pick_zipcode: 'Pick zipcode',
                destination_pick_up_address: 'Destination Pick-Up Address',
                origin_country: 'Origin Country',
                origin_port: 'Origin Port',
                origin_city: 'Origin City \\ Zipcode',
                origin_pick_up_address: 'Origin Pick-Up Address',
                expected_ready_date: 'Estimate Goods Ready',
                asap: 'As soon as possible',
                consignee_same_as_company: 'Ship to my company',
                requested_goods_ready: 'Requested Goods Ready',
                actual_goods_ready: 'Actual Goods Ready',
                payment_terms: 'Payment Terms',
                payment_dates: 'Payment Dates',
                note: 'Note',
                taxes_rate: 'Taxes Rate',
                code: 'Code',
                name: 'Name',
                dimensions: 'Dimensions',
                CM: 'CM',
                IN: 'IN',
                LB: 'LB',
                KG: 'KG',
                quantity: 'Quantity',
                product_name: 'Name',
                length: 'Length',
                width: 'Width',
                height: 'Height',
                weight: 'Weight',
                sku: 'SKU',
                price: 'Cost',
                total_cost: 'Total Cost',
                attributes: 'Attributes',
                cartons: 'Cartons',
                ready_date: 'Ready Date',
                pd_ref: 'PD Ref',
                images: 'Images',
                remaining_quantity: 'Remaining Quantity',
                qc_approved: 'Qc Approved',
                shipments_refs: 'Shipments Refs',
                shipment: 'Shipment',
                shipments: 'Shipments',
                add_item: 'Add item',
                update_ready_date: 'Update ready date',
                create_shipment: 'Create shipment',
                merge_shipment: 'Merge PO to Shipment',
                reference_no: 'Reference No.',
                add_ref_no: 'Add Ref. No.',
                incoterms_tooltip: 'The agreed trade terms as mentioned in the shipment invoice',
                shipper_tooltip: 'The company you bought the goods from',
                consignee: 'Ship to',
                consignee_placeholder: 'Pick company',
                consignee_tooltip: 'The company receiving the goods at destination',
                lead_days_tooltip: 'The time from the moment the customer places an order to the moment it is ready for delivery',
                forwarder_tooltip: 'The company handling the cargo shipping',
                reference_no_tooltip: 'This can be your Purchase order number/name or nickname',
                update_product_cost: 'Update also catalog product cost',
                merge_shipment_modal: {
                    title: 'Choose Shipment',
                    fields: {
                        type: 'Type',
                        company: 'Company',
                        shipper: 'Shipper',
                        forwarder: 'Forwarder',
                        last_event: 'Last Event',
                        quantity: 'Quantity',
                        volume: 'Volume',
                        weight: 'Weight'
                    },
                    dialog: {
                        title: 'Where do you want to go?',
                        po: 'PO',
                        shipment: 'Shipment'
                    },
                    merge_btn: 'Merge'
                }
            },
            filter: {
                po_type: 'PO Type',
                incoterms: 'Incoterms',
                forwarder: 'Forwarder',
                open_date: 'Open Date',
                from_date: 'From Date',
                to_date: 'To Date',
                origin_country: 'Origin Country',
                origin_station: 'Origin Station',
                destination_country: 'Destination Country',
                destination_station: 'Destination Station',
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter'
            },
            export_btn: 'Export',
            new_purchase_order: 'New Purchase Order',
            date_from: 'Date from: ',
            date_to: 'Date to: '
        },
        purchaseOrder: {
            backLink: 'Back to POs page',
            lastUpdate: 'Last update:',
            edit: 'Edit',
            duplicate: 'Duplicate',
            purchaseOrder: 'PO',
            client_ref: 'Client Ref:',
            company: 'Company',
            forwarder_ref: 'Forwarder Ref:',
            last_event: 'Last Event:',
            eta: 'ETA:',
            master: 'Master',
            house: 'House',
            etd: 'ETD',
            supplier: 'Supplier',
            customs_file: 'Customs File',
            carrier: 'Carrier',
            port_terminal: 'Port terminal',
            incoterms: 'Incoterms',
            quantity: 'Items',
            see_more: 'See more >',
            volume: 'Volume',
            weight: 'Weight',
            name: 'Name',
            address: 'Address',
            contactName: 'Contact Name',
            phoneNumber: 'Phone Number',
            email: 'Email',
            save_btn: 'Save',
            expected_ready_date: 'Estimate Goods Ready',
            total_cartons: 'Total Cartons',
            asap: 'As soon as possible',
            requested_goods_ready: 'Requested Goods Ready',
            actual_goods_ready: 'Actual Goods Ready',
            payment_terms: 'Payment Terms',
            payment_dates: 'Payment Dates',
            wand_tooltip: 'Create payment dates by payment terms',
            note: 'Note',
            taxes_rate: 'Taxes Rate',
            chargeable_weight: 'Chargeable Weight',
            deleted: 'Deleted',
            confirm: 'Confirm',
            confirmed_message: 'Thank you! Purchase order has been confirmed.',
            alert_status: {
                critical: 'Critical',
                warning: 'Warning',
                success: 'All good'
            },
            send_supplier_updates_email_modal: {
                title: 'Send to supplier',
                sent_message: 'Email has been sent to the supplier',
                form: {
                    email: 'Email',
                    note: 'Note',
                    send_btn: 'Send',
                    cancel_btn: 'Cancel',
                    required: 'Required',
                    invalid_email: 'Invalid Email'
                }
            },
            parties: {
                empty_state: 'No parties right now!',
                add_party: 'Add',
                modal: {
                    add_title: 'Add PO party',
                    edit_title: 'Edit PO party',
                    add_btn: 'Add',
                    edit_btn: 'Update',
                    cancel_btn: 'Cancel'
                },
                form: {
                    partytype_placeholder: 'Choose party type',
                    partysubtype_placeholder: 'Choose party sub type',
                    companyaddresses_placeholder: 'Choose address',
                    companycontacts_placeholder: 'Choose contact',
                    partyname_placeholder: 'Name',
                    partycountry_placeholder: 'Country',
                    partycity_placeholder: 'City',
                    partyaddress_placeholder: 'Address',
                    partyphone_placeholder: 'Phone number',
                    partyemail_placeholder: 'Email',
                    remarks_placeholder: 'Remarks',
                    partycontact_name_placeholder: 'Contact name',
                    partyvat_number_placeholder: 'Vat number',
                    partywebsite_placeholder: 'Website',
                    required_error: 'Required',
                    phone_error: 'Does not match phone pattern',
                    email_error: 'Does not match email pattern',
                    create_company_party: 'Create Business Partner',
                    address_max_length_error: 'Max 250 characters',
                    phone_max_length_error: 'Max 40 digits',
                    email_max_length_error: 'Max 100 characters',
                    website_max_length_error: 'Max 50 characters',
                    vat_number_max_length_error: 'Max 20 characters',
                    name_max_length_error: 'Max 100 characters',
                    updatestates_placeholder: 'State Update Notification',
                    salesman_placeholder: 'Salesman'
                },
                menu: {
                    edit: 'Edit',
                    delete: 'Delete'
                }
            },
            events: {
                grid: {
                    columns: {
                        id: 'Id',
                        eventCode: 'Code',
                        name: 'Event',
                        description: 'Description',
                        userName: 'Insert By',
                        eventDate: 'Event Date',
                        createdAt: 'Created Date',
                        comment: 'Comment',
                        displayOnlyForCompanyId: '',
                        displayOnlyForCompanyIdTooltip: 'This event is visible only to your company',
                        expirationDate: 'Expiration Date'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No events right now!',
                add_event: 'Add event',
                modal: {
                    add_title: 'Add PO event',
                    edit_title: 'Edit PO event',
                    add_btn: 'Add event',
                    edit_btn: 'Update event',
                    cancel_btn: 'Cancel'
                },
                form: {
                    eventtype: 'Event type',
                    eventtype_placeholder: 'Choose event type',
                    comment: 'Comment',
                    comments_placeholder: 'Add a comment here...',
                    required_error: 'Required',
                    comment_max_length_error: 'Max 255 characters'
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment',
                        displayOnlyForCompanyId: ' ',
                        displayOnlyForCompanyIdTooltip: 'This document is visible only to your company',
                        expirationDate: 'Expiration Date'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents yet.. add the first document by clicking on the Add document button on the right.',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add PO document',
                    edit_title: 'Edit PO document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            items: {
                modal_title: 'Items',
                add_item: 'Add items',
                modal: {
                    add_title: 'Add Items',
                    edit_title: 'Edit Item',
                    add_btn: 'Add Item',
                    edit_btn: 'Update Item',
                    cancel_btn: 'Cancel'
                },

                grid: {
                    columns: {
                        quantity: 'Quantity',
                        sku: 'SKU',
                        code: 'Code',
                        name: 'Name',
                        price: 'Cost',
                        currency: 'Currency',
                        dimensions: 'Dimensions',
                        weight: 'Weight'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No items right now!'
            },
            routes: {
                modal_title: 'Routes',
                grid: {
                    columns: {
                        origin_station: 'Origin',
                        destination_station: 'Destination',
                        departure: 'Departure',
                        arrival: 'Arrival',
                        flight_number: 'Flight number'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                empty_state: 'No routes right now!'
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            },
            history: {
                item_dictionary: {
                    CREATE_PURCHASE_ORDER: 'create new PO',
                    CREATE_FOLLOWERS: 'start follow PO',
                    CREATE_EVENTS: 'create new event',
                    CREATE_DOCUMENTS: 'create new document',
                    CREATE_PARTIES: 'create new party',
                    'CREATE_UPLOAD-MULTIPLE': 'upload files',
                    CREATE_PURCHASEORDERS: 'create new PO',
                    CREATE_DISCUSSIONS: 'create new discussion',
                    CREATE_UPLOAD: 'create new upload',
                    CREATE_ITEM: 'create new item',
                    CREATE_REFERENCE: 'create new reference',
                    CREATE_EVENT: 'create new event',
                    CREATE_PARTY: 'create new party',

                    UPDATE_PURCHASE_ORDER: 'update PO',
                    UPDATE_EVENTS: 'update event',
                    UPDATE_DOCUMENTS: 'update document',
                    UPDATE_PARTIES: 'update party',
                    'UPDATE_UPLOAD-MULTIPLE': 'upload files',
                    UPDATE_PURCHASEORDERS: 'update PO',
                    UPDATE_DISCUSSIONS: 'update PO',
                    UPDATE_UPLOAD: 'update PO',
                    UPDATE_STATE: 'update PO state',
                    UPDATE_ITEM: 'update item',
                    UPDATE_REFERENCE: 'update reference',
                    UPDATE_EVENT: 'update event',
                    UPDATE_PARTY: 'update party',

                    DELETE_PURCHASE_ORDER: 'delete PO',
                    DELETE_FOLLOWERS: 'unfollow PO',
                    DELETE_EVENTS: 'delete event',
                    DELETE_DOCUMENTS: 'delete document',
                    DELETE_PARTIES: 'delete party',
                    'DELETE_UPLOAD-MULTIPLE': 'delete files',
                    DELETE_PURCHASEORDERS: "delete PO's",
                    DELETE_DISCUSSIONS: 'delete discussion',
                    DELETE_UPLOAD: 'delete upload'
                }
            },
            references: {
                modal: {
                    add: 'Add',
                    edit: 'Edit'
                },
                types: {
                    CLIENT: 'Client Ref',
                    FORWARDER: 'Forwarder Ref'
                },
                form: {
                    maxLength: (length: number) => `Max ${length} characters`,
                    value: 'Value',
                    cancel_btn: 'Cancel',
                    delete_btn: 'Delete',
                    save_btn: 'Save'
                }
            },
            exceptions: {
                '401': {
                    title: 'Access denied'
                },
                back_btn: 'Go to POs page'
            }
        },
        purchaseDemands: {
            title: 'All PDs',
            filterPlaceHolder: 'Filter PDs',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel',
                export_file_name: 'purchaseDemands',
                export_excel_modal: {
                    title: 'Export Purchase Demands Excel',
                    cancel: 'Cancel',
                    export: 'Export',
                    all_columns: 'All columns'
                }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'PD Number',
                    createdAt: 'Open Date',
                    state: 'State',
                    companyName: 'Company',
                    userName: 'User',
                    quantity: 'Number of items',
                    approvers: 'Approvers'
                },
                filtersForm: {
                    purchaseDemand_type: 'PD Type',
                    from_date: 'From Date',
                    to_date: 'To Date'
                },
                menu: {
                    delete: 'Delete',
                    export: 'Export',
                    pdf: 'Pdf',
                    excel: 'Excel',
                    confirmMessage: 'Are you sure?'
                },
                purchaseDemandWarning: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No PD yet, to create your first PD, you should click on the new PD button up right.'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                requiredItem: 'At least one item is required',
                maxLengthMessage: (chars: string) => `Max ${chars} characters`,
                back_modal: {
                    purchaseDemands_page: 'PDs Page',
                    purchaseDemand_details: 'PD Details'
                },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                route_guard: 'Close without saving?',
                add_item: 'Add item',
                company: 'Company',
                name: 'Name',
                dimensions: 'Dimensions',
                CM: 'CM',
                IN: 'IN',
                LB: 'LB',
                KG: 'KG',
                quantity: 'Quantity',
                product_name: 'Name',
                length: 'Length',
                width: 'Width',
                height: 'Height',
                weight: 'Weight',
                productSupplierName: 'Supplier',
                sku: 'SKU',
                price: 'Cost',
                minOrderQuantity: 'Minimum Order Quantity',
                productionDays: 'LT Production',
                supplier: 'Supplier',
                total_cost: 'Total Cost',
                purchase_order_id: 'PO',
                pos: 'POs',
                pos_refs: 'POs Refs',
                reset_po_state_message: 'This change will reset the approvers’ vote and the PD’s state',
                remaining_quantity: 'Remaining Quantity',
                images: 'Images',
                see_purchase_order: 'See PO',
                attributes: 'Attributes',
                cartons: 'Cartons',
                save_btn: 'Save',
                cancel_btn: 'Cancel',
                approvers: 'Approvers',
                select_users: 'Select users',
                requested_goods_ready: 'Requested Goods Ready',
                asap: 'As soon as possible',
                default_approvers: 'This users are required for approval:',
                update_product_cost: 'Update also catalog product cost',
                create_po: 'Create PO',
                create_po_modal_title: 'Choose supplier and then proudcts',
                expected_ready_date: 'Expected Ready Date',
                purchaseOrder: 'PO',
                purchaseDemand: 'PD',
                merge_po_modal_title: 'Merge PD to PO',
                items_title: 'Items',
                parties_title: 'Parties',
                include_insurance: 'Include Insurance',
                no_suppliers: 'No Suppliers',
                back_btn: 'Back',
                next_btn: 'Next',
                items: {
                    modal_title: 'Items',
                    add_item: 'Add items',
                    modal: {
                        add_title: 'Add Items',
                        edit_title: 'Edit Item',
                        add_btn: 'Add Item',
                        edit_btn: 'Update Item',
                        cancel_btn: 'Cancel'
                    },

                    grid: {
                        columns: {
                            quantity: 'Quantity',
                            sku: 'SKU',
                            code: 'Code',
                            name: 'Name',
                            price: 'Cost',
                            currency: 'Currency',
                            dimensions: 'Dimensions',
                            weight: 'Weight',
                            attributes: 'Attributes',
                            cartons: 'Cartons',
                            total_cost: 'Total Cost'
                        },
                        filter: {
                            noResults: 'No results found for your search : (',
                            resetBtn: 'Reset Filter',
                            noData: 'No data'
                        },
                        menu: {
                            edit: 'Edit',
                            delete: 'Delete'
                        }
                    },
                    empty_state: 'No items right now!'
                }
            },
            filter: {
                pd_type: 'PD Type',
                open_date: 'Open Date',
                from_date: 'From Date',
                to_date: 'To Date',
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter',
                state: 'State'
            },
            modal: {
                add_title: 'Add PD'
            },
            export_btn: 'Export',
            new_purchase_demand: 'New Purchase Demand',
            date_from: 'Date from: ',
            date_to: 'Date to: '
        },
        purchaseDemand: {
            backLink: 'Back to PDs page',
            lastUpdate: 'Last update:',
            edit: 'Edit',
            duplicate: 'Duplicate',
            send: 'Send',
            purchaseDemand: 'PD',
            requested_goods_ready: 'Requested Goods Ready',
            reset_po_state_message: 'This change will reset the approvers’ vote and the PD’s state',
            asap: 'As soon as possible',
            company: 'Company',
            save_btn: 'Save',
            back_btn: 'Back',
            next_btn: 'Next',
            deleted: 'Deleted',
            alert_status: {
                critical: 'Critical',
                warning: 'Warning',
                success: 'All good'
            },
            purchase_demand_state: 'Purchase Demand State',
            create_po: 'Create PO',
            create_po_modal_title: 'Choose supplier and then proudcts',
            expected_ready_date: 'Expected Ready Date',
            purchaseOrder: 'PO',
            merge_po_modal_title: 'Merge PD to PO',
            items_title: 'Items',
            parties_title: 'Parties',
            include_insurance: 'Include Insurance',
            no_suppliers: 'No Suppliers',
            items: {
                modal_title: 'Items',
                add_item: 'Add items',
                modal: {
                    add_title: 'Add Items',
                    edit_title: 'Edit Item',
                    add_btn: 'Add Item',
                    edit_btn: 'Update Item',
                    cancel_btn: 'Cancel'
                },

                grid: {
                    columns: {
                        quantity: 'Quantity',
                        sku: 'SKU',
                        code: 'Code',
                        name: 'Name',
                        price: 'Cost',
                        currency: 'Currency',
                        dimensions: 'Dimensions',
                        weight: 'Weight',
                        attributes: 'Attributes',
                        cartons: 'Cartons',
                        total_cost: 'Total Cost'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No items right now!'
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            },
            history: {
                item_dictionary: {
                    CREATE_PURCHASE_DEMAND: 'create new PD',
                    CREATE_PURCHASEORDERS: 'create new PD',
                    CREATE_DISCUSSIONS: 'create new discussion',
                    CREATE_ITEM: 'create new items',
                    CREATE_APPROVER: 'create new approver',

                    UPDATE_PURCHASE_DEMAND: 'update PD',
                    UPDATE_PURCHASEORDERS: 'update PD',
                    UPDATE_DISCUSSIONS: 'update PD',
                    UPDATE_STATE: 'update PD state',
                    UPDATE_ITEM: 'update new items',
                    UPDATE_APPROVER: 'update new approver',

                    DELETE_PURCHASE_DEMAND: 'delete PD',
                    DELETE_FOLLOWERS: 'unfollow PD',
                    DELETE_PURCHASEORDERS: "delete PD's",
                    DELETE_DISCUSSIONS: 'delete discussion',
                    DELETE_ITEMS: 'delete new items',
                    DELETE_APPROVERS: 'delete new approver'
                }
            },
            exceptions: {
                '401': {
                    title: 'Access denied'
                },
                back_btn: 'Go to PDs page'
            },
            approvers: {
                header: 'Approvers',
                empty_approvers: 'No approvers right now!',
                select_user: 'Add new approver'
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents yet.. add the first document by clicking on the Add document button on the right.',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add PD document',
                    edit_title: 'Edit PD document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            }
        },
        routes: {
            titles: {
                shipments: 'All Shipments',
                sub_shipments: 'Shipment Details',
                companies: 'All Business Partners',
                sub_companies: 'Business partner Details',
                messages: 'Cargozone Chat',
                contacts: 'Contacts'
            }
        },
        bid: {
            title: 'All Bids',
            filterPlaceHolder: 'Filter bids',
            newBid: 'New Bid',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel'
            },
            bid: 'Bid',
            backLink: 'Back to bids',
            edit: 'Edit',
            startBid: 'Start bid',
            client_ref: 'Client Ref',
            company: 'Company',
            forwarder: 'Forwarder',
            ata: 'ATA',
            grid: {
                columns: {
                    CargoZoneNumber: 'Bid number',
                    shipmentId: 'Shipment',
                    startDate: 'Start date',
                    expirationDate: 'Expiration date',
                    includeInsurance: 'Include Insurance'
                },
                filtersForm: {},
                menu: {
                    edit: 'Edit',
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'Looks like you did not set any bid yet. To generate the first bid, click on the new bid button up right'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                back_modal: {
                    bids_page: 'Bids Page',
                    bid_details: 'Bid Details'
                },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                parties: {
                    modal: {
                        create: 'Create',
                        FORWARDER: 'Forwarder',
                        BROKER: 'Broker',
                        SHIPPER: 'Shipper',
                        CONSIGNEE: 'Consignee'
                    }
                },
                company: 'Company',
                company_placeholder: 'Pick company',
                shipment: 'Shipment',
                shipment_placeholder: 'Pick shipment',
                startdate: 'Start date',
                expirationdate: 'Expiration date',
                includeInsurance: 'Include Insurance',
                route_guard: 'Close without saving?'
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add bid document',
                    edit_title: 'Edit bid document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            quotations: {
                grid: {
                    columns: {
                        forwarderName: 'Forwarder name',
                        freightPrice: 'Total Freight Price (USD)',
                        transitTime: 'Transit time',
                        freeDays: 'Free days',
                        validTill: 'Valid till',
                        note: 'note'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                empty_state: 'No quotations right now!'
            },
            history: {
                item_dictionary: {
                    CREATE_BID: 'create new bid',
                    CREATE_QUOTATION: 'create new quotation',
                    CREATE_PARTY: 'create new party',
                    CREATE_DOCUMENT: 'create new document',
                    CREATE_BIDS: 'create new bid',

                    UPDATE_BID: 'update bid',
                    UPDATE_QUOTATION: 'update quotation',
                    UPDATE_PARTY: 'update party',
                    UPDATE_DOCUMENT: 'update document',
                    UPDATE_BIDS: 'update bid',

                    DELETE_BID: 'delete bid',
                    DELETE_QUOTATION: 'delete quotation',
                    DELETE_PARTY: 'delete party',
                    DELETE_DOCUMENT: 'delete document',
                    DELETE_BIDS: 'delete bid'
                }
            },
            references: {
                modal: {
                    add: 'Add',
                    edit: 'Edit'
                },
                types: {
                    CLIENT: 'Client Ref',
                    FORWARDER: 'Forwarder Ref'
                }
            }
        },
        quotation: {
            packages: {
                modal_title: 'Packages',
                grid: {
                    columns: {
                        type: 'Type',
                        quantity: 'Quantity',
                        description: 'Description',
                        containerNumber: 'Container number',
                        containerType: 'Container type',
                        isOverweight: 'Overweight',
                        dimensions: 'Dimensions',
                        weight: 'Weight',
                        volume: 'Volume',
                        hsCode: 'HS Code'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    }
                },
                empty_state: 'No packages right now!'
            },
            expire: 'Expire',
            incoterms: 'Incoterms',
            shipment_type: 'Shipment type',
            items_title: 'Items',
            parties_title: 'Parties',
            packages_title: 'Packages',
            include_insurance: 'Include Insurance',
            attached_files: 'Attached files',
            quantity: 'Quantity',
            weight: 'Weight',
            volume: 'Volume',
            note: 'Note',
            quotation_request: 'Quotation Request',
            reply_page_default_error_message: 'Token is expired or incorret',
            po_ref: 'PO Ref',
            sku: 'SKU',
            code: 'Code',
            name: 'Name',
            product_name: 'Name',
            price: 'Cost',
            currency: 'Currency',
            dimensions: 'Dimensions',
            total_cost: 'Total Cost',
            attributes: 'Attributes',
            ready_date: 'Ready Date',
            pd_ref: 'PD Ref',
            cartons: 'Cartons',
            images: 'Images',
            remaining_quantity: 'Remaining Quantity',
            shipments_refs: 'Shipments Refs',
            qc_approved: 'Qc Approved',
            shipments: 'Shipments',
            form: {
                required_message: 'This field is required',
                note_max_length: 'Max. 512 characters',
                sent_quotation: 'Your quotation was sent',
                we_will_update: 'We will update you as soon there is a winner',
                send_again: 'Send another quotation',
                add_quotation: 'Add your quotation’s',
                quotation: 'Quotation',
                total_freight_price: 'Total Freight Price',
                transit_time: 'Transit time',
                demurrage_free_days: 'Demurrage free days',
                valid_till: 'Valid till',
                note: 'Note',
                file_placeholder: 'Add file here',
                next_btn: 'Next',
                send_btn: 'Send',
                back_btn: 'Back',
                skip_btn: 'Skip'
            }
        },
        common: {
            loaderText: 'Loading',
            click: 'Click',
            follow: 'Follow',
            view: 'View',
            go_back: 'Go Back',
            date: 'Date',
            time: 'Time',
            are_you_sure: 'Are you sure?',
            copied_message: 'Copied succesfully!',
            no_permission: 'No Permission',
            yes: 'Yes',
            no: 'No'
        },
        files: {
            preview: {
                no_preview: 'No Preview',
                modal: {
                    download_btn: 'Download'
                }
            }
        },
        chat: {
            contacts_side_bar: {
                new_group: 'New group',
                invite_users: 'Invite users'
            },
            chat_view: {
                footer: {
                    are: 'are ',
                    is: 'is ',
                    editing_message: 'Editing message',
                    reply_to: 'Reply to',
                    send_connection_request: 'Send a connection request'
                },
                header: {
                    exit_group: 'Exit group'
                },
                participants_header: {
                    are_you_sure: 'Are you sure?'
                },
                content: {
                    reply: 'Reply',
                    set_reminder: 'Set Reminder',
                    reminder_about_message: 'Reminder about message',
                    edit: 'Edit',
                    delete: 'Delete'
                }
            },
            cannot_be_empty: 'Cannot be empty',
            max_chars: (chars: number) => `Max ${chars} characters`,
            created_group: 'created group',
            group_name: 'Group Name',
            select_users: 'Select users',
            create_btn: 'Create',
            create_new_group: 'Create a new group',
            value: 'Value',
            save_btn: 'Save',
            invalid_email: 'invalid email',
            enter_email: 'Enter an email',
            send_btn: 'Send',
            connection_requests: 'Connection Requests',
            approve_btn: 'Approve',
            waiting_for: 'Waiting for',
            to_approve: 'to approve',
            typing: 'Typing',
            required: 'Required',
            search_users: 'Search Users'
        },
        modal: {
            confirm_text: 'Close without saving?'
        }
    },
    es: {
        login: {
            username: 'Nombre de usuario o email',
            password: 'Contraseña',
            forget_password: '¿Se te olvidó tu contraseña?',
            rights: '© 2020 CargoZone, Inc. Todos los derechos reservados.'
        },
        sidebar: {
            links: {
                dashboard: 'Tablero',
                shipments: 'Envíos',
                calendar: 'Calendario'
            }
        },
        tasksStrip: {
            title: 'Tareas',
            priority: {
                high: 'Alto',
                medium: 'Medio',
                low: 'Bajo'
            },
            status: {
                done: 'Hecho',
                pending: 'Pendiente',
                stuck: 'Atascado',
                onWork: 'Progreso',
                archived: 'Archivado'
            }
        },
        dashboard: {
            summary: {
                title: 'Tablero',
                activeShipments: {
                    title: 'Envíos activos'
                },
                monthArrivals: {
                    title: 'Llegadas del mes actual'
                },
                missingDocuments: {
                    title: 'Documentos faltantes'
                },
                starredShipments: {
                    title: 'Envíos destacados'
                }
            }
        },
        shipments: {
            title: 'Todos los envíos',
            grid: {
                columns: {
                    shipment_number: 'Numero de envío',
                    type: 'Tipo',
                    client_ref: 'Cliente Ref',
                    forwarder_ref: 'Forwarder Ref',
                    origin: 'Origen',
                    destination_country: 'Destino',
                    shipper: 'Expedidor'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                }
            }
        },
        shipment: {
            backLink: 'Back to shipments'
        },
        calendar: {
            title: 'Página del calendario'
        },
        common: {
            loaderText: 'Cargando'
        }
    },
    cn: {
        login: {
            email: '邮箱',
            password: '密码',
            rememberMe: '记住我',
            forget_password: '忘记密码？',
            rights: 'CargoZone版权声明',
            login: '登录',
            errors: { email: '邮件必须有效', email_required: '输入电子邮件', pwd_required: '输入密码' },
            forgot_pswd_form: {
                email: '邮箱',
                send_btn: '发送邮件',
                login: '登录',
                errors: { email_required: '输入电子邮件', email_invalid: '邮件必须有效' }
            },
            reset_pswd_form: {
                new_pwd: '新密码',
                confirm_pwd: '确认密码',
                send_btn: '更改密码',
                login: '登录',
                welcome_msg: '欢迎界面！请输入密码',
                password_requirments_title: '密码要求',
                password_requirments:
                    ' • be a minimum of eight (8) characters in length\n• contain at least one (1) character from the \nfollowing categories:\n 1. Uppercase letter (A-Z)\n 2. Lowercase letter (a-z)\n 3. Digit (0-9)\n ',
                errors: { not_match: '密码必须匹配', pwd_invalid: '密码必须有效', pwd_required: '输入密码' }
            }
        },
        get_started: {
            used_link: 'Someone already used this link',
            invalid_token: 'Token is invalid or has been expired',
            create_new: {
                title: 'First, enter your email',
                sub_title: 'We suggest using the email address you use at work.',
                email_placeholder: 'name@company.com',
                continue_btn: 'Continue',
                ask_account: 'Already have account?',
                sign_in: 'Sign in',
                email_exist_err: 'This email is already in use',
                email_not_valid: 'Not valid email',
                required: 'Required'
            },
            confirm_email: {
                title: 'Check your email for a code',
                sub_title: (email: string) => `We’ve sent a 6-digit code to ${email}. The code expires shortly, so please enter it soon.`,
                ask_cannot_find: 'Can’t find your code? Check your spam folder!',
                checking_code: 'Checking your code',
                validate_email_err: "That code wasn't valid or has been expired. Give it another go!"
            },
            setup_company: {
                title: 'Setup your company',
                name: 'Company Name',
                type: 'Company Type',
                create_btn: 'Create',
                company_name_err: 'This company name already exists. Please try another.',
                required: 'Required'
            },
            create_user: {
                title: 'Provide your details',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                username: 'Username',
                role: 'Role',
                phone_num: 'Phone number',
                birth_date: 'Birth Date',
                create_btn: 'Done',
                errors: {
                    max_100: 'Max 100 characters',
                    max_50: 'Max 50 characters',
                    max_40: 'Max 40 digits',
                    required: 'Required',
                    invalid_phone_num: 'Invalid phone number',
                    email_invalid: 'Email must be valid',
                    email_exist: 'This email address is already being used'
                }
            }
        },
        sidebar: {
            links: {
                dashboard: '模块',
                shipments: '船期',
                purchaseOrders: '订单',
                purchaseDemands: 'Purchase Demands',
                procurement: 'Procurement',
                shipmentsBoard: '出货模块',
                calendar: '日历',
                companies: '商业伙伴',
                bids: '标价',
                messages: '信息',
                containers: 'FCL Guard',
                admin: 'Admin',
                users_mgmt: 'Users',
                companies_mgmt: 'Companies',
                partners_relations: 'Partners Relations',
                catalog: 'Catalog',
                products: 'Products',
                inventory: 'Inventory',
                orders: 'Orders',
                tariffs: 'Tariff'
            }
        },
        tasksStrip: {
            title: '任务',
            add_task: '地址',
            priority: { high: '高级', medium: '中级', low: '低级' },
            status: { done: '完成', pending: '待定', new: '新', stuck: '暂停', onWork: '正在进行', archived: '已封存' },
            form: {
                required_error: '输入',
                max_length_error: '最多500个字符',
                status_placeholder: '状态',
                priority_placeholder: '优先',
                content_placeholder: '内容',
                duedate_placeholder: '到期日',
                reminder_placeholder: '提醒'
            },
            modal: { submit_btn: '创建', add_title: '新增任务', create_task: '创建' },
            empty_state: { title: '等待界面', sub_title: '点击add按钮新增任务' }
        },
        followingShipments: {
            following: '跟进',
            clear: '清除',
            shipment_type: '船期类型',
            shipment_item: { shipment: '船期', last_event: '上次事件', eta: '交期', commodity: '品名', unfollow: '无跟进', ref: '参考' },
            empty_state: '空白界面，提示您可以通过点击follow按钮在船期菜单中跟进任意一个您需要知道进度或者做过更新的船期',
            following_tabs: { following: '跟进', invitations: '邀请' }
        },
        containers: {
            header: {
                title: 'FCL Guard (Storage, Demurrage & Detention)',
                text_filter: { placeholder: 'Filter' },
                exportOptions: {
                    pdf: 'Pdf',
                    excel: 'Excel',
                    export_btn: 'Export',
                    export_file_name: 'businessPartners',
                    export_excel_modal: { title: 'Export Business Partners Excel', cancel: 'Cancel', export: 'Export', all_columns: 'All columns' }
                }
            },
            stats: {
                totalContainers: 'Total containers',
                totalContainersTooltip: 'Displays active containers with ETA. From booking state and further.',
                returnOverdue: 'Containers with Demurrage or Detention charges',
                returnOverdueTooltip:
                    'Displays the number of containers that already passed the average Free Days at the port of discharge (6 days).',
                storageOverdue: 'Containers with storage charges',
                storageOverdueTooltip:
                    'Displays the number of containers that already passed the average storage free days at the port of discharge (4 days).',
                estimateCosts: 'Cumulative Estimated Costs.',
                estimateCostsTooltip: 'Displays the total estimated charges for Storage, Detention & Demurrage according to the global average.'
            },
            grid: {
                tooltips: {
                    state: 'Indicates the current location & status of the container/s. For top accuracy, it is recommended to update the container state as close as possible to the physical progress of the container.'
                },
                fields: {
                    containerNumber: 'Container#',
                    shipmentCargoZoneNumber: 'Shipment#',
                    containerType: 'Type',
                    lastEvent: 'Last Event',
                    arrivalDate: 'Arrival',
                    storageDays: 'Storage days',
                    returnDays: 'Demurrage or Detention overdue',
                    state: 'State',
                    estimateCosts: 'Est. Costs'
                }
            }
        },
        admin: {
            users_mgmt: {
                add_edit_user: {
                    add_user_form: {
                        title: 'Create User',
                        allowed_companies: 'Allowed Companies',
                        allowed_shipment_types: 'Allowed Shipment Types',
                        allowed_related_companies: 'Allowed Related Companies',
                        success: 'Success',
                        email_sent: 'Email has been sent to',
                        fields: {
                            first_name: 'First Name',
                            last_name: 'Last Name',
                            email: 'Email',
                            username: 'User Name',
                            role: 'Role',
                            phone_num: 'Phone Number',
                            birth_date: 'Birth Date'
                        },
                        permissions: 'Permissions',
                        claims: {
                            shipment: 'Shipment',
                            edit_state: 'Edit State',
                            po: 'Purchase Order',
                            pd: 'Purchase Demand',
                            add_document: 'Add Documents',
                            edit_document: 'Edit Documents',
                            view_document: 'View Documents',
                            delete_document: 'Delete Documents',
                            add_event: 'Add Events',
                            edit_event: 'Edit Events',
                            view_event: 'View Events',
                            delete_event: 'Delete Events',
                            admin: 'Admin',
                            view: 'Access to Admin',
                            add_shipment: 'Add Shipment',
                            duplicate_shipment: 'Duplicate Shipment',
                            view_pd: 'Purchase Demand Access',
                            edit_pd_item: 'Edit Purchase Demand Item',
                            delete_pd_item: 'Delete Purchase Demand Item',
                            edit_pd_item_cost: 'Edit Purchase Demand Item Cost',
                            add_pd_item: 'Add Purchase Demand Item',
                            add_pd: 'Add Purchase Demand',
                            edit_pd: 'Edit Purchase Demand',
                            delete_pd: 'Delete Purchase Demand',
                            add_approver: 'Add Purchase Demand Approver',
                            delete_approver: 'Delete Purchase Demand Approver'
                        },
                        errors: {
                            max_100: 'Max 100 characters',
                            max_50: 'Max 50 characters',
                            max_40: 'Max 40 digits',
                            required: 'Required',
                            invalid_phone_num: 'Invalid phone number',
                            email_invalid: 'Email must be valid',
                            email_exist: 'This email address is already being used'
                        },
                        submit_btn: 'Submit'
                    },
                    edit_user_form: {
                        title: 'Edit User Details',
                        allowed_companies: 'Allowed Companies',
                        allowed_shipment_types: 'Allowed Shipment Types',
                        allowed_related_companies: 'Allowed Related Companies',
                        fields: {
                            email: 'Email',
                            firstName: 'First Name',
                            lastName: 'Last Name',
                            role: 'Role',
                            companyName: 'Company Name',
                            phoneNumber: 'Phone Number',
                            birthDate: 'Birth Date'
                        },
                        permissions: 'Permissions',
                        claims: {
                            shipment: 'Shipment',
                            edit_state: 'Edit State',
                            po: 'Purchase Order',
                            pd: 'Purchase Demand',
                            add_document: 'Add Documents',
                            edit_document: 'Edit Documents',
                            view_document: 'View Documents',
                            delete_document: 'Delete Documents',
                            add_event: 'Add Events',
                            edit_event: 'Edit Events',
                            view_event: 'View Events',
                            delete_event: 'Delete Events',
                            admin: 'Admin',
                            view: 'Access to Admin',
                            add_shipment: 'Add Shipment',
                            duplicate_shipment: 'Duplicate Shipment',
                            view_pd: 'Purchase Demand Access',
                            edit_pd_item: 'Edit Purchase Demand Item',
                            delete_pd_item: 'Delete Purchase Demand Item',
                            edit_pd_item_cost: 'Edit Purchase Demand Item Cost',
                            add_pd_item: 'Add Purchase Demand Item',
                            add_pd: 'Add Purchase Demand',
                            edit_pd: 'Edit Purchase Demand',
                            delete_pd: 'Delete Purchase Demand',
                            add_approver: 'Add Purchase Demand Approver',
                            delete_approver: 'Delete Purchase Demand Approver'
                        },
                        submit_btn: 'Save',
                        reset_pass: 'Reset Password',
                        delete: 'Delete',
                        restore: 'Restore'
                    }
                },
                header: { title: 'Users Management', text_filter: { placeholder: 'Filter' } },
                grid: {
                    columns: {
                        birthDate: 'Birth Date',
                        companyName: 'Company Name',
                        createdAt: 'Created At',
                        email: 'Email',
                        firstName: 'Full Name',
                        lastName: 'Last Name',
                        phoneNumber: 'Phone Number',
                        role: 'Role',
                        shouldResetPassword: 'Should reset pass'
                    }
                }
            },
            company: {
                backLink: 'Back to Companies page',
                save_btn: 'Save',
                access_key: 'Access Key',
                create_keys: 'Create Keys',
                company_id: 'Company Id',
                api_keys: 'API Keys',
                documentation: 'Documentation',
                keys_modal: {
                    title: 'Your access key and secret key has been created successfully.',
                    info: 'Copy and save your secret key now. If you do not copy it now, you will not be able to retrieve your secret key again.',
                    secret_key: 'Secret Key',
                    access_key: 'Access Key',
                    company_id: 'Company Id'
                },
                name: 'Name',
                vatNumber: 'Vat Number',
                marketplaces: 'Marketplaces',
                address: 'Address',
                phoneNumber: 'Phone number',
                color: 'Color',
                addresses: {
                    grid: {
                        columns: {
                            name: 'Name',
                            country: 'Country',
                            airPort: 'Air Port',
                            seaPort: 'Sea Port',
                            phoneNumber: 'Phone number',
                            city: 'City',
                            address: 'Address',
                            personInChargeName: 'Person in charge',
                            description: 'Description',
                            isDefault: 'Is Default'
                        },
                        filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                        menu: { edit: 'Edit', delete: 'Delete' }
                    },
                    empty_state: 'No addresses right now!',
                    add_address: 'Add address',
                    modal: { add_title: 'Add company address', edit_title: 'Edit company address', add_btn: 'Create', edit_btn: 'Update' },
                    form: {
                        name: 'Name',
                        country: 'Country',
                        airPort: 'Air Port',
                        seaPort: 'Sea Port',
                        city: 'City',
                        address: 'Address',
                        description: 'Description',
                        phoneNumber: 'Phone number',
                        personInCharge: 'Person in charge',
                        isDefault: 'Is Default',
                        required_error: 'Required',
                        phone_error: 'Does not match phone pattern',
                        city_max_length_error: 'Max 200 characters',
                        address_max_length_error: 'Max 20 characters',
                        description_max_length_error: 'Max 200 characters',
                        name_max_length_error: 'Max 20 characters',
                        phone_number_max_length_error: 'Max 20 characters'
                    }
                }
            },
            companies_mgmt: {
                api_connection: {
                    connection_successful: 'Connection Successful!',
                    connect: 'Connect',
                    connected: 'Connected'
                },
                add_edit_company: {
                    add_company_form: {
                        title: 'Create Company',
                        fields: {
                            name: 'Name',
                            type: 'Type',
                            vat_number: 'Vat Number',
                            phone_num: 'Phone Number',
                            address: 'Address',
                            marketplaces: 'Marketplaces'
                        },
                        errors: { max: 'Max', chars: 'characters', required: 'Required', invalid_phone_num: 'Invalid phone number' },
                        submit_btn: 'Submit'
                    },
                    edit_company_form: { title: 'Edit Company', fields: { email: 'Email' } }
                },
                header: { title: 'Companies Management', text_filter: { placeholder: 'Filter' } },
                grid: {
                    columns: {
                        name: 'Name',
                        vatNumber: 'Vat Number',
                        type: 'Type',
                        address: 'Address',
                        phoneNumber: 'Phone Number',
                        createdAt: 'Created At',
                        sellingPartnerId: 'Selling Partner API',
                        adsApiRefreshToken: 'Amazon Advertising API'
                    }
                }
            },
            companies_relations: {
                relations: 'Relations',
                companyName: 'Company Name',
                relatedCompanyName: 'Related Company Name',
                relationType: 'Relation Type',
                filters: {
                    requested: 'Requested',
                    approved: 'Approved',
                    all: 'All'
                },
                relations_empty_state: 'No Relations Found',
                required: 'Required',
                forwarder: 'Forwarder',
                supplier: 'Supplier',
                cargo_owner: 'Cargo Owner',
                send: 'Send',
                requests_empty_state: 'No Requests Found',
                token: 'Token',
                my_token: 'My token',
                new_token: 'New token',
                generate_token: 'Generate token',
                approve: 'Approve',
                waiting_for: 'Waiting for',
                to_approve: 'to approve',
                sent: 'Sent',
                recieved: 'Recieved',
                are_you_sure: 'Are you sure?'
            }
        },
        dashboard: {
            title: '欢迎回来',
            sub_title:
                '感谢使用CargoZone数据工作区。很开心您在使用！这是您的CargoZone工作模块，在这里您可以找到您货物的运输进度和趋势的相关数据和总结清单。',
            summary: {
                title: '工作模块',
                activeShipments: { title: '有效船期' },
                monthArrivals: { title: '当月到岸的船期' },
                missingDocuments: { title: '文件丢失' },
                starredShipments: { title: '跟进船期' },
                charts: {
                    shipmentsByForwarder: '按货代分类船期',
                    shipmentsByType: '按类型分类船期',
                    shipmentsByVendor: '按供应商分类船期',
                    shipmentsByCargoOwner: '按货主分类船期',
                    shipmentsByCarrier: '按承运人分类船期',
                    shipmentsByRoute: '首选路线',
                    shipmentsByCommodity: 'Shipments By Commodity',
                    tooltips: { shipmentsByVendor: '按供应商显示（正在运输的）船期', shipmentsByCarrier: '按承运人显示（正在运输的）船期' }
                },
                warning_alerts: '警告中的警报',
                critical_alerts: '严重警报',
                avg_trans_cost: '运输成本百分比平均值',
                avg_shipment_profit: '平均出货利润',
                avg_transit_time: '平均运输时间',
                inbound_spend: '打开入库支出',
                toolTips: {
                    warning_alerts: '需要注意点/要采取的行动',
                    critical_alerts: '检测到的重大风险/潜在中断的问题',
                    avg_trans_cost: '显示附加在货物价值上的额外成本 (%)（仅限有效船期）',
                    avg_transit_time: '按照有 ETD/ATD & ETA/ATA 的船期分类',
                    inbound_spend: '`显示所有运输的入库总金额 + 货物成本（有效采购订单、装运、间接费用和相关运输成本）`'
                }
            },
            orderOrientedSummary: {
                activeOrders: {
                    title: 'Active Orders'
                },
                exceptions: {
                    title: 'Exceptions'
                },
                warnings: {
                    title: 'Warnings'
                },
                activeOrdersValue: {
                    title: 'Active Orders Value'
                },
                charts: {
                    ordersAmountByMonth: 'Orders By Month',
                    exceptionsByMonth: 'Exceptions By Month',
                    ordersByCarrier: 'Orders By Carrier',
                    ordersByState: 'Orders By State',
                    ordersByCountry: 'Orders By Country'
                }
            },
            my_rfq: {
                title: 'My RFQ',
                tool_tip: 'My Request for Quote',
                grid: {
                    columns: {
                        companyName: 'Company Name',
                        expirationDate: 'Expiration Date',
                        includeInsurance: 'Include Insurance',
                        shipmentTypes: 'Shipment Types',
                        note: 'Note'
                    },
                    filtersForm: {
                        purchaseDemand_type: 'PD Type',
                        from_date: 'From Date',
                        to_date: 'To Date'
                    },
                    emptyState: 'No RFQ yet'
                }
            },
            sent_quotations: {
                title: 'Sent Quotations',
                tool_tip: 'Sent Quotations',
                grid: {
                    columns: {
                        shipmentType: 'Shipment Type',
                        freightPrice: 'Freight Price',
                        validTill: 'Valid Till',
                        note: 'Note',
                        state: 'State'
                    },
                    emptyState: 'No Sent Quotations yet'
                }
            },
            purchase_demands: {
                title: 'Purchase Demands',
                tool_tip: 'Purchase Demands',
                grid: {
                    columns: {
                        CargoZoneNumber: 'PD Number',
                        createdAt: 'Open Date',
                        state: 'State',
                        companyName: 'Company',
                        userName: 'User',
                        quantity: 'Number of items',
                        approvers: 'Approvers'
                    },
                    filtersForm: { purchaseDemand_type: 'PD Type', from_date: 'From Date', to_date: 'To Date' },
                    menu: { delete: 'Delete', export: 'Export', pdf: 'Pdf', excel: 'Excel', confirmMessage: 'Are you sure?' },
                    purchaseDemandWarning: { critical: 'Critical', warning: 'Warning', success: 'All good' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    emptyState: 'No PD yet, to create your first PD, you should click on the new PD button up right.'
                }
            },
            ready_soon_po: {
                title: '马上完货的订单',
                tool_tip: '马上完货的订单',
                grid: {
                    columns: {
                        containerNumber: '柜号',
                        containerType: '柜型',
                        arrivalDate: '到达日',
                        daysFromArrival: '离到达还有几天',
                        state: '状态',
                        id: '-',
                        lastEvent: '上次事件',
                        lastEvent_tooltip: '显示船期最新的详细事件',
                        incoterms: '国贸术语'
                    },
                    filtersForm: {
                        purchaseOrder_type: '订单类型',
                        incoterms: '国贸术语',
                        forworder: '货代',
                        from_date: '从哪一个日期',
                        to_date: '到哪一个日期',
                        origin_country: '原产国',
                        origin_station: '原产州',
                        destination_country: '到达国',
                        destination_station: '到达州'
                    },
                    menu: {
                        openRelatedTalk: '打开相关对话',
                        share: '共享',
                        delete: '删除',
                        follow: '跟进',
                        unfollow: '无跟进',
                        invite_to_follow: '邀请跟进',
                        share_shipment: '共享船期',
                        make_shipment: '制作船期表',
                        export: '导出',
                        pdf: 'PDF',
                        excel: 'EXCEL表格',
                        confirmMessage: '提示您确定要导出吗'
                    },
                    purchaseOrderWarning: { critical: '严重警报', warning: '警报', success: '一切进展顺利' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    emptyState: '现在没有订单'
                }
            },
            upcoming_arrivals: {
                title: '马上到达的船期',
                tool_tip: '显示从今天起后5天内+10天前到达目的地的货物',
                grid: {
                    columns: {
                        CargoZoneNumber: '货物数量',
                        alertStatus: '',
                        alertStatus_tooltip:
                            '“当您需要注意或采取行动以促进装运进程时，风险指示器将是黄色的!”红色(X)，则表示检测到重大风险或潜在中断时。绿色(V)表示目前为止一切顺利。”',
                        typeCode: '类型',
                        createdAt: '开立日期',
                        createdAt_tooltip: '按照船期创立的日期和时间显示',
                        companyName: '公司',
                        forwarderName: '货代',
                        clientRef: '客户参考',
                        clientRef_tooltip: '货主参考数量',
                        forwarderRef: '货代参考',
                        incoterms: '国贸术语',
                        originStation: '原产地',
                        originStation_tooltip: '装柜港口',
                        destinationStation: '目的港',
                        destinationStation_tooltip: '卸货港',
                        shipperName: '托运人',
                        consigneeName: '收件人',
                        userName: '由谁创立',
                        quantity: '数量',
                        quantity_tooltip: '总共装运的单位件数',
                        weight: '重量',
                        carrierCode: '承运人',
                        carrierCode_tooltip: '实体/实际运输服务提供商(航空公司、航运公司、拖车公司等)',
                        containerNumber: '柜号',
                        eta: '交期',
                        commodity: '品名',
                        state: '状态',
                        state_tooltip: '显示货物的当前状态(一般情况)',
                        lastEvent: '上次事件',
                        lastEvent_tooltip: '显示船期最新的详细事件',
                        updatedAt: '上次更新',
                        updatedAt_tooltip: '显示上次更新的日期和时间'
                    },
                    menu: {
                        openRelatedTalk: '打开相关对话',
                        share: '共享',
                        delete: '删除',
                        follow: '跟进',
                        unfollow: '无跟进',
                        invite_to_follow: '邀请跟进',
                        share_shipment: '共享船期',
                        make_shipment: '制作船期表',
                        export: '导出',
                        pdf: 'PDF',
                        excel: 'EXCEL表格',
                        confirmMessage: '提示您确定要导出吗'
                    },
                    shipmentWarning: { error: '错误', warning: '警告', success: '进展顺利' },
                    alert_status: { critical: '严重警报', warning: '警告', success: '进展顺利' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    emptyState: '现在没有船期'
                }
            },
            fcl_containers: {
                title: '整装货柜',
                tool_tip: '显示整装货柜',
                grid: {
                    columns: {
                        containerNumber: 'Container Number',
                        containerType: 'Container Type',
                        arrivalDate: 'Arrival Date',
                        daysFromArrival: 'Days From Arrival',
                        state: 'State',
                        id: '-',
                        lastEvent: 'Last event',
                        lastEvent_tooltip: 'Displays the latest event of the shipment (Detailed)',
                        incoterms: 'Incoterms'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    emptyState: '现在没有货柜'
                }
            },
            ready_soon_shipments: {
                title: '马上完成的船期',
                tool_tip: '',
                grid: {
                    columns: {
                        CargoZoneNumber: '船期数量',
                        alertStatus: '',
                        alertStatus_tooltip:
                            '“当您需要注意或采取行动以促进装运进程时，风险指示器将是黄色的!”红色(X)，则表示检测到重大风险或潜在中断时。绿色(V)表示目前为止一切顺利。”',
                        typeCode: '类型',
                        createdAt: '开立日期',
                        createdAt_tooltip: '按照船期创立的日期和时间显示',
                        companyName: '公司',
                        forwarderName: '货代',
                        clientRef: '客户参考',
                        clientRef_tooltip: '货主参考数量',
                        forwarderRef: '货代参考',
                        incoterms: '国贸术语',
                        originStation: '原产地',
                        originStation_tooltip: '装柜港口',
                        destinationStation: '目的港',
                        destinationStation_tooltip: '卸货港',
                        shipperName: '托运人',
                        consigneeName: '收件人',
                        userName: '由谁创立',
                        quantity: '数量',
                        quantity_tooltip: '总共装运的单位件数',
                        weight: '重量',
                        carrierCode: '承运人',
                        carrierCode_tooltip: '实体/实际运输服务提供商(航空公司、航运公司、拖车公司等)',
                        containerNumber: '柜号',
                        eta: '交期',
                        commodity: '品名',
                        state: '状态',
                        state_tooltip: '显示货物的当前状态(一般情况)',
                        lastEvent: '上次事件',
                        lastEvent_tooltip: '显示船期最新的详细事件',
                        updatedAt: '上次更新',
                        updatedAt_tooltip: '显示上次更新的日期和时间'
                    },
                    menu: {
                        openRelatedTalk: '打开相关对话',
                        share: '共享',
                        delete: '删除',
                        follow: '跟进',
                        unfollow: '无跟进',
                        invite_to_follow: '邀请跟进',
                        share_shipment: '共享船期',
                        make_shipment: '制作船期表',
                        export: '导出',
                        pdf: 'PDF',
                        excel: 'EXCEL表格',
                        confirmMessage: '提示您确定要导出吗'
                    },
                    shipmentWarning: { error: '错误', warning: '警告', success: '进展顺利' },
                    alert_status: { critical: '严重警报', warning: '警告', success: '进展顺利' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    emptyState: '现在没有船期'
                }
            }
        },
        shipments: {
            title: '所有船期',
            filterPlaceHolder: '过滤船期',
            new_shipment: '新船期',
            refresh_btn: 'Refresh',
            export_btn: '导出',
            exportOptions: {
                pdf: 'PDF',
                excel: 'EXCEL表格',
                export_file_name: 'shipments',
                export_excel_modal: { title: 'Export Shipments Excel', cancel: 'Cancel', export: 'Export', all_columns: 'All columns' }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'Shipment Number',
                    alertStatus: ' ',
                    carrierCode: 'Carrier',
                    clientRef: 'Client Ref',
                    commodity: '品名',
                    companyName: 'Company',
                    consigneeName: 'Consignee',
                    containerNumber: 'Container#',
                    createdAt: 'Open Date',
                    destinationStation: 'Destination',
                    destinationCountry: 'Destination Country',
                    eta: '交期',
                    etd: 'ETD',
                    ata: 'ATA',
                    atd: 'ATD',
                    forwarderName: 'Forwarder',
                    forwarderRef: 'Forwarder Ref',
                    goodsReady: 'Goods Ready',
                    incoterms: '国贸术语',
                    lastEvent: 'Last event',
                    originStation: 'Origin',
                    originCountry: 'Origin Country',
                    quantity: '数量',
                    shipperName: 'Shipper',
                    state: 'State',
                    transportationCost: 'Trans. cost',
                    transportationPaid: 'Forwarder Inv. Paid',
                    typeCode: 'Type',
                    updatedAt: 'Last Update',
                    userName: 'Created by',
                    weight: '重量/体积',
                    shipment_number: '船期数量',
                    type: '类型',
                    open_date: '开立日期',
                    client_ref: '客户参考',
                    forwarder_ref: '货代参考',
                    forwarder: '货代',
                    origin: '原产地',
                    destination: '目的港',
                    shipper: '托运人',
                    consignee: '收件人',
                    master_bl: '外箱规格',
                    house_bl: '仓库',
                    container_no: '柜号',
                    carrier: '承运人',
                    customs_file: '报关文件',
                    last_event: '上次事件'
                },
                filtersForm: {
                    shipment_type: '船期类型',
                    incoterms: '国贸术语',
                    forworder: '货代',
                    from_date: '从哪一个日期',
                    to_date: '到哪一个日期',
                    origin_country: '原产国',
                    origin_station: '原产州',
                    destination_country: '目的国',
                    destination_station: '目的州'
                },
                menu: {
                    openRelatedTalk: '打开相关对话',
                    share: '共享',
                    delete: '删除',
                    follow: '跟进',
                    unfollow: '无跟进',
                    invite_to_follow: '邀请跟进',
                    share_shipment: '共享船期',
                    make_shipment: '制作船期表',
                    export: '导出',
                    pdf: 'PDF',
                    excel: 'EXCEL表格',
                    confirmMessage: '提示您确定要导出吗'
                },
                shipmentWarning: { critical: '错误', warning: '警告', success: '进展顺利' },
                alert_status: { critical: '严重警报', warning: '警告', success: '进展顺利' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: '还没有船期，要创建您的第一个船期，您应该向上单击新的货船期按钮'
            },
            form: {
                requiredFieldMessage: '这个字段是必须的',
                back_modal: { shipments_page: '船期也', shipment_details: '船期详情' },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                parties: { modal: { create: '创建', FORWARDER: '货代', BROKER: '中介', SHIPPER: '托运人', CONSIGNEE: '收件人' } },
                sections: {
                    general: {
                        fields: {
                            company: { title: '公司', placeholder: '选择公司' },
                            shipment_type: { title: '船期类型', tooltip: '输入运输模式', placeholder: '选择船期类型' },
                            forwarder: { title: '货代', tooltip: '货代输入框', placeholder: '选择货代' },
                            broker: { title: '中介', tooltip: '中介输入框', placeholder: '选择中介' },
                            shipper: { title: '托运人', tooltip: '在提单/运单，发票和装箱单上提到的出口商是托运人', placeholder: '选择托运人' },
                            consignee: { title: '收件人', tooltip: '接收货物的一方即为收件人', placeholder: '选择收件人' },
                            tracking_number: { title: '运单号', tooltip: '运单号输入框', placeholder: '输入运单号' },
                            estimate_delivery: { title: '估计交货日期', tooltip: '估计交货日期输入框', placeholder: '输入估计交货日期' },
                            incoterms: { title: '国贸术语', tooltip: '装运发票中所述的约定贸易条件', placeholder: '选择类型' },
                            commodity: { title: '品名', tooltip: '货物类型描述' },
                            sub_type: { placeholder: '模式' }
                        },
                        save_broker_same_as_forwarder: '中介等同于货代',
                        shipper_same_as_company: 'I am the shipper',
                        consignee_same_as_company: 'I am the consignee',
                        taxes_duties_at_destination_included: '是否包括目的地税和关税?',
                        yes: '是',
                        no: '否'
                    },
                    geography: {
                        fields: {
                            address: { title: '选择地址', placeholder: '选择地址' },
                            origin_country: { title: '原产国', tooltip: '货件出产国的名称', placeholder: '选择国家' },
                            origin_port: { title: '原产地', tooltip: '装货港', placeholder: '选择港口' },
                            origin_zipcode: { title: '原产城市/邮编', placeholder: '选择邮编' },
                            pick_up_address: { title: '提货地址', placeholder: '原产地址' },
                            consignee_addresses: { title: '选择地址', placeholder: '选择地址' },
                            destination_country: { title: '目的国', tooltip: '货物送到的国家名称', placeholder: '选择国家' },
                            destination_port: { title: '目的港', tooltip: '卸货港', placeholder: '选择港口' },
                            destination_zipcode: { title: '目的城市/邮编', placeholder: '选择邮编' },
                            delivery_address: { title: '发货地址', placeholder: '目的地地址' }
                        }
                    },
                    billing: {
                        fields: {
                            goods_ready: { title: '完货日期', tooltip: '货物预计完成日' },
                            master: { title: '主提单', tooltip: '主承运人在收到货运代理提供的货物后签发的主提单，并按照约定条款在目的地交付货物' },
                            awb: { title: '运单' },
                            carrier: {
                                title: '承运人',
                                tooltip: '公司有权运输货物/物理/实际运输服务提供商(航空公司，航运公司，铁路，拖车公司等。)',
                                placeholder: '选择承运人'
                            },
                            etd: { title: '交期', tooltip: '估计发货时间' },
                            house: { title: '交货提单', tooltip: '货代在收到托运人的货物后签发的同意在目的地交货的提单' },
                            delivery_note: { title: '发货注意点' },
                            vessel_name: { title: '船名', tooltip: '最终确定的船名/航班号' },
                            eta: { title: '交期', tooltip: '预计到底时间' },
                            customs_file: { title: '清关文件', tooltip: '进口海关文件编号/指示' },
                            voyage_number: { title: '船次', tooltip: '最终确定的船名/航班号', placeholder: '输入船次' },
                            goods_value: { title: '货价' },
                            trans_price: { title: '运输价格' },
                            buying_rate: { title: '买入汇率' },
                            selling_rate: { title: '卖出汇率' }
                        },
                        enter_name: '输入名字'
                    },
                    amazon: {
                        fields: {
                            amazon_id: { title: 'Amazon Id' },
                            fba_id: { title: 'FBA Id' },
                            amazon_appointment_date: { title: 'Amazon Appointment Date' }
                        }
                    },
                    packages: {
                        fields: {
                            container_type: { title: '柜型', tooltip: '选择需要的柜型' },
                            container_number: {
                                title: '柜号',
                                tooltip: '由四个字母和七个数字组成的独特的字母数字序列，用于在国际上识别货柜。如提单所述，显示在货柜的右上方的柜号。'
                            },
                            description: { title: '描述' },
                            overweight: { title: '限重' },
                            dimensions: { title: '尺寸' },
                            quantity: { title: '数量' },
                            weight: { title: '重量' },
                            weight_unit: { KG: '千克', LB: '磅' },
                            total_volume: { title: '总容量' },
                            total_weight: { title: '总重量' },
                            volume_unit: { CBM: '体积', CFT: '英尺' },
                            dimensions_unit: { CM: '厘米', IN: '英寸' },
                            hs_code: { title: '海关编码' }
                        },
                        add_container: '新增货柜',
                        add_package: '新增货物'
                    },
                    references: {
                        fields: { reference_no: { title: '参考编号', tooltip: '这里可以是采购订单的编号/合同号' } },
                        add_ref_no: '新增参考编号'
                    }
                },
                route_guard: '是否不保存关闭？',
                next_btn: '下一步',
                previous_btn: '前一步',
                where_do_you_want_to_go: '您想要进行哪一步',
                save_btn: '保存',
                cancel_btn: '取消',
                new_shipment: '新建货期',
                mandatory_fields: '必填字段'
            },
            filter: {
                active_shipments: '有效货期',
                active: '有效货期',
                inactive: '无效',
                state: '状态',
                company: '公司',
                pick_company: '选择公司',
                alert_status: '警告状态',
                all_good: '一切顺利',
                warning: '警告',
                critical: '严重警告',
                shipment_type: '船期类型',
                incoterms: '国贸术语',
                forwarder: '货代',
                open_date: '开立日期',
                from_date: '从哪一个日期',
                to_date: '到哪一个日期',
                eta_from_date: '从哪一个日期开始的交期',
                eta_to_date: '到哪个日期的交期',
                origin_country: '原产国',
                origin_station: '原产州',
                destination_country: '目的国',
                destination_station: '目的州',
                vendor: '供应商',
                carrier: '承运人',
                cancel_btn: '取消',
                reset_btn: '重置',
                filter_btn: '过滤'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload shipments'
            },
            date_from: '日期从',
            date_to: '日期到',
            eta_from: '交期从',
            eta_to: '交期到',
            origin_country: '原产国',
            destination_country: '目的国',
            origin_station: '原产州',
            destination_station: '目的州',
            critical: '严重警告',
            warning: '警告',
            all_good: '一切顺利',
            carrier: '承运人',
            vendor: '供应商',
            active: '有效',
            inactive: '无效',
            all: '全部'
        },
        categories: {
            form: { name: 'Name', company: 'Company', errors: { required: 'Required', max_length: (length: number) => `Max ${length} characters` } },
            modal: { add_btn: 'Add Category', edit_btn: 'Edit Category' }
        },
        products: {
            title: 'All Products',
            filterPlaceHolder: 'Filter products',
            new_product: 'New Product',
            refresh_btn: 'Refresh',
            edit_btn: 'Edit',
            duplicate_btn: 'Duplicate',
            active: 'Active',
            inactive: 'Inactive',
            add_product: 'Add Product',
            grid: {
                columns: {
                    number: 'Number',
                    name: 'Name',
                    sku: 'SKU',
                    description: 'Description',
                    supplierName: 'Supplier',
                    latestProductImgUrl: 'Image',
                    madeIn: 'Made in',
                    marketplace: 'Marketplace',
                    category: 'Category',
                    brand: 'Brand',
                    cost: 'Cost',
                    retailPrice: 'Retail Price',
                    salePrice: 'Sale Price',
                    weight: 'Weight',
                    width: 'Width',
                    length: 'Length',
                    height: 'Height',
                    dimensionUnit: 'Dimension',
                    note: 'Note',
                    companyName: 'Company Name'
                },
                filtersForm: {},
                menu: { delete: 'Delete' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: 'No products yet, to create your first product, you should click on the new product button up right.',
                relatedEmptyState: 'No related products'
            },
            filter: {
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter',
                active_products: 'Active Products',
                brand: 'Brand',
                madeIn: 'Made in',
                supplier: 'Supplier',
                category: 'Category',
                company: 'Company',
                active: 'Active',
                inactive: 'Inactive'
            },
            form: {
                cancel_btn: 'Cancel',
                save_btn: 'Save',
                required_field_message: 'Required',
                max_length_message: (length: number) => `Max ${length} characters`,
                note: 'Note',
                company: 'Company',
                name: 'Name',
                sku: 'SKU',
                description: 'Description',
                supplier: 'Supplier',
                category: 'Category',
                brand: 'Brand',
                cost: 'Cost',
                retailPrice: 'Retail Price',
                salePrice: 'Sale Price',
                weight: 'Weight',
                height: 'H',
                width: 'W',
                length: 'L',
                dimension: 'Dimension',
                masterCartonWeight: 'Master Carton Weight',
                madeIn: 'Made in',
                marketplace: 'Marketplace',
                masterCartonDimension: 'Master Carton Dimension',
                unitsPerMasterCarton: 'Units per carton',
                isActive: 'Is Active',
                productionDays: 'LT Production',
                CM: 'CM',
                IN: 'IN',
                user: 'User',
                asin: 'ASIN',
                fnsku: 'FNSKU',
                rank: 'Rank',
                numberOfRatings: 'Number of ratings',
                fbaFee: 'FBA Fee',
                amazonPercentFee: 'Amazon refferal fee %',
                amazon_title: 'Amazon',
                before_duplicate_msg: 'Before saving duplicate product',
                include_documents: 'Include documents?',
                include_attributes: 'Include attributes?',
                hs_codes: 'HS Codes',
                minOrderQuantity: 'Minimum Order Quantity',
                minOrderQuantityAlert: 'Minimum Order Quantity Alert',
                code: 'Code',
                hs_code: 'HS Code',
                add_hscode: 'Add HS Code'
            },
            upload_form: {
                required: 'Required',
                company_placeholder: 'Choose company',
                select_excel_file: 'Select xls or xlsx file',
                download_template: 'Download Excel Template',
                upload_btn: 'Upload',
                modal_title: 'Upload products'
            },
            history: {
                item_dictionary: {
                    CREATE_PRODUCT: 'create new product',
                    UPDATE_PRODUCT: 'update product',
                    DELETE_PRODUCT: 'delete product',
                    CREATE_DOCUMENT: 'create new document',
                    UPDATE_DOCUMENT: 'update document',
                    DELETE_DOCUMENT: 'delete document',
                    CREATE_ATTRIBUTE: 'create new attribute',
                    UPDATE_ATTRIBUTE: 'update attribute',
                    DELETE_ATTRIBUTE: 'delete attribute',
                    CREATE_ATTRIBUTE_OPTION: 'create new attribute option',
                    UPDATE_ATTRIBUTE_OPTION: 'update attribute option',
                    DELETE_ATTRIBUTE_OPTION: 'delete attribute option',
                    CREATE_RELATED_PRODUCT: 'create new relation',
                    DELETE_RELATED_PRODUCT: 'stop relation',
                    CREATE_LINK: 'create new link',
                    UPDATE_LINK: 'update link',
                    DELETE_LINK: 'delete link'
                }
            },
            documents: {
                grid: {
                    columns: { type: 'Document type', name: 'Name', date: 'Date', added_by: 'Added by', comment: 'Comment' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: { add_title: 'Add product document', edit_title: 'Edit product document', submit_btn: 'Upload' },
                missing_docs: { dismiss_btn: 'Dismiss', missing: 'Missing' },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            attributes: {
                grid: {
                    columns: { name: 'Name', options: 'Options' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: 'No attributes right now!',
                add_attribute: 'Add attribute',
                modal: { add_title: 'Add product attribute', edit_title: 'Edit product attribute', add_btn: 'Create', edit_btn: 'Update' },
                form: { name: 'Name', name_max_length_error: 'Max 100 characters', required_error: 'Required' },
                attributeOptions: {
                    add_options: 'Add options',
                    option: 'option',
                    options: 'options',
                    grid: {
                        columns: {
                            name: 'Name',
                            skuAdjustment: 'SKU',
                            costAdjustment: 'Cost',
                            priceAdjustment: 'Price',
                            weightAdjustment: 'Weight',
                            dimensionAdjustmentUnit: 'Dimension',
                            displayOrder: 'Display order',
                            isDefault: 'Is Default'
                        },
                        filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                        menu: { edit: 'Edit', delete: 'Delete' }
                    },
                    empty_state: 'No options right now!',
                    add_attribute: 'Add option',
                    modal: {
                        title: 'Attribute options',
                        add_title: 'Add attribute option',
                        edit_title: 'Edit attribute option',
                        add_btn: 'Create',
                        edit_btn: 'Update'
                    },
                    form: {
                        name: 'Name',
                        sku: 'SKU Adjustment',
                        cost: 'Cost Adjustment',
                        price: 'Price Adjustment',
                        weight: 'Weight Adjustment',
                        height: 'H',
                        width: 'W',
                        length: 'L',
                        dimension: 'Dimension Adjustment',
                        displayOrder: 'Display order',
                        isDefault: 'Is Default',
                        CM: 'CM',
                        IN: 'IN',
                        KG: 'KG',
                        LB: 'LB',
                        required_field_message: 'Required',
                        max_length_message: (length: number) => `Max ${length} characters`
                    }
                }
            },
            links: {
                grid: {
                    columns: { title: 'Title', url: 'Url' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: 'No links right now!',
                add_link: 'Add link',
                modal: { add_title: 'Add product link', edit_title: 'Edit product link', add_btn: 'Create', edit_btn: 'Update' },
                form: {
                    title: 'Title',
                    title_max_length_error: 'Max 20 characters',
                    url: 'Url',
                    url_max_length_error: 'Max 250 characters',
                    required_error: 'Required'
                }
            },
            related_products: { add_btn: 'Add relation' }
        },
        internalFlowInventory: {
            title: 'All Inventory',
            refresh_btn: 'Refresh',
            filterPlaceHolder: 'Filter inventory',
            grid: {
                columns: {
                    name: 'Name',
                    asin: 'ASIN',
                    sku: 'SKU',
                    fnsku: 'FNSKU',
                    reserved: 'Reserved',
                    inbound: 'Inbound',
                    outbound: 'Outbound',
                    pdReservedTotal: 'PD',
                    poInboundTotal: 'PO',
                    shipmentInboundTotal: 'Shipment',
                    productImgUrl: 'Image',
                    onHand: 'On Hand',
                    amazonInboundTotal: 'Inbound',
                    internalFlow: 'Internal Flow',
                    amazon: 'Amazon',
                    manual: 'Manual',
                    amazonOnHandTotal: 'On Hand',
                    amazonReservedTotal: 'Resrved',
                    manualOnHand: 'On Hand',
                    manualInbound: 'Inbound',
                    manualReserved: 'Resrved'
                },
                filtersForm: {},
                menu: {
                    delete: 'Delete'
                },
                filter: {
                    noResults: 'No results found for your search : (',
                    resetBtn: 'Reset Filter',
                    noData: 'No data'
                },
                emptyState: 'No internal flow inventory yet.'
            }
        },
        shipment: {
            backLink: '回到船期页',
            lastUpdate: '上次更新',
            edit: '编辑',
            duplicate: '复制',
            newBid: '新建货期',
            shipment: '货期',
            client_ref: '客户参考',
            forwarder_ref: '货代参考',
            last_event: '上次事件',
            eta: '交期',
            tracking_number: '运单号',
            estimate_delivery: '预计交货期',
            master: '主提单',
            awb: '运单',
            house: '交货提单',
            delivery_note: '发货注意点',
            etd: '预计发货期',
            atd: '实际发货期',
            ata: '实际到达期',
            commodity: '品名',
            customs_file: '清关文件',
            amazon_id: 'Amazon Id',
            fba_id: 'FBA Id',
            amazon_appointment_date: 'Amazon Appointment Date',
            goods_ready: '完货期',
            forwarder: '货代',
            broker: '中介',
            company: '公司',
            voyage_number: '船次',
            vessel: '船名',
            commodity_type: '货物类型',
            container_no: '柜号',
            containers: 'Containers',
            packages_field: 'Packages',
            not_available: 'N/A',
            is_overweight: '是否超重',
            carrier: '承运人',
            port_terminal: '港口码头',
            incoterms: '国贸术语',
            quantity: '数量',
            see_more: '查看更多',
            volume: '容量',
            weight: '重量',
            chargeable_weight: '收费重量',
            goods_value: '货价',
            trans_price: '运输价格',
            buying_rate: '买入汇率',
            selling_rate: '卖出汇率',
            visit_site: '访问站点',
            save_btn: '保存',
            enter_name: '输入名字',
            pick_type: '选择类型',
            pick_carrier: '选择承运人',
            total_volume: '总容量',
            total_weight: '总重量',
            transportation_cost_percent: '运输成本百分比',
            transportation_paid: 'Forwarder Inv. Paid',
            total_profit: '总利润',
            estimated_items_volume: 'Estimated Items Volume',
            estimated_items_weight: 'Estimated Items Weight',
            total_cartons: 'Total Cartons',
            are_you_sure: '您确定吗',
            dismiss: '取消',
            shipment_state: '船期状态',
            you_are_moving_the_shipment_state_to: '您正在将装运状态移至',
            inactive: 'Inactive',
            now: '现在',
            next: '下次',
            forwarder_modal: { title: '哦，您得首先选择货代', forwarder_placeholder: '选择货代', set_btn: '设定' },
            paid_mail_modal: {
                dialog_text: 'Do you want to let the forwarder know the shipment has been paid?',
                title: 'Who to send?',
                contacts_placeholder: 'Pick contacts',
                send_btn: 'Send'
            },
            overview: {
                tooltips: {
                    transportationPrice: '为了达到最高准确性，键入一个包括国际和当地运输、保险、存储成本、海关放行费用和税收(不包括增值税)的值。',
                    transportationPrecent: '表示产品成本中额外成本的总百分比'
                }
            },
            alert_status: { critical: '严重警告', warning: '警告', success: '一切顺利' },
            items: {
                po_ref: 'PO Ref',
                transportation_cost_per_unit: 'Transportation Cost Per Unit',
                quantity: 'Quantity',
                sku: 'SKU',
                code: 'Code',
                name: 'Name',
                product_name: 'Name',
                price: 'Cost',
                currency: 'Currency',
                dimensions: 'Dimensions',
                weight: 'Weight',
                total_cost: 'Total Cost',
                attributes: 'Attributes',
                ready_date: 'Ready Date',
                pd_ref: 'PD Ref',
                cartons: 'Cartons',
                images: 'Images',
                remaining_quantity: 'Remaining Quantity',
                shipments_refs: 'Shipments Refs',
                qc_approved: 'Qc Approved',
                shipments: 'Shipments',
                amazon_reference_id: 'Amazon Reference Id',
                amazon_shipment_id: 'Amazon Shipment Id'
            },
            parties: {
                empty_state: '现在没有当事人',
                add_party: '新增',
                modal: { add_title: '新增船期当事人', edit_title: '编辑船期当事人', add_btn: '新增', edit_btn: '更新', cancel_btn: '取消' },
                form: {
                    partycompany_placeholder: '选择公司',
                    partytype_placeholder: '选择当事人类型',
                    partysubtype_placeholder: '选择辅当事人类型',
                    companyaddresses_placeholder: '选择地址',
                    companycontacts_placeholder: '选择联系人',
                    partyname_placeholder: '名称',
                    partycountry_placeholder: '国家',
                    partycity_placeholder: '城市',
                    partyaddress_placeholder: '地址',
                    partyphone_placeholder: '手机号',
                    partyemail_placeholder: '邮箱',
                    remarks_placeholder: 'Remarks',
                    partycontact_name_placeholder: '联系人名称',
                    partyvat_number_placeholder: '税号',
                    partywebsite_placeholder: '网址',
                    payment_terms: '付款条款',
                    payment_terms_remarks: '付款条款评论',
                    taxes_duties: '税收和关税',
                    monthly_intrest: '月利息',
                    credit_line: '信用额度',
                    credit_line_currency: '信用额度币种',
                    required_error: '输入',
                    phone_error: '不符合电话模式',
                    email_error: '不符合电子邮件模式',
                    create_company_party: '创建商业伙伴',
                    address_max_length_error: '最多250个字符',
                    phone_max_length_error: '最多40个数字',
                    email_max_length_error: '最多100个字符',
                    website_max_length_error: '最多50个字符',
                    payment_terms_max_length_error: '最多25个字符',
                    payment_terms_remarks_max_length_error: '最多150个字符',
                    vat_number_max_length_error: '最多20个字符',
                    vat_number_error: '这个税号已经存在',
                    name_max_length_error: '最多100个字符'
                },
                menu: { edit: '编辑', delete: '删除' }
            },
            events: {
                grid: {
                    columns: {
                        id: 'ID号',
                        eventCode: '编码',
                        name: '事件',
                        description: '描述',
                        userName: '通过以下哪种插入',
                        eventDate: '事件日期',
                        createdAt: '创建日期',
                        comment: '评论',
                        displayOnlyForCompanyId: '',
                        displayOnlyForCompanyIdTooltip: 'This event is visible only to your company',
                        expirationDate: 'Expiration Date'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有事件',
                add_event: '新增事件',
                updated_by: '更新',
                modal: { add_title: '新增船期事件', edit_title: '编辑船期事件', add_btn: '新增事件', edit_btn: '更新事件', cancel_btn: '取消' },
                form: {
                    eventtype: '事件类型',
                    eventtype_placeholder: '选择事件类型',
                    comment: '评论',
                    comments_placeholder: '在这里新增评论',
                    required_error: '输入',
                    comment_max_length_error: '最多255个字符'
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: '文件类型',
                        name: '名称',
                        date: '日期',
                        added_by: '新增',
                        comment: '评论',
                        displayOnlyForCompanyId: ' ',
                        displayOnlyForCompanyIdTooltip: 'This document is visible only to your company',
                        expirationDate: 'Expiration Date'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有文件',
                add_document: '新增文件',
                modal: { add_title: '新增货期文件', edit_title: '编辑货期文件', submit_btn: '上传' },
                missing_docs: { dismiss_btn: '取消', missing: '丢失' },
                form: {
                    documenttype: '文件类型',
                    documenttype_placeholder: '选择文件类型',
                    comment: '评论',
                    comment_placeholder: '在这里新增评论',
                    addfile_placeholder: '在这里新增文件',
                    required_error: '输入'
                }
            },
            quotations: {
                grid: {
                    columns: {
                        forwarderName: '货代名称',
                        freightPrice: '总运费（USD)',
                        transitTime: '运输时间',
                        freeDays: '开放时间',
                        validTill: '有效期到',
                        note: '注意点'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                },
                pane: {
                    no_quotation: '没有报价',
                    total_price: '总价格',
                    transit_time: '运输时间',
                    valid_untill: '有效期截止到',
                    status: '状态',
                    attached_file: '插入文本',
                    inform_not_approved: 'Do you want to send email to forwarders that their quote did not approve?',
                    no_file: '没有文本',
                    note: 'Note',
                    confirm_btn: 'Confirm this offer'
                },
                empty_state: '现在没有报价'
            },
            bid: {
                empty_state: '未找到报价单',
                create_btn: '新建报价单',
                forwarder_exist: '已经有货代，无法创建报价单',
                state_is_not_draft: '在货期状态不是草稿时无法创建报价单',
                edit_btn: '编辑',
                publish_btn: '发布',
                view_btn: '查看',
                draft: '草稿',
                bid: '报价单',
                selected_offer: '选择提供人',
                quotations: {
                    grid: {
                        columns: {
                            forwarder: '货代',
                            shipment: '船期',
                            transit_time: '运输时间',
                            total_price: '总价格',
                            valid_till: '有效期到',
                            price_per_unit: 'Price Per Volume Unit',
                            status: '状态',
                            notes: '注意点'
                        },
                        filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                    },
                    empty_state: '现在没有报价'
                },
                form: {
                    back_modal_title: '您现在想进行哪一步',
                    forwarder_is_required: '输入货代',
                    contact_is_required: '输入联系人',
                    shipment_types: '船期类型',
                    pick_types: '选择类型',
                    note: '注意点',
                    save_as_draft: '保存为草稿',
                    save: 'Save',
                    publish: '发布',
                    expirationdate: 'Expiration date',
                    includeInsurance: 'Include Insurance',
                    required_field_message: 'Required',
                    documents: { upload_documents: '上传文件', add_documents: '新增文件' },
                    parties: {
                        choose_forwarders: '选择货代',
                        pick_forwarder: '选择货代',
                        add: '新增文件',
                        pick_contact: '选择联系人',
                        add_contact: '新增联系人',
                        send_email_again: '再次发送邮件',
                        add_forwarder: '新增货代',
                        name: '名称',
                        email: '邮箱',
                        cancel: 'Cancel'
                    }
                }
            },
            packages: {
                modal_title: '包裹',
                grid: {
                    columns: {
                        type: '类型',
                        quantity: '数量',
                        description: '描述',
                        containerNumber: '柜号',
                        containerType: '柜型',
                        isOverweight: '是否超重',
                        dimensions: '尺寸',
                        weight: '重量',
                        volume: '容量',
                        hsCode: '海关编码'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                },
                empty_state: '现在没有包裹'
            },
            routes: {
                modal_title: '路线',
                grid: {
                    columns: { origin_station: '原产地', destination_station: '目的地', departure: '离开', arrival: '到底', flight_number: '航班号' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                },
                empty_state: '现在没有路线'
            },
            discussions: {
                header: '讨论',
                item: { placeholder: '添加一个评论。使用@来提及一个人，或使用#来链接一个实体。', menu: { edit: '编辑', delete: '删除' } }
            },
            history: {
                item_dictionary: {
                    CREATE_SHIPMENT: '创建新的船期',
                    CREATE_FOLLOWERS: '开始跟进船期',
                    CREATE_DOCUMENTS: '创建新的文件',
                    CREATE_EVENTS: '创建新的事件',
                    CREATE_EVENT: '创建新的事件',
                    CREATE_PARTIES: '创建新的当事人',
                    CREATE_PARTY: '创建新的当事人',
                    CREATE_PACKAGE: '创建新的包裹',
                    CREATE_CONTAINER: 'create new container',
                    CREATE_REFERENCES: '创建新的参考',
                    CREATE_REFERENCE: '创建新的参考',
                    'CREATE_UPLOAD-MULTIPLE': '上传文档',
                    CREATE_SHIPMENTS: '创建新的船期',
                    CREATE_DISCUSSIONS: '创建新的讨论',
                    CREATE_UPLOAD: '创建新的上次',
                    CREATE_BID: 'create new bid',
                    CREATE_QUOTATION: 'create new quotation',
                    CREATE_ITEM: 'create new item',
                    UPDATE_SHIPMENT: '更新船期',
                    UPDATE_EVENTS: '更新事件',
                    UPDATE_EVENT: '更新事件',
                    UPDATE_DOCUMENTS: '更新文件',
                    UPDATE_PARTIES: '更新当事人',
                    UPDATE_PARTY: '更新当事人',
                    UPDATE_ITEM: 'update item',

                    'UPDATE_UPLOAD-MULTIPLE': '更新文档',
                    UPDATE_PACKAGE: '更新包裹',
                    UPDATE_CONTAINER: 'update container',
                    UPDATE_SHIPMENTS: '更新船期',
                    UPDATE_DISCUSSIONS: '更新船期',
                    UPDATE_REFERENCE: '更新参考',
                    UPDATE_UPLOAD: '更新船期',
                    UPDATE_STATE: '更新船期状态',
                    UPDATE_BID: 'update shipment bid',
                    DELETE_SHIPMENT: '删除船期',
                    DELETE_FOLLOWERS: '无跟进船期',
                    DELETE_EVENTS: '删除事件',
                    DELETE_DOCUMENTS: '删除文件',
                    DELETE_ALERT: '已经取消警告',
                    DELETE_PARTIES: '删除当事人',
                    'DELETE_UPLOAD-MULTIPLE': '删除文档',
                    DELETE_SHIPMENTS: '删除船期',
                    DELETE_DISCUSSIONS: '删除讨论',
                    DELETE_UPLOAD: '删除上传'
                }
            },
            references: {
                modal: { add: '新增', edit: '编辑' },
                types: { CLIENT: '客户参考', FORWARDER: '货代参考' },
                form: {
                    maxLength: (length: number) => `Max ${length} characters`,
                    value: '货值',
                    cancel_btn: '取消',
                    delete_btn: '删除',
                    save_btn: '保存'
                }
            },
            exceptions: {
                '401': { title: 'Access denied', sub_title: 'Sorry! You have no permission to see this shipment details.' },
                back_btn: '返回船期页'
            },
            pane: {
                shipmentDetails: '船期详情',
                ref: '参考',
                clientRef: '客户参考',
                forwarderRef: '货代参考',
                trackingNumber: '运单号',
                commodity: '品名',
                lastEvent: '上次事件',
                nextEvent: '预计下次事件',
                currentState: '当前状态',
                nextState: '下次状态',
                etd: '交期',
                etdTooltip: '预计离港日',
                eta: '交期',
                etaTooltip: '预计到港日',
                chargeableWeight: '计费重量',
                incoterms: '国贸术语',
                quantity: '数量',
                carrier: '承运人',
                master: '主提单',
                masterTooltip: '主提单',
                house: '交货提单',
                houseTooltip: '交货提单',
                packages: {
                    modal_title: '包裹',
                    see_more: '查看更多',
                    grid: {
                        columns: {
                            type: '类型',
                            quantity: '数量',
                            description: '描述',
                            containerNumber: '柜号',
                            containerType: '柜型',
                            isOverweight: '超重',
                            dimensions: '尺寸',
                            weight: '重量',
                            volume: '容量',
                            hsCode: '海关编码'
                        },
                        filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                    },
                    empty_state: '现在没有包裹'
                },
                parties: { title: '当事人' }
            },
            board: {
                filter: { noResults: '按照您的搜索没有找到结果', resetBtn: '重置过滤', noData: '无数据' },
                board_page: {
                    you_are_moving_message: '您正在将装运状态移至',
                    you_are_moving_to_bid_with_forwarder:
                        'You are moving to bid and you have forwarder , we will reset the forwarder and let you create new bid!',
                    are_you_sure: '您确定吗'
                }
            },
            'exceptions[401]': { title: '禁止进入' }
        },
        order_confirmation: {
            ask_confirm_title: 'Do you want to confirm the order?',
            confirmed_title: 'You have confirmed this order',
            rejected_title: 'You have rejected this order',
            confirm: 'Confirm',
            reject: 'Reject'
        },
        orders: {
            title: 'Orders',
            addOrder: 'Add Order',
            emptyState: 'No Orders Yet',
            exceptions: {
                '401': {
                    title: 'Access denied',
                    sub_title: 'Sorry! You have no permission to see this order details.'
                },
                back_btn: 'Go to orders page'
            },
            filtersForm: {
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter'
            },
            overview: {
                order: 'Order',
                orderNumber: 'Order Number',
                company: 'Company',
                saveBtn: 'Save',
                parties: {
                    CUSTOMER: 'Ship To',
                    PICKUP: 'Ship From',
                    PAYER: 'Bill To'
                }
            },
            alerts: {
                status: {
                    critical: 'Critical',
                    warning: 'Warning',
                    success: 'All good'
                }
            },
            state: {
                NEW: 'New',
                READY_TO_SHIP: 'Ready To Ship',
                EXCEPTION: 'Exception',
                EXCEPTION_HANDLING: 'Exception Handling',
                PICKED_UP: 'Picked Up',
                IN_TRANSIT: 'In Transit',
                DELIVERED: 'Delivered',
                CANCELED: 'Canceled'
            },
            fields: {
                id: {
                    title: 'Id',
                    tooltip: '',
                    placeholder: 'Id'
                },
                orderNumber: {
                    title: 'Order Number',
                    tooltip: '',
                    placeholder: 'Order Number'
                },
                orderDateFrom: {
                    title: 'Order Date From',
                    tooltip: '',
                    placeholder: 'Order Date From'
                },
                orderDateTo: {
                    title: 'Order Date To',
                    tooltip: '',
                    placeholder: 'Order Date To'
                },
                alertStatus: {
                    title: 'Alert Status',
                    tooltip: '',
                    placeholder: 'Alert Status'
                },
                state: {
                    title: 'State',
                    tooltip: '',
                    placeholder: 'State'
                },
                userId: {
                    title: 'User',
                    tooltip: '',
                    placeholder: 'User'
                },
                userName: {
                    title: 'User',
                    tooltip: '',
                    placeholder: 'User'
                },
                companyId: {
                    title: 'Company',
                    tooltip: '',
                    placeholder: 'Company'
                },
                customerId: {
                    title: 'Customer',
                    tooltip: '',
                    placeholder: 'Customer'
                },
                pickupId: {
                    title: 'Pick Up From',
                    tooltip: '',
                    placeholder: 'Pick Up From'
                },
                payerId: {
                    title: 'Bill To',
                    tooltip: '',
                    placeholder: 'Bill To'
                },
                payerSameAsCustomer: {
                    title: 'Same As Customer',
                    tooltip: '',
                    placeholder: 'Same As Customer'
                },
                customerCountry: {
                    title: 'Customer Country',
                    tooltip: '',
                    placeholder: 'Customer Country'
                },
                requiredCustomsClearance: {
                    title: 'Required Customs Clearance',
                    tooltip: '',
                    placeholder: 'Required Customs Clearance'
                },
                companyName: {
                    title: 'Company',
                    tooltip: '',
                    placeholder: 'Company'
                },
                totalItems: {
                    title: 'Total Items',
                    tooltip: '',
                    placeholder: 'Total Items'
                },
                productsName: {
                    title: 'Products',
                    tooltip: '',
                    placeholder: 'Products'
                },
                orderDate: {
                    title: 'Order Date',
                    tooltip: '',
                    placeholder: 'Order Date'
                },
                dueDate: {
                    title: 'Due Date',
                    tooltip: '',
                    placeholder: 'Due Date'
                },
                poDate: {
                    title: 'Po Date',
                    tooltip: '',
                    placeholder: 'Po Date'
                },
                poNumber: {
                    title: 'Po Number',
                    tooltip: '',
                    placeholder: 'Po Number'
                },
                soNumber: {
                    title: 'Order Number',
                    tooltip: '',
                    placeholder: 'Order Number'
                },
                eta: {
                    title: 'ETA',
                    tooltip: '',
                    placeholder: 'ETA'
                },
                lastEvent: {
                    title: 'Last Event',
                    tooltip: '',
                    placeholder: 'Last Event'
                },
                invoiceNumber: {
                    title: 'Invoice Number',
                    tooltip: '',
                    placeholder: 'Invoice Number'
                },
                incoterms: {
                    title: 'Incoterms',
                    tooltip: '',
                    placeholder: 'Incoterms'
                },
                trackingNumber: {
                    title: 'Tracking Number',
                    tooltip: '',
                    placeholder: 'Tracking Number'
                },
                carrier: {
                    title: 'Carrier',
                    tooltip: '',
                    placeholder: 'Carrier'
                },
                weight: {
                    title: 'Weight',
                    tooltip: '',
                    placeholder: 'Weight'
                },
                weightUnit: {
                    title: 'Weight Unit',
                    tooltip: '',
                    placeholder: 'Weight Unit'
                },
                volume: {
                    title: 'Volume',
                    tooltip: '',
                    placeholder: 'Volume'
                },
                volumeUnit: {
                    title: 'Volume Unit',
                    tooltip: '',
                    placeholder: 'Volume Unit'
                },
                estimatedItemsVolume: {
                    title: 'Estimated Items Volume',
                    tooltip: '',
                    placeholder: 'Estimated Items Volume'
                },
                estimatedItemsWeight: {
                    title: 'Estimated Items Weight',
                    tooltip: '',
                    placeholder: 'Estimated Items Weight'
                },
                itemsTotalQuantity: {
                    title: 'Total Items',
                    tooltip: '',
                    placeholder: 'Total Items'
                },
                itemsTotalCost: {
                    title: 'Total Price',
                    tooltip: '',
                    placeholder: 'Total Price'
                },
                paymentTerms: {
                    title: 'Payment Terms',
                    tooltip: '',
                    placeholder: 'Payment Terms'
                },
                shippingInstructions: {
                    title: 'Shipping Instructions',
                    tooltip: '',
                    placeholder: 'Shipping Instructions'
                },
                createdAt: {
                    title: 'Created At',
                    tooltip: '',
                    placeholder: 'Created At'
                },
                updatedAt: {
                    title: 'Last Update',
                    tooltip: '',
                    placeholder: 'Last Update'
                },
                deletedAt: {
                    title: 'Deleted At',
                    tooltip: '',
                    placeholder: 'Deleted At'
                },
                party: {
                    contactId: {
                        title: 'Contact',
                        tooltip: '',
                        placeholder: 'Contact'
                    },
                    name: {
                        title: 'Name',
                        tooltip: '',
                        placeholder: 'Name'
                    },
                    vatNumber: {
                        title: 'Vat Number',
                        tooltip: '',
                        placeholder: 'Vat Number'
                    },
                    address: {
                        title: 'Address',
                        tooltip: '',
                        placeholder: 'Address'
                    },
                    contactName: {
                        title: 'Contact Name',
                        tooltip: '',
                        placeholder: 'Contact Name'
                    },
                    phoneNumber: {
                        title: 'Phone Number',
                        tooltip: '',
                        placeholder: 'Phone Number'
                    },
                    email: {
                        title: 'Email',
                        tooltip: '',
                        placeholder: 'Email'
                    },
                    country: {
                        title: 'Country',
                        tooltip: '',
                        placeholder: 'Country'
                    },
                    city: {
                        title: 'City',
                        tooltip: '',
                        placeholder: 'City'
                    }
                },
                item: {
                    orderId: {
                        title: 'Order Id',
                        tooltip: '',
                        placeholder: 'Order Id'
                    },
                    productId: {
                        title: 'Product Id',
                        tooltip: '',
                        placeholder: 'Product Id'
                    },
                    name: {
                        title: 'Name',
                        tooltip: '',
                        placeholder: 'Name'
                    },
                    productSku: {
                        title: 'SKU',
                        tooltip: '',
                        placeholder: 'SKU'
                    },
                    partNumber: {
                        title: 'Part Number',
                        tooltip: '',
                        placeholder: 'Part Number'
                    },
                    serialNumber: {
                        title: 'Serial Number',
                        tooltip: '',
                        placeholder: 'Serial Number'
                    },
                    quantity: {
                        title: 'Quantity',
                        tooltip: '',
                        placeholder: 'Quantity'
                    },
                    length: {
                        title: 'Length',
                        tooltip: '',
                        placeholder: 'Length'
                    },
                    height: {
                        title: 'Height',
                        tooltip: '',
                        placeholder: 'Height'
                    },
                    width: {
                        title: 'Width',
                        tooltip: '',
                        placeholder: 'Width'
                    },
                    dimensionsUnit: {
                        title: 'Dimensions Unit',
                        tooltip: '',
                        placeholder: 'Dimensions Unit'
                    },
                    weight: {
                        title: 'Weight',
                        tooltip: '',
                        placeholder: 'Weight'
                    },
                    weightUnit: {
                        title: 'Weight Unit',
                        tooltip: '',
                        placeholder: 'Weight Unit'
                    },
                    price: {
                        title: 'Price',
                        tooltip: '',
                        placeholder: 'Price'
                    },
                    currency: {
                        title: 'Currency',
                        tooltip: '',
                        placeholder: 'Currency'
                    },
                    attributes: {
                        title: 'Attributes',
                        tooltip: '',
                        placeholder: 'Attributes'
                    },
                    hsCodes: {
                        title: 'Hs Codes',
                        tooltip: '',
                        placeholder: 'Hs Codes'
                    }
                }
            },
            documents: {
                grid: {
                    columns: {
                        type: 'Document type',
                        name: 'Name',
                        date: 'Date',
                        added_by: 'Added by',
                        comment: 'Comment'
                    },
                    filter: {
                        noResults: 'No results found for your search : (',
                        resetBtn: 'Reset Filter',
                        noData: 'No data'
                    },
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                },
                empty_state: 'No documents right now!',
                add_document: 'Add document',
                modal: {
                    add_title: 'Add product document',
                    edit_title: 'Edit product document',
                    submit_btn: 'Upload'
                },
                missing_docs: {
                    dismiss_btn: 'Dismiss',
                    missing: 'Missing'
                },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            },
            events: {
                empty_state: 'No events right now!',
                add_event: 'Add event',
                updated_by: 'updated by',
                modal: {
                    add_title: 'Add order event',
                    edit_title: 'Edit order event',
                    add_btn: 'Add event',
                    edit_btn: 'Update event',
                    cancel_btn: 'Cancel'
                },
                form: {
                    eventtype: 'Event type',
                    eventtype_placeholder: 'Choose event type',
                    comment: 'Comment',
                    comments_placeholder: 'Add a comment here...',
                    required_error: 'Required',
                    comment_max_length_error: 'Max 255 characters'
                }
            },
            discussions: {
                header: 'Public Discussions',
                item: {
                    placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.',
                    menu: {
                        edit: 'Edit',
                        delete: 'Delete'
                    }
                }
            },
            history: {
                item_dictionary: {
                    CREATE_ORDER: 'Create Order',
                    UPDATE_ORDER: 'Update Order',
                    DELETE_ORDER: 'Delete Order',

                    CREATE_ITEM: 'Create Item',
                    UPDATE_ITEM: 'Update Item',
                    DELETE_ITEM: 'Delete Item',

                    CREATE_PARTY: 'Create Party',
                    UPDATE_PARTY: 'Update Party',
                    DELETE_PARTY: 'Delete Party',

                    CREATE_EVENT: 'Create Event',
                    UPDATE_EVENT: 'Update Event',
                    DELETE_EVENT: 'Delete Event',

                    CREATE_DOCUMENT: 'Create Document',
                    UPDATE_DOCUMENT: 'Update Document',
                    DELETE_DOCUMENT: 'Delete Document'
                }
            }
        },
        tariffs: {
            newTariff: 'New Tariff',
            title: 'Tariffs'
        },
        tariff: {
            saveBtn: 'Save',
            overview: {
                tariff: 'Tariff'
            },
            form: {
                errors: {
                    required: 'Required'
                },
                fields: {
                    companyId: {
                        title: 'Company',
                        tooltip: '',
                        placeholder: 'Company'
                    },
                    businessPartnerId: {
                        title: 'Customer',
                        tooltip: '',
                        placeholder: 'Customer'
                    },
                    shipmentTypeCode: {
                        title: 'Shipment Type',
                        tooltip: '',
                        placeholder: 'Shipment Type'
                    },
                    shipmentSubTypeCode: {
                        title: 'Shipment Sub Type',
                        tooltip: '',
                        placeholder: 'Shipment Sub Type'
                    },
                    incoterms: {
                        title: 'Incoterms',
                        tooltip: '',
                        placeholder: 'Incoterms'
                    },
                    carrier: {
                        title: 'Carrier',
                        tooltip: '',
                        placeholder: 'Carrier'
                    },
                    transitTime: {
                        title: 'Transit Time',
                        tooltip: '',
                        placeholder: 'Transit Time'
                    },
                    originCountry: {
                        title: 'Origin Country',
                        tooltip: '',
                        placeholder: 'Origin Country'
                    },
                    originStation: {
                        title: 'Origin Station',
                        tooltip: '',
                        placeholder: 'Origin Station'
                    },
                    destinationCountry: {
                        title: 'Destination Country',
                        tooltip: '',
                        placeholder: 'Destination Country'
                    },
                    destinationStation: {
                        title: 'Destination Station',
                        tooltip: '',
                        placeholder: 'Destination Station'
                    },
                    forwarderTariffNumber: {
                        title: 'Forwarder Tariff Number',
                        tooltip: '',
                        placeholder: 'Forwarder Tariff Number'
                    },
                    note: {
                        title: 'Note',
                        tooltip: '',
                        placeholder: 'Note'
                    },
                    activationDate: {
                        title: 'Activation Date',
                        tooltip: '',
                        placeholder: 'Activation Date'
                    },
                    expirationDate: {
                        title: 'Expiration Date',
                        tooltip: '',
                        placeholder: 'Expiration Date'
                    }
                }
            },
            charges: {
                addCharge: 'Add Charge',
                fields: {
                    chargeCode: {
                        title: 'Charge Code',
                        tooltip: '',
                        placeholder: 'Charge Code'
                    },
                    description: {
                        title: 'Description',
                        tooltip: '',
                        placeholder: 'Description'
                    },
                    chargeType: {
                        title: 'Charge Type',
                        tooltip: '',
                        placeholder: 'Charge Type'
                    },
                    minimum: {
                        title: 'Minimum',
                        tooltip: '',
                        placeholder: 'Minimum'
                    },
                    unitType: {
                        title: 'Unit Type',
                        tooltip: '',
                        placeholder: 'Unit Type'
                    },
                    calcType: {
                        title: 'Calc Type',
                        tooltip: '',
                        placeholder: 'Calc Type'
                    },
                    chargeCalcData: {
                        title: 'Charge Calc Data',
                        tooltip: '',
                        placeholder: 'Charge Calc Data'
                    },
                    price: {
                        title: 'Price',
                        tooltip: '',
                        placeholder: 'Price'
                    },
                    currency: {
                        title: 'Currency',
                        tooltip: '',
                        placeholder: 'Currency'
                    },
                    value: {
                        title: 'Value',
                        tooltip: '',
                        placeholder: 'Value'
                    },
                    percent: {
                        title: 'Percent',
                        tooltip: '',
                        placeholder: 'Percent'
                    },
                    createdAt: {
                        title: 'Created At',
                        tooltip: '',
                        placeholder: 'Created At'
                    }
                }
            }
        },
        share_shipment_modal: {
            errors: { email: '不是一个有效邮箱', required: '输入' },
            placeholders: { emails: '共享给', content: '将您的信息加在这里' },
            files: '文档',
            share: '共享'
        },
        inviteToFollowModal: {
            errors: { required: '输入', maxLengthMessage: (chars: string) => `Max ${chars} characters` },
            placeholders: { users: '邀请用户', message: '将您的信息加在这里' },
            invite: '邀请'
        },
        calendar: { title: '日历页' },
        user: {
            user_menu: {
                edit_profile: '编辑资料',
                STATUS: '状态',
                SIGN_OUT: '退出',
                USER_SETTINGS: 'Settings',
                statuses: { AVAILABLE: '在线', BUSY: '忙碌', DO_NOT_DISTURB: '不要打扰', OOTO: '不在办公室' }
            },
            edit_profile: {
                upload_img: '上传',
                details_form: { username: '用户名称', first_name: '名', last_name: '姓', phone_number: '电话号码', role: '角色', submit_btn: '保存' },
                password_form: { current_pswd: '当前密码', new_pswd: '新密码', confirm_pswd: '确认密码', submit_btn: '更新密码' },
                errors: {
                    empty_field: '此字段不能为空',
                    pswd_match: '密码不匹配',
                    pswd_invalid: '无效密码',
                    you_can_only_upload_jpg_png_file: '只能上传JPG/PNG 文档',
                    image_must_smaller_than_2_mb: '图片必须小于2MB'
                },
                notification: { details_has_been_updated: '详情已更新', password_has_been_updated: '密码已更新' }
            }
        },
        notifications: {
            header: { text: '通知', markAllRead: '标记所有为已读', markAllUnread: '标记所有为未读' },
            item: { unfollow: '无跟进', follow: '跟进', tooltipRead: '标记已读', tooltipUnread: '标记未读', tooltipRemove: '移除' },
            empty_state: { title: '暂无通知', sub_title: '请继续关注!关于您活动的通知将在这里显示。', settings_ref: '通知设置' },
            menu: { see_all: '查看所有' },
            push: { default_title: '默认标题', go_to_chat: '去聊天界面', see_requests: '查看请求' }
        },
        companies: {
            title: '所有合作伙伴',
            filterPlaceHolder: '过滤合作伙伴',
            newCompany: '新伙伴',
            export_btn: '导出',
            filter_btn: 'Filter',
            refresh: 'Refresh',
            exportOptions: {
                pdf: 'PDF',
                excel: 'excel表格',
                export_btn: '导出',
                export_file_name: 'businessPartners',
                export_excel_modal: { title: 'Export Business Partners Excel', cancel: 'Cancel', export: 'Export', all_columns: 'All columns' }
            },
            grid: {
                columns: {
                    id: 'Copy Id',
                    name: '名称',
                    type: '类型',
                    address: '地址',
                    contactName: '联系人名称',
                    phoneNumber: '电话号码',
                    email: '电子邮件',
                    vatNumber: '税号',
                    paymentTerms: '付款条款',
                    paymentTermsRemarks: '付款条款评论',
                    taxesDuties: '税收和关税',
                    monthlyIntrest: '月利息',
                    creditLine: '信用额度',
                    website: '网址'
                },
                filtersForm: {},
                menu: { edit: '编辑', delete: '删除' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: '现在创建商业伙伴'
            },
            form: {
                requiredFieldMessage: '这个字段是必须的',
                dateAfterMessage: '日期需为美式格式',
                parties: { modal: { create: '创建', FORWARDER: '货代', BROKER: '中介', SHIPPER: '托运人', CONSIGNEE: '收件人' } }
            },
            modal: { add_title: '新增合作伙伴', edit_title: '编辑合作伙伴' },
            filter: { type: '类型', cancel_btn: '取消', reset_btn: '重置', filter_btn: '过滤' },
            upload_form: {
                required: '输入',
                company_placeholder: '选择公司',
                select_excel_file: '选择 xls或者xlsx 文档',
                download_template: '下载可用excel',
                upload_btn: '上传',
                modal_title: '上传合作伙伴'
            }
        },
        company: {
            backLink: '回到合作伙伴页面',
            edit: '编辑',
            name: '名称',
            type: '类型',
            address: '地址',
            contactName: '联系人名称',
            phoneNumber: '电话号码',
            email: '电子邮件',
            vatNumber: '税号',
            website: '网址',
            paymentTerms: '付款条款',
            paymentTermsRemarks: '付款条款评论',
            remarks: 'Remarks',
            taxesDuties: '税收和关税',
            monthlyIntrest: '月利息',
            creditLine: '信用额度',
            modal: { edit_title: '编辑合作伙伴' },
            active: 'Active',
            inactive: 'Inctive',
            documents: {
                grid: {
                    columns: { type: '文件类型', name: '名称', date: '日期需为美式格式', added_by: '被谁新增', comment: '评论' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有文件',
                add_document: '新增文件',
                modal: { add_title: '新增合作伙伴文件', edit_title: '编辑合作伙伴文件', submit_btn: '上传' },
                missing_docs: { dismiss_btn: '取消', missing: '丢失' },
                form: {
                    documenttype: '文件类型',
                    documenttype_placeholder: '选择文件类型',
                    comment: '评论',
                    comment_placeholder: '在这里新增评论',
                    addfile_placeholder: '在这里新增文档',
                    required_error: '输入'
                }
            },
            products: {
                grid: {
                    columns: {
                        number: 'Number',
                        name: 'Name',
                        sku: 'SKU',
                        description: 'Description',
                        supplierName: 'Supplier',
                        category: 'Category',
                        brand: 'Brand',
                        cost: 'Cost',
                        retailPrice: 'Retail Price',
                        salePrice: 'Sale Price',
                        weight: 'Weight',
                        width: 'Width',
                        length: 'Length',
                        height: 'Height',
                        dimensionUnit: 'Dimension',
                        note: 'Note',
                        companyName: 'Company Name'
                    }
                }
            },
            contacts: {
                grid: {
                    columns: {
                        name: '名称',
                        email: '电子邮件',
                        phoneNumber: '电话号码',
                        role: '角色',
                        backupContactName: '备用联系人',
                        comment: '评论'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '没有联系人。单击界面右侧的“添加联系人”按钮添加第一个联系人。',
                add_contact: '新增联系人',
                modal: { add_title: '新增合作伙伴联系人', edit_title: '编辑合作伙伴联系人', add_btn: '创建', edit_btn: '更新' },
                form: {
                    name: '名称',
                    email: '电子邮件',
                    phoneNumber: '电话号码',
                    role: '角色',
                    departments: '部门',
                    comment: '评论',
                    backupContact: '备用联系人',
                    required_error: '输入',
                    phone_error: '不符合电话模式',
                    email_error: '不符合电子邮件模式',
                    role_max_length_error: '最多50个字符',
                    phone_max_length_error: '最多40个字符',
                    comment_max_length_error: '最多250个字符'
                }
            },
            addresses: {
                grid: {
                    columns: {
                        name: '名称',
                        country: '国家',
                        airPort: '空运港',
                        seaPort: '海运港',
                        phoneNumber: '电话号码',
                        city: '城市',
                        address: '地址',
                        personInChargeName: '负责人',
                        description: '描述',
                        isDefault: '是默认的吗'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有地址',
                add_address: '新增地址',
                modal: { add_title: '新增合作伙伴地址', edit_title: '编辑合作伙伴地址', add_btn: '创建', edit_btn: '更新' },
                form: {
                    name: '名称',
                    country: '国家',
                    airPort: '空运港',
                    seaPort: '海运港',
                    city: '城市',
                    address: '地址',
                    description: '描述',
                    phoneNumber: '电话号码',
                    personInCharge: '负责人',
                    isDefault: '是默认的吗',
                    required_error: '输入',
                    phone_error: '不符合电话模式',
                    city_max_length_error: '最多200个字符',
                    address_max_length_error: '最多20个字符',
                    description_max_length_error: '最多200个字符',
                    name_max_length_error: '最多20个字符',
                    phone_number_max_length_error: '最多20个字符'
                }
            },
            discussions: {
                header: '讨论',
                item: { placeholder: '添加一个评论。使用@来提及一个人，或使用#来链接一个实体。', menu: { edit: '编辑', delete: '删除' } }
            }
        },
        purchaseOrders: {
            title: '全部订单',
            filterPlaceHolder: '过滤订单',
            exportOptions: {
                pdf: 'pdf',
                excel: 'excel表格',
                export_file_name: 'purchaseOrders',
                export_excel_modal: { title: 'Export Purchase Orders Excel', cancel: 'Cancel', export: 'Export', all_columns: 'All columns' }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'PO Number',
                    consigneeName: 'Consignee',
                    createdAt: 'Open Date',
                    destinationStation: 'Destination',
                    incoterms: '国贸术语',
                    lastEvent: 'Last event',
                    originStation: 'Origin',
                    productsName: 'Products name',
                    quantity: '条款',
                    product: 'Product',
                    state: 'State',
                    userName: 'Created by',
                    vendorName: 'Supplier',
                    weight: '重量/体积',
                    purchaseOrder_number: '订单号码',
                    type: '类型',
                    open_date: '开立日期',
                    client_ref: '客户参考',
                    forwarder_ref: '货代参考',
                    forwarder: '货代',
                    origin: '原产地',
                    destination: '目的地',
                    shipper: '托运人',
                    consignee: '收件人',
                    master_bl: '主提单',
                    house_bl: '交货提单',
                    container_no: '柜号',
                    carrier: '承运人',
                    customs_file: '清关文件',
                    eta: '交期',
                    last_event: '上次事件',
                    leadDays: '需要天数'
                },
                filtersForm: {
                    purchaseOrder_type: '订单类型',
                    incoterms: '国贸术语',
                    forworder: '货代',
                    from_date: '从哪个日期',
                    to_date: '到哪个日期',
                    origin_country: '原产国',
                    origin_station: '原产州',
                    destination_country: '目的国',
                    destination_station: '目的州'
                },
                menu: {
                    openRelatedTalk: '打开相关对话',
                    share: '共享',
                    delete: '删除',
                    follow: '跟进',
                    unfollow: '无跟进',
                    invite_to_follow: '邀请跟进',
                    share_shipment: '共享船期',
                    make_shipment: '制作船期表',
                    export: '导出',
                    pdf: 'pdf',
                    pdf_no_prices: 'Pdf with no prices',
                    excel: 'excel表格',
                    confirmMessage: '您确认吗'
                },
                purchaseOrderWarning: { critical: '严重警告', warning: '警告', success: '一切顺利' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: '还没有船期，要创建您的第一个船期，您应该点击右上角的新订单按钮'
            },
            form: {
                requiredFieldMessage: '此字段不能为空',
                phone_error: '不符合电话模式',
                email_error: '不符合邮箱模式',
                maxLengthMessage: '最多字符',
                back_modal: { purchaseOrders_page: '订单页面', purchaseDemand_details: 'PD Details', purchaseOrder_details: '订单详情' },
                dateAfterMessage: '日期需为美式格式',
                parties: { modal: { create: '创建', FORWARDER: '货代', VENDOR: '供应商', CONSIGNEE: '收件人' } },
                route_guard: '关闭不保存？',
                pick_contact: '选择联系人',
                contact_name: '联系人名称',
                contact_phone: '联系人电话',
                contact_email: '联系人电子邮件',
                pick_address: '选择地址',
                destination_country: '目的国',
                pick_country: '选择国家',
                destination_port: '目的港',
                pick_port: '选择港口',
                destination_city: '目的城市/邮编',
                pick_zipcode: '选择邮编',
                destination_pick_up_address: '目的地提货地址',
                origin_country: '原产国',
                origin_port: '原产港口',
                origin_city: '原产城市/邮编',
                origin_pick_up_address: '原产提货地址',
                expected_ready_date: 'Estimate Goods Ready',
                asap: 'As soon as possible',
                consignee_same_as_company: 'Ship to my company',
                requested_goods_ready: 'Requested Goods Ready',
                actual_goods_ready: 'Actual Goods Ready',
                payment_terms: 'Payment Terms',
                payment_dates: 'Payment Dates',
                note: 'Note',
                taxes_rate: 'Taxes Rate',
                code: '编码',
                name: '名称',
                dimensions: '尺寸',
                CM: '厘米',
                IN: '英寸',
                LB: '磅',
                KG: '千克',
                quantity: '数量',
                product_name: 'Name',
                length: 'Length',
                width: 'Width',
                height: 'Height',
                weight: 'Weight',
                sku: 'SKU',
                price: '价格',
                total_cost: 'Total Cost',
                attributes: 'Attributes',
                cartons: 'Cartons',
                ready_date: 'Ready Date',
                pd_ref: 'PD Ref',
                shipments_refs: 'Shipments Refs',
                qc_approved: 'Qc Approved',
                shipments: 'Shipments',
                remaining_quantity: 'Remaining Quantity',
                images: 'Images',
                shipment: '船期',
                add_item: '新增类目',
                update_ready_date: 'Update ready date',
                merge_shipment: 'Merge PO to Shipment',
                merge_shipment_modal: {
                    title: 'Choose Shipment',
                    fields: {
                        type: 'Type',
                        company: 'Company',
                        shipper: 'Shipper',
                        forwarder: 'Forwarder',
                        last_event: 'Last Event',
                        quantity: 'Quantity',
                        volume: 'Volume',
                        weight: 'Weight'
                    },
                    dialog: {
                        title: 'Where do you want to go?',
                        po: 'PO',
                        shipment: 'Shipment'
                    },
                    merge_btn: 'Merge'
                },
                create_shipment: '创建船期',
                reference_no: '参考号码',
                add_ref_no: '新增参考编号',
                incoterms_tooltip: 'The agreed trade terms as mentioned in the shipment invoice',
                shipper_tooltip: 'The company you bought the goods from',
                consignee: 'Ship to',
                consignee_placeholder: 'Pick company',
                consignee_tooltip: 'The company receiving the goods at destination',
                lead_days_tooltip: 'The time from the moment the customer places an order to the moment it is ready for delivery',
                forwarder_tooltip: 'The company handling the cargo shipping',
                reference_no_tooltip: 'This can be your Purchase order number/name or nickname',
                update_product_cost: 'Update also catalog product cost'
            },
            filter: {
                po_type: '订单类型',
                incoterms: '国贸术语',
                forwarder: '货代',
                open_date: '开立日期',
                from_date: '从哪个日期',
                to_date: '到哪个日期',
                origin_country: '原产国',
                origin_station: '原产州',
                destination_country: '目的国',
                destination_station: '目的州',
                cancel_btn: '取消',
                reset_btn: '重置',
                filter_btn: '过滤'
            },
            export_btn: '导出',
            new_purchase_order: '新订单',
            date_from: '从哪个日期',
            date_to: '到哪个日期'
        },
        purchaseOrder: {
            backLink: '回到订单页',
            lastUpdate: '上次更细',
            edit: '编辑',
            duplicate: '复制',
            purchaseOrder: '订单',
            client_ref: '客户参考',
            company: 'Company',
            forwarder_ref: '货代参考',
            last_event: '上次事件',
            eta: '交期',
            master: '主提单',
            house: '交货提单',
            etd: '预计发货期',
            supplier: 'Supplier',
            customs_file: '清关文件',
            carrier: '承运人',
            port_terminal: '到达港口',
            incoterms: '国贸术语',
            quantity: '类目',
            see_more: '查看更多',
            volume: '容量',
            weight: '重量',
            name: 'Name',
            address: 'Address',
            contactName: 'Contact Name',
            phoneNumber: 'Phone Number',
            email: 'Email',
            save_btn: 'Save',
            expected_ready_date: 'Estimate Goods Ready',
            total_cartons: 'Total Cartons',
            asap: 'As soon as possible',
            requested_goods_ready: 'Requested Goods Ready',
            actual_goods_ready: 'Actual Goods Ready',
            payment_terms: 'Payment Terms',
            payment_dates: 'Payment Dates',
            wand_tooltip: 'Create payment dates by payment terms',
            note: 'Note',
            taxes_rate: 'Taxes Rate',
            confirm: 'Confirm',
            confirmed_message: 'Thank you! Purchase order has been confirmed.',
            chargeable_weight: '计费重量',
            alert_status: { critical: '严重警告', warning: '警告', success: '一切顺利' },
            send_supplier_updates_email_modal: {
                title: 'Send to supplier',
                sent_message: 'Email has been sent to the supplier',
                form: {
                    email: 'Email',
                    note: 'Note',
                    send_btn: 'Send',
                    cancel_btn: 'Cancel',
                    required: 'Required',
                    invalid_email: 'Invalid Email'
                }
            },
            parties: {
                empty_state: '现在没有当事人',
                add_party: '新增',
                modal: { add_title: '新增订单当事人', edit_title: '编辑订单当事人', add_btn: '新增', edit_btn: '更新', cancel_btn: '取消' },
                form: {
                    partytype_placeholder: '选择当事人类型',
                    partysubtype_placeholder: '选择辅当事人类型',
                    companyaddresses_placeholder: '选择地址',
                    companycontacts_placeholder: '选择联系人',
                    partyname_placeholder: '名称',
                    partycountry_placeholder: '国家',
                    partycity_placeholder: '城市',
                    partyaddress_placeholder: '地址',
                    partyphone_placeholder: '电话号码',
                    partyemail_placeholder: '电子邮件',
                    remarks_placeholder: 'Remarks',
                    partycontact_name_placeholder: '联系人名称',
                    partyvat_number_placeholder: '税号',
                    partywebsite_placeholder: '网址',
                    required_error: '输入',
                    phone_error: '不符合电话模式',
                    email_error: '不符合电子邮件模式',
                    create_company_party: '创建合作伙伴',
                    address_max_length_error: '最多250个字符',
                    phone_max_length_error: '最多40个数字',
                    email_max_length_error: '最多100个字符',
                    website_max_length_error: '最多50个字符',
                    vat_number_max_length_error: '最多20个字符',
                    name_max_length_error: '最多100个字符'
                },
                menu: { edit: '编辑', delete: '删除' }
            },
            events: {
                grid: {
                    columns: {
                        id: 'ID 号',
                        eventCode: '编码',
                        name: '事件',
                        description: '描述',
                        userName: '被谁插入',
                        eventDate: '事件日期',
                        createdAt: '创建日期',
                        comment: '评论'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有事件',
                add_event: '新增事件',
                modal: { add_title: '新增订单事件', edit_title: '编辑订单事件', add_btn: '新增事件', edit_btn: '更新事件', cancel_btn: '取消' },
                form: {
                    eventtype: '事件类型',
                    eventtype_placeholder: '选择事件类型',
                    comment: '评论',
                    comments_placeholder: '在这里新增评论',
                    required_error: '输入',
                    comment_max_length_error: '最多255个字符'
                }
            },
            documents: {
                grid: {
                    columns: { type: '文件类型', name: '名称', date: '日期', added_by: '被谁新增', comment: '评论' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '没有文件. .单击界面右侧的“添加联系人”按钮添加第一个联系人。',
                add_document: '新增文件',
                modal: { add_title: '新增订单文件', edit_title: '编辑订单文件', submit_btn: '上传' },
                missing_docs: { dismiss_btn: '取消', missing: '丢失' },
                form: {
                    documenttype: '文件类型',
                    documenttype_placeholder: '选择文件类型',
                    comment: '评论',
                    comment_placeholder: '在这里新增评论',
                    addfile_placeholder: '新增文档',
                    required_error: '输入'
                }
            },
            items: {
                modal_title: '类目',
                add_item: 'Add items',
                modal: { add_title: 'Add Items', edit_title: 'Edit Item', add_btn: 'Add Item', edit_btn: 'Update Item', cancel_btn: 'Cancel' },
                grid: {
                    columns: {
                        quantity: '数量',
                        sku: 'SKU',
                        code: '编码',
                        name: '名称',
                        price: '价格',
                        currency: '币种',
                        dimensions: '尺寸',
                        weight: '重量'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: '现在没有类目'
            },
            routes: {
                modal_title: '路线',
                grid: {
                    columns: { origin_station: '原产地', destination_station: '目的地', departure: '离港', arrival: '到港', flight_number: '航班号' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                },
                empty_state: '现在没有路线'
            },
            discussions: {
                header: '讨论',
                item: { placeholder: '添加一个评论。使用@来提及一个人，或使用#来链接一个实体。', menu: { edit: '编辑', delete: '删除' } }
            },
            history: {
                item_dictionary: {
                    CREATE_PURCHASE_ORDER: '创建新订单',
                    CREATE_FOLLOWERS: '开始跟进订单',
                    CREATE_EVENTS: '创建新事件',
                    CREATE_DOCUMENTS: '创建新文件',
                    CREATE_PARTIES: '创建新当事人',
                    'CREATE_UPLOAD-MULTIPLE': '上传文档',
                    CREATE_PURCHASEORDERS: '创建新订单',
                    CREATE_DISCUSSIONS: '创建新讨论',
                    CREATE_UPLOAD: '创建新上传',
                    CREATE_ITEM: 'create new item',
                    CREATE_REFERENCE: 'create new reference',
                    CREATE_EVENT: 'create new event',
                    CREATE_PARTY: 'create new party',
                    UPDATE_PURCHASE_ORDER: '更新订单',
                    UPDATE_EVENTS: '更新事件',
                    UPDATE_DOCUMENTS: '更新文件',
                    UPDATE_PARTIES: '更新当事人',
                    'UPDATE_UPLOAD-MULTIPLE': '上传文档',
                    UPDATE_PURCHASEORDERS: '更新订单',
                    UPDATE_DISCUSSIONS: '更新订单',
                    UPDATE_UPLOAD: '更新订单',
                    UPDATE_STATE: '更新订单状态',
                    UPDATE_ITEM: 'update item',
                    UPDATE_REFERENCE: 'update reference',
                    UPDATE_EVENT: 'update event',
                    UPDATE_PARTY: 'update party',
                    DELETE_PURCHASE_ORDER: '删除订单',
                    DELETE_FOLLOWERS: '无跟进订单',
                    DELETE_EVENTS: '删除事件',
                    DELETE_DOCUMENTS: '删除文件',
                    DELETE_PARTIES: '删除当事人',
                    'DELETE_UPLOAD-MULTIPLE': '删除文档',
                    DELETE_PURCHASEORDERS: '删除订单',
                    DELETE_DISCUSSIONS: '删除讨论',
                    DELETE_UPLOAD: '删除上传'
                }
            },
            references: {
                modal: { add: 'Add', edit: 'Edit' },
                types: { CLIENT: 'Client Ref', FORWARDER: 'Forwarder Ref' },
                form: {
                    maxLength: (length: number) => `Max ${length} characters`,
                    value: 'Value',
                    cancel_btn: 'Cancel',
                    delete_btn: 'Delete',
                    save_btn: 'Save'
                }
            },
            exceptions: { '401': { title: 'Access denied' }, back_btn: '回到订单页' },
            leadDays: '需要天数',
            'exceptions[401]': { title: '禁止进入' }
        },
        purchaseDemands: {
            title: 'All PDs',
            filterPlaceHolder: 'Filter PDs',
            exportOptions: {
                pdf: 'Pdf',
                excel: 'Excel',
                export_file_name: 'purchaseDemands',
                export_excel_modal: { title: 'Export Purchase Demands Excel', cancel: 'Cancel', export: 'Export', all_columns: 'All columns' }
            },
            grid: {
                columns: {
                    CargoZoneNumber: 'PD Number',
                    createdAt: 'Open Date',
                    state: 'State',
                    companyName: 'Company',
                    userName: 'User',
                    quantity: 'Number of items',
                    approvers: 'Approvers'
                },
                filtersForm: { purchaseDemand_type: 'PD Type', from_date: 'From Date', to_date: 'To Date' },
                menu: { delete: 'Delete', export: 'Export', pdf: 'Pdf', excel: 'Excel', confirmMessage: 'Are you sure?' },
                purchaseDemandWarning: { critical: 'Critical', warning: 'Warning', success: 'All good' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: 'No PD yet, to create your first PD, you should click on the new PD button up right.'
            },
            form: {
                requiredFieldMessage: 'This field is mandatory',
                requiredItem: 'At least one item is required',
                maxLengthMessage: (chars: string) => `Max ${chars} characters`,
                back_modal: { purchaseDemands_page: 'PDs Page', purchaseDemand_details: 'PD Details' },
                dateAfterMessage: (date: string) => `Date needs to be after ${date}`,
                route_guard: 'Close without saving?',
                add_item: 'Add item',
                company: 'Company',
                name: 'Name',
                dimensions: 'Dimensions',
                CM: 'CM',
                IN: 'IN',
                LB: 'LB',
                KG: 'KG',
                quantity: 'Quantity',
                product_name: 'Name',
                length: 'Length',
                width: 'Width',
                height: 'Height',
                weight: 'Weight',
                productSupplierName: 'Supplier',
                sku: 'SKU',
                price: 'Cost',
                minOrderQuantity: 'Minimum Order Quantity',
                productionDays: 'LT Production',
                supplier: 'Supplier',
                total_cost: 'Total Cost',
                purchase_order_id: 'PO',
                pos: 'POs',
                pos_refs: 'POs Refs',
                reset_po_state_message: 'This change will reset the approvers’ vote and the PD’s state',
                remaining_quantity: 'Remaining Quantity',
                images: 'Images',
                see_purchase_order: 'See PO',
                attributes: 'Attributes',
                cartons: 'Cartons',
                save_btn: 'Save',
                cancel_btn: 'Cancel',
                approvers: 'Approvers',
                select_users: 'Select users',
                requested_goods_ready: 'Requested Goods Ready',
                asap: 'As soon as possible',
                default_approvers: 'This users are required for approval:',
                update_product_cost: 'Update also catalog product cost',
                create_po: 'Create PO',
                create_po_modal_title: 'Choose supplier and then proudcts',
                expected_ready_date: 'Expected Ready Date',
                purchaseOrder: 'PO',
                purchaseDemand: 'PD',
                merge_po_modal_title: 'Merge PD to PO',
                items_title: 'Items',
                parties_title: 'Parties',
                include_insurance: 'Include Insurance',
                no_suppliers: 'No Suppliers',
                back_btn: 'Back',
                next_btn: 'Next',
                items: {
                    modal_title: 'Items',
                    add_item: 'Add items',
                    modal: { add_title: 'Add Items', edit_title: 'Edit Item', add_btn: 'Add Item', edit_btn: 'Update Item', cancel_btn: 'Cancel' },
                    grid: {
                        columns: {
                            quantity: 'Quantity',
                            sku: 'SKU',
                            code: 'Code',
                            name: 'Name',
                            price: 'Cost',
                            currency: 'Currency',
                            dimensions: 'Dimensions',
                            weight: 'Weight',
                            attributes: 'Attributes',
                            cartons: 'Cartons',
                            total_cost: 'Total Cost'
                        },
                        filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                        menu: { edit: 'Edit', delete: 'Delete' }
                    },
                    empty_state: 'No items right now!'
                }
            },
            filter: {
                pd_type: 'PD Type',
                open_date: 'Open Date',
                from_date: 'From Date',
                to_date: 'To Date',
                cancel_btn: 'Cancel',
                reset_btn: 'Reset',
                filter_btn: 'Filter',
                state: 'State'
            },
            modal: { add_title: 'Add PD' },
            export_btn: 'Export',
            new_purchase_demand: 'New Purchase Demand',
            date_from: 'Date from: ',
            date_to: 'Date to: '
        },
        purchaseDemand: {
            backLink: 'Back to PDs page',
            lastUpdate: 'Last update:',
            edit: 'Edit',
            duplicate: 'Duplicate',
            send: 'Send',
            purchaseDemand: 'PD',
            requested_goods_ready: 'Requested Goods Ready',
            reset_po_state_message: 'This change will reset the approvers’ vote and the PD’s state',
            asap: 'As soon as possible',
            company: 'Company',
            save_btn: 'Save',
            back_btn: 'Back',
            next_btn: 'Next',
            alert_status: { critical: 'Critical', warning: 'Warning', success: 'All good' },
            purchase_demand_state: 'Purchase Demand State',
            create_po: 'Create PO',
            create_po_modal_title: 'Choose supplier and then proudcts',
            expected_ready_date: 'Expected Ready Date',
            purchaseOrder: 'PO',
            merge_po_modal_title: 'Merge PD to PO',
            items_title: 'Items',
            parties_title: 'Parties',
            include_insurance: 'Include Insurance',
            no_suppliers: 'No Suppliers',
            items: {
                modal_title: 'Items',
                add_item: 'Add items',
                modal: { add_title: 'Add Items', edit_title: 'Edit Item', add_btn: 'Add Item', edit_btn: 'Update Item', cancel_btn: 'Cancel' },
                grid: {
                    columns: {
                        quantity: 'Quantity',
                        sku: 'SKU',
                        code: 'Code',
                        name: 'Name',
                        price: 'Cost',
                        currency: 'Currency',
                        dimensions: 'Dimensions',
                        weight: 'Weight',
                        attributes: 'Attributes',
                        cartons: 'Cartons',
                        total_cost: 'Total Cost'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: 'No items right now!'
            },
            discussions: {
                header: 'Public Discussions',
                item: { placeholder: 'Add a comment. Use @ to mention a person, or # to link an entity.', menu: { edit: 'Edit', delete: 'Delete' } }
            },
            history: {
                item_dictionary: {
                    CREATE_PURCHASE_DEMAND: 'create new PD',
                    CREATE_PURCHASEORDERS: 'create new PD',
                    CREATE_DISCUSSIONS: 'create new discussion',
                    CREATE_ITEM: 'create new item',
                    CREATE_APPROVER: 'create new approver',
                    UPDATE_PURCHASE_DEMAND: 'update PD',
                    UPDATE_PURCHASEORDERS: 'update PD',
                    UPDATE_DISCUSSIONS: 'update PD',
                    UPDATE_STATE: 'update PD state',
                    UPDATE_ITEM: 'update item',
                    UPDATE_APPROVER: 'update new approver',
                    DELETE_PURCHASE_DEMAND: 'delete PD',
                    DELETE_FOLLOWERS: 'unfollow PD',
                    DELETE_PURCHASEORDERS: "delete PD's",
                    DELETE_DISCUSSIONS: 'delete discussion',
                    DELETE_ITEMS: 'delete new items',
                    DELETE_APPROVERS: 'delete new approver'
                }
            },
            exceptions: { '401': { title: 'Access denied' }, back_btn: 'Go to PDs page' },
            approvers: { header: 'Approvers', empty_approvers: 'No approvers right now!', select_user: 'Add new approver' },
            documents: {
                grid: {
                    columns: { type: 'Document type', name: 'Name', date: 'Date', added_by: 'Added by', comment: 'Comment' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: 'Edit', delete: 'Delete' }
                },
                empty_state: 'No documents yet.. add the first document by clicking on the Add document button on the right.',
                add_document: 'Add document',
                modal: { add_title: 'Add PD document', edit_title: 'Edit PD document', submit_btn: 'Upload' },
                missing_docs: { dismiss_btn: 'Dismiss', missing: 'Missing' },
                form: {
                    documenttype: 'Document type',
                    documenttype_placeholder: 'Choose document type',
                    comment: 'Comment',
                    comment_placeholder: 'Add a comment here...',
                    addfile_placeholder: 'Add file here',
                    required_error: 'Required'
                }
            }
        },
        routes: {
            titles: {
                shipments: '所有船期',
                sub_shipments: '船期详情',
                companies: '所有合作伙伴',
                sub_companies: '合作伙伴详情',
                messages: '聊天窗格',
                contacts: '联系人'
            }
        },
        bid: {
            title: '所有报价单',
            filterPlaceHolder: '过滤报价单',
            newBid: '新报价单',
            exportOptions: { pdf: 'pdf', excel: 'excel表格' },
            bid: '报价单',
            backLink: '回到报价单',
            edit: '编辑',
            startBid: '开始报价',
            client_ref: '客户参考',
            company: 'Company',
            forwarder: '货代',
            ata: '实际到达期',
            grid: {
                columns: {
                    CargoZoneNumber: '报价单数量',
                    shipmentId: '船期',
                    startDate: '开始日期',
                    expirationDate: '截止日期',
                    includeInsurance: 'Include Insurance'
                },
                filtersForm: {},
                menu: { edit: '编辑', delete: '删除' },
                filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                emptyState: '看来你还没报价呢。要生成第一个报价，请单击向上的新报价按钮'
            },
            form: {
                requiredFieldMessage: '此字段不能为空',
                back_modal: { bids_page: '报价页面', bid_details: '报价详情' },
                dateAfterMessage: '日期需为美式格式',
                parties: { modal: { create: '创建', FORWARDER: '货代', BROKER: '中介', SHIPPER: '托运人', CONSIGNEE: '收件人' } },
                company: '公司',
                company_placeholder: '选择公司',
                shipment: '船期',
                shipment_placeholder: '选择船期',
                startdate: '开始日期',
                expirationdate: '截止日期',
                includeInsurance: 'Include Insurance',
                route_guard: '关闭不保存？'
            },
            documents: {
                grid: {
                    columns: { type: '文件类型', name: '名称', date: '日期', added_by: '被谁新增', comment: '评论' },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' },
                    menu: { edit: '编辑', delete: '删除' }
                },
                empty_state: '现在没有文件',
                add_document: '新增文件',
                modal: { add_title: '新增船期文件', edit_title: '编辑船期文件', submit_btn: '上传' },
                missing_docs: { dismiss_btn: '取消', missing: '丢失' },
                form: {
                    documenttype: '文件类型',
                    documenttype_placeholder: '选择文件类型',
                    comment: '评论',
                    comment_placeholder: '在这里添加评论',
                    addfile_placeholder: '在这里添加文档',
                    required_error: '输入'
                }
            },
            quotations: {
                grid: {
                    columns: {
                        forwarderName: '货代名称',
                        freightPrice: '总运输价格（USD)',
                        transitTime: '运输时间',
                        freeDays: '开放天数',
                        validTill: '有效期至',
                        note: '注意点'
                    },
                    filter: { noResults: 'No results found for your search : (', resetBtn: 'Reset Filter', noData: 'No data' }
                },
                empty_state: '现在没有报价'
            },
            history: {
                item_dictionary: {
                    CREATE_BID: '创建新报价',
                    CREATE_QUOTATION: '创建新的报价单',
                    CREATE_PARTY: '创建新当事人',
                    CREATE_DOCUMENT: '创建新文件',
                    CREATE_BIDS: '创建新报价',
                    UPDATE_BID: '更新报价',
                    UPDATE_QUOTATION: '更新报价单',
                    UPDATE_PARTY: '更新当事人',
                    UPDATE_DOCUMENT: '更新文件',
                    UPDATE_BIDS: '更新报价',
                    DELETE_BID: '删除报价',
                    DELETE_QUOTATION: '删除报价单',
                    DELETE_PARTY: '删除当事人',
                    DELETE_DOCUMENT: '删除文件',
                    DELETE_BIDS: '删除报价'
                }
            },
            references: { modal: { add: '新增', edit: '编辑' }, types: { CLIENT: '客户参考', FORWARDER: '货代参考' } }
        },
        quotation: {
            packages: {
                modal_title: '包裹',
                grid: {
                    columns: {
                        type: '类型',
                        quantity: '数量',
                        description: '描述',
                        containerNumber: '柜号',
                        containerType: '柜型',
                        isOverweight: '超重',
                        dimensions: '尺寸',
                        weight: '重量',
                        volume: '容量',
                        hsCode: '海关编码'
                    },
                    filter: { noResults: '按照您的搜索没有发现任何结果', resetBtn: '重置过滤', noData: '没有数据' }
                },
                empty_state: '现在没有包裹'
            },
            expire: '失效',
            incoterms: '国贸术语',
            shipment_type: '船期类型',
            items_title: 'Items',
            parties_title: 'Parties',
            include_insurance: 'Include Insurance',
            packages_title: '包裹',
            attached_files: '插入附件',
            quantity: '数量',
            weight: '重量',
            volume: '体积',
            note: '注意点',
            quotation_request: '申请报价',
            reply_page_default_error_message: '口令已过期或不正确',
            po_ref: '订单参考',
            sku: '款号',
            code: '代码',
            name: '名称',
            product_name: '名称',
            price: '成本',
            currency: '币别',
            dimensions: '尺寸',
            total_cost: '总成本',
            attributes: '属性',
            ready_date: '完成日期',
            pd_ref: '预告订单参考',
            cartons: 'Cartons',
            images: '图像',
            remaining_quantity: '开立数量',
            shipments_refs: '船期参考',
            shipments: '船期',
            form: {
                required_message: '此处必须输入',
                note_max_length: '最大512个字符',
                sent_quotation: '您的报价已发',
                we_will_update: '一旦有人中标我们会给您更新',
                send_again: '发送另一个报价',
                add_quotation: '增加报价',
                quotation: '报价',
                total_freight_price: '总运费',
                transit_time: '运输时间',
                demurrage_free_days: '滞期费免费天数',
                valid_till: '直到哪一天有效',
                note: '注意点',
                file_placeholder: '在此处添加文本',
                next_btn: '下一步',
                send_btn: '发送',
                back_btn: '上一步',
                skip_btn: '跳过'
            }
        },
        common: {
            loaderText: '上传中',
            click: '点击',
            follow: '跟进',
            view: '查看',
            go_back: '返回',
            date: '日期',
            time: '时间',
            yes: 'Yes',
            no: 'No',
            are_you_sure: 'Are you sure?',
            copied_message: 'Copied succesfully!',
            no_permission: 'No Permission'
        },
        files: { preview: { no_preview: '没有预览', modal: { download_btn: '下载' } } },
        chat: {
            contacts_side_bar: { new_group: '新群组', invite_users: '邀请用户' },
            chat_view: {
                footer: { are: '是', is: '是', editing_message: '编辑信息', reply_to: '回复', send_connection_request: '发送联系请求' },
                header: { exit_group: '退出群组' },
                participants_header: { are_you_sure: '您确定吗' },
                content: {
                    reply: 'Reply',
                    set_reminder: 'Set Reminder',
                    reminder_about_message: 'Reminder about message',
                    edit: 'Edit',
                    delete: 'Delete'
                }
            },
            cannot_be_empty: '不能为空',
            max_chars: '最多字符',
            created_group: '创建群组',
            group_name: '群组名称',
            select_users: '选择用户',
            create_btn: '创建',
            create_new_group: '创建新群组',
            value: '货值',
            save_btn: '保存',
            invalid_email: '无效邮箱',
            enter_email: '输入一个电子邮箱',
            send_btn: '发送',
            connection_requests: '联系请求',
            approve_btn: '确认',
            waiting_for: '等待',
            to_approve: '去确认',
            typing: '输入',
            required: '输入',
            search_users: 'Search Users',
            reply: '回复',
            set_reminder: '设置提醒',
            reminder_about_message: '信息提醒',
            edit: '编辑',
            delete: '删除'
        },
        modal: { confirm_text: '关闭不保存？' },
        '"login': { reset_pswd_form: { password_requirments: '长度至少为8个字符 • 至少包含以下类别中的1个字符： 1. 大写字母 (A-Z)' } }
    }
};
