import { address } from '../../../models/entities/businessPartner/address';
import { shipmentType } from '../../../models/entities/shipment/shipment';
import { allowedCompany } from '../../../models/entities/user';
import { carriersDropDownOptionsByShipmentType } from './ShipmentForm/sections/utils';

export const shipmentFormLogics = {
    companyId: (allowedCompanies: allowedCompany[]): boolean => allowedCompanies.length > 1,
    BrokerAsForwarder: (shipmentType: shipmentType): boolean => ['CUSTOMS'].includes(shipmentType),
    shipperId: (shipmentType: shipmentType, userCompanyType: string): boolean => true,
    consigneeId: (shipmentType: shipmentType, userCompanyType: string): boolean => true,
    taxesIncluded: (shipmentType: shipmentType): boolean => ['EXPRESS'].includes(shipmentType),
    incoterms: (shipmentType: shipmentType): boolean => !['EXPRESS'].includes(shipmentType),
    commodity: (shipmentType: shipmentType): boolean => true,
    goods_ready: (shipmentType: shipmentType): boolean => !['EXPRESS'].includes(shipmentType),
    pick_up: (shipmentType: shipmentType): boolean => ['EXPRESS'].includes(shipmentType),
    carrier: (shipmentType: shipmentType): boolean => carriersDropDownOptionsByShipmentType(shipmentType).length > 0,
    house: (shipmentType: shipmentType): boolean => !['EXPRESS', 'INLAND'].includes(shipmentType),
    vessel_name: (shipmentType: shipmentType): boolean => ['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(shipmentType),
    container_no: (shipmentType: shipmentType): boolean => ['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(shipmentType),
    customs_file: (shipmentType: shipmentType): boolean => true,
    operator: (shipmentType: shipmentType): boolean => true,
    shipper_addresses: (shipperAddresses: address[], consigneeAddresses: address[], shipmentType: shipmentType): boolean =>
        (shipperAddresses.length > 1 || consigneeAddresses.length > 1) && !['EXPRESS'].includes(shipmentType),
    origin: (shipmentType: shipmentType): boolean => !['EXPRESS', 'INLAND'].includes(shipmentType),
    consignee_addresses: (consigneeAddresses: address[], shipperAddresses: address[], shipmentType: shipmentType): boolean =>
        (consigneeAddresses.length > 1 || shipperAddresses.length > 1) && !['EXPRESS'].includes(shipmentType),
    destination: (shipmentType: shipmentType): boolean => !['EXPRESS', 'INLAND'].includes(shipmentType),
    voyage_number: (shipmentType: shipmentType): boolean => !['EXPRESS', 'INLAND'].includes(shipmentType),
    etd: (shipmentType: shipmentType): boolean =>
        ['OE', 'OI', 'OEX', 'AE', 'AI', 'EXPRESS', 'DROP_OCEAN', 'DROP_AIR', 'CUSTOMS', 'INLAND', 'RAIL'].includes(shipmentType),
    eta: (shipmentType: shipmentType): boolean =>
        ['OE', 'OI', 'OEX', 'AE', 'AI', 'EXPRESS', 'DROP_OCEAN', 'DROP_AIR', 'CUSTOMS', 'INLAND', 'RAIL'].includes(shipmentType),
    ata: (shipmentType: shipmentType): boolean => !['EXPRESS'].includes(shipmentType),
    atd: (shipmentType: shipmentType): boolean => !['EXPRESS'].includes(shipmentType),
    master: (shipmentType: shipmentType): boolean => !['EXPRESS', 'INLAND'].includes(shipmentType),
    tracking_number: (shipmentType: shipmentType): boolean => ['EXPRESS'].includes(shipmentType),
    delivery_note: (shipmentType: shipmentType): boolean => ['INLAND'].includes(shipmentType)
};
