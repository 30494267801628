import { party } from '../../../../../models/entities/common_subentities/party';
import { businessPartner } from '../../../../../models/entities/businessPartner/businessPartner';
import { Option } from '../../../../shared/inputs/base/DropDown';
import carriers from '../../../../../static/data/carriers.json';
import operators from '../../../../../static/data/operators.json';
import { shipmentType } from '../../../../../models/entities/shipment/shipment';
import { address } from '../../../../../models/entities/businessPartner/address';
import { allowedCompany } from '../../../../../models/entities/user';
import { purchaseOrderItem } from '../../../../../models/entities/purchaseOrder/purchaseOrderItem';
import shipmentItem from '../../../../../models/entities/shipment/shipmentItem';

export const partiesPrepareSubmit = (
    values: any,
    initialValues: any,
    shipmentId?: string,
    businessPartners?: Array<businessPartner>,
    allowedCompanies?: allowedCompany[]
) => {
    let parties: Array<party> = values.parties || [];

    if (!businessPartners) return parties;

    if (values.forwarderId && !parties.find((item) => item.businessPartnerId === values.forwarderId)) {
        const forwarder = businessPartners.find((p) => p.id === values.forwarderId);

        if (forwarder) {
            if (initialValues.forwarderId) {
                const previousForwarderIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.forwarderId);
                if (previousForwarderIndex > -1) parties.splice(previousForwarderIndex, 1);
            }
            const party = {
                entityId: shipmentId,
                businessPartnerId: forwarder.id,
                type: forwarder.type,
                name: forwarder.name,
                email: forwarder.email,
                phoneNumber: forwarder.phoneNumber,
                logoFileId: forwarder.logoFileId
            } as party;
            parties.push({ ...party, subType: 'FORWARDER' });
            if (values.BrokerAsForwarder) parties.push({ ...party, subType: 'BROKER' });
        }
    }
    if (values.cargoOwnerId && !parties.find((item) => item.businessPartnerId === values.cargoOwnerId)) {
        const cargoOwner = businessPartners.find((p) => p.id === values.cargoOwnerId);

        if (cargoOwner) {
            if (initialValues.cargoOwnerId) {
                const previousCargoOwnerIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.cargoOwnerId);
                if (previousCargoOwnerIndex > -1) parties.splice(previousCargoOwnerIndex, 1);
            }
            const party = {
                entityId: shipmentId,
                businessPartnerId: cargoOwner.id,
                type: cargoOwner.type,
                name: cargoOwner.name,
                email: cargoOwner.email,
                phoneNumber: cargoOwner.phoneNumber,
                logoFileId: cargoOwner.logoFileId
            } as party;
            parties.push(party);
        }
    }
    if (values.brokerId && !values.BrokerAsForwarder && !parties.find((item) => item.businessPartnerId === values.brokerId)) {
        const broker = businessPartners.find((p) => p.id === values.brokerId);

        if (broker) {
            if (initialValues.brokerId) {
                const previousBrokerIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.brokerId);
                if (previousBrokerIndex > -1) parties.splice(previousBrokerIndex, 1);
            }
            parties.push({
                entityId: shipmentId,
                businessPartnerId: broker.id,
                type: broker.type,
                subType: 'BROKER',
                name: broker.name,
                email: broker.email,
                phoneNumber: broker.phoneNumber,
                logoFileId: broker.logoFileId
            } as party);
        }
    }

    if (values.shipper_same_as_company && !parties.find((item) => item.companyId === values.companyId && item.type === 'SHIPPER')) {
        const company = allowedCompanies?.find((c) => c.companyId === values.companyId);
        if (company) {
            if (initialValues.shipperId) {
                const previousShipperIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.shipperId);
                if (previousShipperIndex > -1) parties.splice(previousShipperIndex, 1);
            }

            parties.push({
                entityId: shipmentId,
                type: 'SHIPPER',
                subType: 'SHIPPER',
                name: company?.companyName,
                companyId: values.companyId,
                address: values.origin_pickup_address + ',' + values.origin_zipcode + ',' + values.origin_country
            } as party);
        }
    } else if (values.shipperId && !parties.find((item) => item.businessPartnerId === values.shipperId)) {
        const shipper = businessPartners.find((p) => p.id === values.shipperId);
        if (shipper) {
            if (initialValues.shipperId) {
                const previousShipperIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.shipperId);
                if (previousShipperIndex > -1) parties.splice(previousShipperIndex, 1);
            }

            parties.push({
                entityId: shipmentId,
                businessPartnerId: shipper.id,
                type: 'SHIPPER',
                subType: 'SHIPPER',
                name: shipper.name,
                address: values.origin_pickup_address + ',' + values.origin_zipcode + ',' + values.origin_country,
                email: shipper.email,
                phoneNumber: shipper.phoneNumber,
                logoFileId: shipper.logoFileId
            } as party);
        }
    }
    if (values.consignee_same_as_company && !parties.find((item) => item.companyId === values.companyId && item.type === 'CONSIGNEE')) {
        const company = allowedCompanies?.find((c) => c.companyId === values.companyId);
        if (company) {
            if (initialValues.consigneeId) {
                const previousConsigneeIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.consigneeId);
                if (previousConsigneeIndex > -1) parties.splice(previousConsigneeIndex, 1);
            }

            parties.push({
                entityId: shipmentId,
                type: 'CONSIGNEE',
                subType: 'CONSIGNEE',
                name: company?.companyName,
                companyId: values.companyId,
                address: values.destination_pickup_address + ',' + values.destination_zipcode + ',' + values.destination_country
            } as party);
        }
    } else if (values.consigneeId && !parties.find((item) => item.businessPartnerId === values.consigneeId) && !values.consignee_same_as_company) {
        const consignee = businessPartners.find((p) => p.id === values.consigneeId);
        if (consignee) {
            if (initialValues.consigneeId) {
                const previousConsigneeIndex = parties.findIndex((item) => item.businessPartnerId === initialValues.consigneeId);
                if (previousConsigneeIndex > -1) parties.splice(previousConsigneeIndex, 1);
            }
            parties.push({
                entityId: shipmentId,
                businessPartnerId: consignee.id,
                type: 'CONSIGNEE',
                subType: 'CONSIGNEE',
                name: consignee.name,
                address: values.destination_pickup_address + ',' + values.destination_zipcode + ',' + values.destination_country,
                email: consignee.email,
                phoneNumber: consignee.phoneNumber,
                logoFileId: consignee.logoFileId
            } as party);
        }
    }
    return parties;
};

export const poItemsToShipmentItems = (poItems: purchaseOrderItem[], purchaseOrderId: string, poCzNumber: string): shipmentItem[] => {
    const shipmentItems = poItems.map((item) => {
        const shipmentItem = {
            purchaseOrderId: purchaseOrderId,
            purchaseOrderItemId: item.id,
            purchaseOrderCzNumber: poCzNumber,
            productId: item.productId,
            productSku: item.productSku,
            quantity: item.quantity,
            code: item.code,
            name: item.name,
            length: item.length,
            height: item.height,
            width: item.width,
            dimensionsUnit: item.dimensionsUnit,
            weight: item.weight,
            weightUnit: item.weightUnit,
            price: item.price,
            currency: item.currency,
            attributes: item.attributes,
            minOrderQuantity: item.minOrderQuantity,
            unitsPerMasterCarton: item.unitsPerMasterCarton,
            masterCartonWeight: item.masterCartonWeight,
            masterCartonWeightUnit: item.masterCartonWeightUnit,
            masterCartonHeight: item.masterCartonHeight,
            masterCartonWidth: item.masterCartonWidth,
            masterCartonLength: item.masterCartonLength,
            masterCartonDimensionUnit: item.masterCartonDimensionUnit
        } as shipmentItem;

        return shipmentItem;
    });

    return shipmentItems;
};

export const duplicateShipmentItems = (items: shipmentItem[]): shipmentItem[] => {
    const shipmentItems = items.map((item) => {
        const shipmentItem = {
            purchaseOrderId: item.purchaseOrderId,
            purchaseOrderItemId: item.id,
            purchaseOrderCzNumber: item.purchaseOrderCzNumber,
            productId: item.productId,
            productSku: item.productSku,
            quantity: item.quantity,
            code: item.code,
            name: item.name,
            length: item.length,
            height: item.height,
            width: item.width,
            dimensionsUnit: item.dimensionsUnit,
            weight: item.weight,
            weightUnit: item.weightUnit,
            price: item.price,
            currency: item.currency,
            attributes: item.attributes,
            minOrderQuantity: item.minOrderQuantity,
            unitsPerMasterCarton: item.unitsPerMasterCarton,
            masterCartonWeight: item.masterCartonWeight,
            masterCartonWeightUnit: item.masterCartonWeightUnit,
            masterCartonHeight: item.masterCartonHeight,
            masterCartonWidth: item.masterCartonWidth,
            masterCartonLength: item.masterCartonLength,
            masterCartonDimensionUnit: item.masterCartonDimensionUnit
        } as shipmentItem;

        return shipmentItem;
    });

    return shipmentItems;
};

export const updateOriginPickupAddress = (values: any, setFieldValue: any, value?: string) => {
    const field = 'origin_pickup_address';
    if (!values[field]) setFieldValue(field, value);
};
export const updateDestinationPickupAddress = (values: any, setFieldValue: any, value?: string) => {
    const field = 'destination_pickup_address';
    if (!values[field]) setFieldValue(field, value);
};

const isNumeric = (str: string) => {
    return !isNaN(+str) && !isNaN(parseFloat(str));
};

export const updateMaster = (values: any, setFieldValue: any, value: string) => {
    const field = 'master';
    if (!values[field]) {
        if (isNumeric(value)) {
            setFieldValue(field, value + '-');
        } else {
            setFieldValue(field, value);
        }
    }
};

export const carriersDropDownOptionsByShipmentType = (shipmentType: shipmentType) =>
    carriers
        .filter((carrier) => {
            if (['AI', 'AE', 'DROP_AIR', 'EXPRESS'].includes(shipmentType)) return carrier.type === 'AIR';
            if (['OI', 'OEX', 'OE', 'DROP_OCEAN'].includes(shipmentType)) return carrier.type === 'OCEAN';
            return false;
        })
        .map((c) => ({
            text: c.carrierName,
            value: c.carrierCode
        }));

export const operatorDropDownOptions = () =>
    operators.map((o) => ({
        text: o.operatorName,
        value: o.operatorName
    }));

export const handlePickupAddress = (
    shipment_type: string,
    addresses: { shipperAddress?: string; consigneeAddress?: string },
    setFieldValue: any,
    values: any
) => {
    if (['AI', 'OEX', 'OI'].includes(shipment_type)) {
        //take shipper address and set consignee null
        updateOriginPickupAddress(values, setFieldValue, addresses.shipperAddress);
        updateDestinationPickupAddress(values, setFieldValue, '');
    } else if (['AE', 'OE'].includes(shipment_type)) {
        //take consignee address and set shipper null
        updateOriginPickupAddress(values, setFieldValue, '');
        updateDestinationPickupAddress(values, setFieldValue, addresses.consigneeAddress);
    } else {
        // rest are common for both , so set both addresses
        updateOriginPickupAddress(values, setFieldValue, addresses.shipperAddress);
        updateDestinationPickupAddress(values, setFieldValue, addresses.consigneeAddress);
    }
};

export const updatePickupAddress = (shipmentType: string, field: string, setFieldValue: any, address: address, values: any) => {
    const country = field + '_country';
    // if (!values[country])
    setFieldValue(country, address.country); // country

    const port = field;
    // if (!values[port])
    if (['AI', 'AE', 'DROP_AIR', 'EXPRESS'].includes(shipmentType)) {
        setFieldValue(port, address.airPort);
    } else {
        setFieldValue(port, address.seaPort);
    }

    const city = field + '_zipcode';
    // if (!values[city])
    setFieldValue(city, address.city); // city

    const pickup_address = field + '_pickup_address';
    // if (!values[pickup_address])
    setFieldValue(pickup_address, address.address); // address
};

export const getAllowedTypes = (shipmentTypesDropDownOptions: Option[], allowedTypes?: Array<string>) => {
    const allowedShipmentTypes: Option[] = [];
    shipmentTypesDropDownOptions?.forEach((item) => {
        const allowed = allowedTypes?.find((type) => type === item.value);
        if (allowed) allowedShipmentTypes.push(item);
    });
    return allowedShipmentTypes;
};
export const filterBusinessPartners = (businessPartners?: Array<businessPartner>, companyId?: string, allowedCompanies?: allowedCompany[]) => {
    if (!companyId || !businessPartners) return businessPartners;
    else
        return businessPartners.filter(
            (item) => item.companyId === companyId || item.companyId == allowedCompanies?.find((c) => c.companyId === companyId)?.parentCompany || ''
        );
};
export const filterIncoterms = (shipment_type: shipmentType, incoterms: Option[]) => {
    if (shipment_type === 'CUSTOMS') {
        const arr = ['CIF', 'CIP', 'CFR', 'CPT', 'DAT', 'DAP', 'DDP'];
        return incoterms.filter((item) => arr.includes(item.value));
    } else if (shipment_type === 'EXPRESS') {
        const arr = ['DDP', 'DAP'];
        return incoterms.filter((item) => arr.includes(item.value));
    }
    return incoterms;
};
