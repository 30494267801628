import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { PagesRoutes } from '../../../routing/PagesRoutes';
import { settings, shipment } from '../../../models/entities/shipment/shipment';
import { alertImgs, shipmentAlertsEnum, shipmentAlert } from '../../../models/entities/shipment/shipmentAlert';
import Overview from './sections/Overview';
import { styled } from '../../shared/Theme/theme';
import Button from '../../shared/SmallComponents/Button';
import EditIcon from '../../../static/icons/edit.svg';
import MenuIcon from '../../../static/icons/menu.svg';
import { Link } from 'react-router-dom';
import BackButton from '../../shared/SmallComponents/BackButton';
import ShipmentTabs, { ShipmentTabsPrintMode } from './sections/Tabs';
import DiscussionsSection from './sections/Discussions/DiscussionsSection';
import moment from 'moment';
import ShipmentMenu from '../../shared/Grid/specialColumns/ShipmentMenu';
import { TriggerProps } from '../../shared/Menu/Menu';
import { useHistory } from 'react-router-dom';
import ShipmentFollowers from './components/shipmentFollowers';
import DropDown from '../../shared/inputs/base/DropDown';
import renderStateOption from '../../shared/SmallComponents/StateOption';
import TimeLine, { TimeLineItem } from '../../shared/TimeLine/TimeLine';
import { settings as companySettings } from '../../../models/entities/businessPartner/businessPartner';
import { featureFlagsModel } from '../../../models/entities/businessPartner/settings';
import CZTooltip from '../../shared/CZTooltip/CZTooltip';
import ShipmentWarning from '../../shared/Grid/specialColumns/ShipmentWarning';
import ChooseForwarder, { stateProps } from '../../shared/ChooseForwarder';
import { chooseForwarderHandler } from '../../shared/ChooseForwarder/utils';
import claimsType from '../../../models/entities/claimsType';
import { bid } from '../../../models/entities/bid/bid';
import { drawers } from '../../../state/ducks/header/header';
import { claimsDomainsType } from '../../../userClaims';
import { purchaseOrderItem } from '../../../models/entities/purchaseOrder/purchaseOrderItem';
import { allowedCompany } from '../../../models/entities/user';
import { css } from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Tooltip from '@material-ui/core/Tooltip';

type Props = {
    shipment: shipment;
    shipmentTags: Array<string>;
    onTagRemoveClicked: (tagName: string) => void;
    updateShipmentState: (shipmentId: string, shipmentState: string, forwarderId?: string) => Promise<void>;
    dismissShipmentAlert: (shipmentId: string, alert: shipmentAlert) => Promise<void>;
    fetchSendToSapGUID: (guid: string) => Promise<void>;
    shipmentPoItems?: Array<purchaseOrderItem>;
    isLoadingShipment: boolean;
    historyUrls: string[];
    isDrawerOpen?: boolean;
    shipmentState?: string;
    shipmentStateTimelineData?: Array<TimeLineItem>;
    localization?: any;
    settings: settings;
    companySettings?: companySettings;
    printMode?: boolean;
    allowedCompanies: allowedCompany[];
    getBPRemarksById: (id: string) => string;
    getBPRemarksAlertById: (id: string) => boolean;
    dismissRequiredDoc: (shipmentId: string, docType: string) => string;
    getUserClaims: (domain: claimsDomainsType) => claimsType;
    isFreelancerOrForwarder?: boolean;
    reStateShipmentById: (shipmentId: string) => Promise<shipment>;
    updateBid?: (id: string, bid: bid, shipment: shipment, publish: boolean) => Promise<boolean>;
    createBid: (shipment: shipment, bid: bid, publish: boolean) => Promise<string>;
    startBid?: (id: string, shipmentId: string, shipment: shipment) => Promise<void>;
    featureFlags?: featureFlagsModel;
    isAllowedShipment?: boolean;
    setDrawerOpen: (open: drawers, params?: any) => void;
};

const SingleShipmentPage = ({
    shipment,
    shipmentTags,
    onTagRemoveClicked,
    updateShipmentState,
    dismissShipmentAlert,
    allowedCompanies,
    getBPRemarksById,
    getBPRemarksAlertById,
    isLoadingShipment,
    historyUrls,
    isDrawerOpen,
    shipmentState,
    shipmentStateTimelineData,
    localization,
    settings,
    companySettings,
    printMode,
    getUserClaims,
    reStateShipmentById,
    dismissRequiredDoc,
    isFreelancerOrForwarder,
    updateBid,
    createBid,
    startBid,
    featureFlags,
    setDrawerOpen,
    isAllowedShipment,
    fetchSendToSapGUID
}: Props) => {
    const history = useHistory();
    const [chooseForwarder, setChooseForwarder] = useState<stateProps>();
    const [timeLineOpen, setTimeLineOpen] = useState<boolean>(true);
    const [isBPPopupVisible, setIsBPPopupVisible] = useState(false);
    const [isAlertedClient, setIsAlertedClient] = useState<boolean>(false);

    useEffect(() => {
        if (shipment.cargoOwnerId) {
            const timer = setTimeout(() => {
                const alertStatus = getBPRemarksAlertById(shipment.cargoOwnerId || '');
                setIsAlertedClient(!!alertStatus);
            }, 100);
            return () => clearTimeout(timer);
        } else {
            setIsAlertedClient(false);
        }
    }, [getBPRemarksAlertById]);

    useEffect(() => {
        if (isAlertedClient) {
            setIsBPPopupVisible(true);
        }
    }, [isAlertedClient]);

    const toggleTimeLine = () => {
        setTimeLineOpen(!timeLineOpen);
    };

    const backPath = ((): string => {
        let backPath = PagesRoutes.Shipments;
        const lastFiveUrls = historyUrls.slice(0, 5);

        for (let i = 0; i < lastFiveUrls.length; i++) {
            const url = lastFiveUrls[i];

            if (!(url?.includes('/shipment/edit') || url === lastFiveUrls[0])) {
                backPath = url;
                break;
            }
        }

        return backPath;
    })();

    const dismissAlert = async (alert: shipmentAlert) => {
        Modal.confirm({
            content: <div>{localization.are_you_sure}</div>,
            async onOk() {
                await dismissShipmentAlert(shipment.id, alert);
            },
            onCancel() {}
        });
    };
    const renderShipmentAlerts = () => {
        return shipment.alerts
            ?.sort((a, b) => a.level - b.level)
            .map((shipmentAlert: shipmentAlert, i: number) => {
                if (!shipmentAlert.isDismissed) {
                    return (
                        <AlertItem key={i}>
                            <AlertImage
                                src={alertImgs[shipmentAlert.level]}
                                alt={localization.alert_status[shipmentAlertsEnum[shipmentAlert.level]]}
                            />
                            <AlertMessage>{shipmentAlert.message}</AlertMessage>
                            <CZTooltip text={localization.dismiss}>
                                <span className="material-icons" onClick={() => dismissAlert(shipmentAlert)}>
                                    close
                                </span>
                            </CZTooltip>
                        </AlertItem>
                    );
                }
            });
    };

    const handleOk = () => {
        setIsBPPopupVisible(false);
    };
    const handleCancel = () => {
        setIsBPPopupVisible(false);
    };
    const getPlainTextFromHTML = (htmlString: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || '';
    };
    return (
        <PageWrapper>
            <Modal
                title="Client Remarks:"
                visible={isBPPopupVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Confirm"
            >
                <div>{getPlainTextFromHTML(getBPRemarksById(shipment.cargoOwnerId || ''))}</div>
            </Modal>

            {chooseForwarder && (
                <ChooseForwarder
                    companyId={chooseForwarder.companyId}
                    onSubmit={chooseForwarder.func}
                    submitArgs={chooseForwarder.args}
                    closeModal={() => setChooseForwarder(undefined)}
                />
            )}
            <LeftSide isTimeLineOpen={timeLineOpen} isDrawerOpen={isDrawerOpen} printMode={printMode} id="single-shipment-page-left-container">
                {!printMode && (
                    <SectionWrapper>
                        <div>
                            <BackButton style={{ marginLeft: '-30px' }} toPath={backPath} />
                            <LastUpdateText>
                                {localization.lastUpdate} {moment(shipment.updatedAt).format('DD/MM/YYYY HH:mm')}
                            </LastUpdateText>
                        </div>
                        <ShipmentStateDropDownContainer>
                            <DropDown
                                placeHolder={localization.shipment_state}
                                style={{ width: '100%' }}
                                options={
                                    settings?.state
                                        ?.sort((a, b) => a.displayOrder - b.displayOrder)
                                        .map((item) => ({
                                            text: item.name,
                                            value: item.code,
                                            color: item.color
                                        })) || []
                                }
                                value={shipmentState}
                                optionRenderer={renderStateOption}
                                onChange={async (value: string) => {
                                    await chooseForwarderHandler(
                                        shipment,
                                        value,
                                        setChooseForwarder,
                                        updateShipmentState,
                                        isFreelancerOrForwarder,
                                        settings
                                    );
                                }}
                                disabled={featureFlags?.UNDER_FORWARDER || !getUserClaims('shipment').edit_state}
                                verifyChange={{
                                    title: (value: string) => `${localization.you_are_moving_the_shipment_state_to} ${
                                        settings?.state?.find((item) => item.code === value)?.name
                                    }.
                                ${localization.are_you_sure}`
                                }}
                            />
                        </ShipmentStateDropDownContainer>
                        <ShipmentWarningContainer>
                            <ShipmentWarning alertStatus={shipment.alertStatus} shipmentId={shipment.id} />
                        </ShipmentWarningContainer>
                        {!shipment.isActive && <InactiveBadge>{localization.inactive}</InactiveBadge>}
                        {shipment.deletedAt && <InactiveBadge>{localization.deleted}</InactiveBadge>}
                        <div style={{ flex: 1 }}></div>
                        <HeaderRightSide>
                            <ShipmentFollowers followers={shipment.followers} />
                            {!featureFlags?.UNDER_FORWARDER && (
                                <Link
                                    to={{
                                        pathname: `${PagesRoutes.EditShipment}/${shipment.id}`
                                    }}
                                >
                                    <Button margin="0 12px 0 0">
                                        <img src={EditIcon} alt={localization.edit} />
                                        <span>{localization.edit}</span>
                                    </Button>
                                </Link>
                            )}

                            {!featureFlags?.UNDER_FORWARDER && isAllowedShipment && getUserClaims('shipment').duplicate_shipment && (
                                <Link
                                    to={{
                                        pathname: `${PagesRoutes.DuplicateShipment}/${shipment.id}`
                                    }}
                                >
                                    <Button margin="0 12px 0 0">
                                        <span>{localization.duplicate}</span>
                                    </Button>
                                </Link>
                            )}
                            {featureFlags?.SAP_INTEGRATION && (
                                <Button
                                    width="auto"
                                    height="auto"
                                    buttonType="hollow"
                                    padding="0 8px"
                                    margin="0 10px 0 0"
                                    borderRadiusMobile="50%"
                                    minHeightMobile="32px"
                                    paddingMobile="0 4px"
                                    widthMobile="auto"
                                    onClick={() => {
                                        if (!shipment.cargoOwnerId) {
                                            alert('Shipment is not related to cargo owner. Complete the data and try again.');
                                            return;
                                        }
                                        fetchSendToSapGUID(shipment.id);
                                        alert('Shipment has been sent to SAP successfully');
                                    }}
                                >
                                    <Tooltip title={localization.SendToSAP} aria-label="send_to_sap_btn">
                                        <span>SAP</span>
                                    </Tooltip>
                                </Button>
                            )}

                            <Button
                                width="auto"
                                height="auto"
                                buttonType="hollow"
                                padding="0 8px"
                                margin="0 10px 0 0"
                                borderRadiusMobile="50%"
                                minHeightMobile="32px"
                                paddingMobile="0 4px"
                                widthMobile="auto"
                                onClick={() => reStateShipmentById(shipment.id)}
                            >
                                <span className="material-icons">refresh</span>
                            </Button>
                            <ShipmentMenu
                                shipment={shipment}
                                entityId={shipment.id}
                                entityData={{ CargoZoneNumber: shipment.CargoZoneNumber, companyId: shipment.companyId }}
                                trigger={(props: TriggerProps) => (
                                    <img style={{ cursor: 'pointer' }} onClick={(e) => props.onClick(e)} src={MenuIcon} alt="Menu" />
                                )}
                                onDelete={() => history.push(PagesRoutes.Shipments)}
                            />
                        </HeaderRightSide>
                    </SectionWrapper>
                )}
                <OverviewSectionWrapper>
                    <Overview
                        shipment={shipment}
                        tags={shipmentTags}
                        onTagRemoveClicked={onTagRemoveClicked}
                        localization={localization}
                        settings={settings}
                        printMode={printMode}
                        featureFlags={featureFlags}
                        isFreelancerOrForwarder={isFreelancerOrForwarder}
                        isAllowedShipment={isAllowedShipment}
                    />
                </OverviewSectionWrapper>
                <SectionWrapper>
                    {!printMode ? (
                        <ShipmentTabs
                            shipment={shipment}
                            localization={localization}
                            settings={settings}
                            companySettings={companySettings}
                            isLoadingShipment={isLoadingShipment}
                            getUserClaims={getUserClaims}
                            isAllowedShipment={isAllowedShipment}
                            dismissRequiredDoc={dismissRequiredDoc}
                            updateBid={updateBid}
                            createBid={createBid}
                            startBid={startBid}
                            featureFlags={featureFlags}
                            setDrawerOpen={setDrawerOpen}
                            isFreelancerOrForwarder={isFreelancerOrForwarder}
                        />
                    ) : (
                        <ShipmentTabsPrintMode
                            shipment={shipment}
                            localization={localization}
                            settings={settings}
                            isAllowedShipment={isAllowedShipment}
                            companySettings={companySettings}
                            printMode={printMode}
                        />
                    )}
                </SectionWrapper>
                {!printMode && (
                    <SectionWrapper>
                        <DiscussionsSection localization={localization} />
                    </SectionWrapper>
                )}
            </LeftSide>
            <RightSide isTimeLineOpen={timeLineOpen}>
                <ToggleBtn onClick={toggleTimeLine}>{!timeLineOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}</ToggleBtn>

                <RightSideContent>
                    <AlertsListContainer>{renderShipmentAlerts()}</AlertsListContainer>
                    <TimeLineContainer>
                        <TimeLine data={shipmentStateTimelineData || []} />
                    </TimeLineContainer>
                </RightSideContent>
            </RightSide>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 65px - 40px);
    }
`;

const TimeLineContainer = styled.div`
    min-width: 380px;
    padding: 20px 36px 10px 7px;
`;

const RightSideContent = styled.div`
    min-width: 380px;
    overflow-y: overlay;
    height: 100%;
`;

const ToggleBtn = styled.div`
    position: absolute;
    top: 9px;
    left: -24px;
    width: 24px;
    color: #1e98cf;
    border: 1px solid #1e98cf;
    background-color: #eff8fc;
    border-right: none;
    height: 32px;
    border-radius: 10px 0px 0px 10px;
    padding: 3px 0;
    transition: 100ms ease-in-out;
    cursor: pointer;
    &:hover {
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        width: 34px;
        left: -34px;
    }
`;

const RightSide = styled.div<{ isTimeLineOpen: boolean }>`
    position: fixed;
    z-index: 0;
    top: 64px;
    right: 70px;
    transition: 225ms ease-in-out;
    ${(props) =>
        props.isTimeLineOpen
            ? css`
                  width: 380px;
                  max-width: 380px;
              `
            : css`
                  width: 0px;
                  max-width: 0px;
              `}
    height: calc(100vh - 64px);
    overflow: visible;
    @media screen and (max-width: 1300px) {
        display: none;
    }
`;
const ShipmentStateDropDownContainer = styled.div`
    align-self: flex-end;
    width: 25%;
    margin-left: 20px;
`;
const ShipmentWarningContainer = styled.div`
    align-self: flex-end;
    margin-left: 10px;
    height: 25px;
`;

const AlertsListContainer = styled.div`
    min-width: 380px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #e0e0e0;

    & :last-child {
        margin-bottom: 0;
    }
`;
export const AlertItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 36px 10px 34px;
    align-items: center;
    transition: 0.1s;
    &:hover {
        background-color: #f1f1f1;
    }
    .material-icons {
        margin-left: auto;
        font-size: 12px;
        text-align: right;
        color: #a2a2a2;
        cursor: pointer;
        transition: 0.1s;

        &:hover {
            color: black;
        }
    }
`;
export const AlertMessage = styled.div`
    color: #394372;
`;
export const AlertImage = styled.img`
    margin-right: 8px;
    height: 18px;
`;

const LeftSide = styled.div<{ isTimeLineOpen: boolean; isDrawerOpen?: boolean; printMode?: boolean }>`
    ${(props) =>
        props.isDrawerOpen
            ? ``
            : css`
                  transition: 225ms ease-in-out;
              `}
    width: ${(props) => (props.isDrawerOpen || props.printMode || !props.isTimeLineOpen ? '100%' : 'calc(100% - 380px)')};
    max-width: ${(props) => (props.isDrawerOpen || props.printMode || !props.isTimeLineOpen ? '100%' : 'calc(100% - 380px)')};
    border-right: 1px solid ${(props) => props.theme.colors.grayDefaultBorder};
    overflow-y: overlay;

    @media screen and (max-width: 1300px) {
        width: 100%;
        max-width: 100%;
    }
`;

const OverviewSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 18px 18px 48px;
    border-bottom: solid 1px ${(props) => props.theme.colors.grayDefaultBorder};
    justify-content: space-between;
`;

const HeaderRightSide = styled.div`
    display: flex;
    height: 30px;
    margin: 30px 0 0 0;
`;

const InactiveBadge = styled.span`
    border-radius: 2vh;
    border: 1px solid #ff7676;
    color: #ff7676;
    font-size: 14px;
    height: 24px;
    padding: 3px 8px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    line-height: 1px;
    align-self: flex-end;
    min-width: max-content;
`;

const LastUpdateText = styled.div`
    font-size: 12px;
    font-style: italic;
    color: #757575;
    padding: 16px 0 0;
`;

export default SingleShipmentPage;
